import { createSlice } from "@reduxjs/toolkit";

const payPalPaySlice = createSlice({
  name: "payPalPay",
  initialState: {
    payPalPayConfig: null,
    payPalPayConfigLoading: false,
    payPalPayConfigError: false,
    approvePayPalPayLoading: false,
    approvePayPalPayLoadingSuccess: false,
    approvePayPalPayLoadingError: false,
    finalizePayPalPayLoading: false,
    finalizePayPalPayLoadingSuccess: false,
    finalizePayPalPayLoadingError: false,
  },
  reducers: {
    createPayPalOrder: (state) => {
      state.payPalPayConfigLoading = true;
      state.payPalPayConfigError = false;
    },
    createPayPalOrderSuccess: (state, action) => {
      state.payPalPayConfigLoading = true;
      state.payPalPayConfigError = false;
      state.payPalPayConfig = action.payload;
    },
    createPayPalOrderFail: (state, action) => {
      state.payPalPayConfigLoading = false;
      state.payPalPayConfigError = action.payload || true;
      state.payPalPayConfig = null;
    },
    approvePayPalPay: (state) => {
      state.approvePayPalPayLoading = true;
      state.approvePayPalPayLoadingSuccess = false;
      state.approvePayPalPayLoadingError = false;
    },
    approvePayPalPaySuccess: (state) => {
      state.approvePayPalPayLoading = false;
      state.approvePayPalPayLoadingSuccess = true;
      state.approvePayPalPayLoadingError = false;
    },
    approvePayPalPayFail: (state, action) => {
      state.approvePayPalPayLoading = false;
      state.approvePayPalPayLoadingSuccess = false;
      state.approvePayPalPayLoadingError = action.payload || true;
    },
    finalizePayPalPay: (state) => {
      state.finalizePayPalPayLoading = true;
      state.finalizePayPalPayLoadingSuccess = false;
      state.finalizePayPalPayLoadingError = false;
    },
    finalizePayPalPaySuccess: (state) => {
      state.finalizePayPalPayLoading = false;
      state.finalizePayPalPayLoadingSuccess = true;
      state.finalizePayPalPayLoadingError = false;
    },
    finalizePayPalPayFail: (state, action) => {
      state.finalizePayPalPayLoading = false;
      state.finalizePayPalPayLoadingSuccess = false;
      state.finalizePayPalPayLoadingError = action.payload || true;
    },
    resetPayPalPayConfig: (state) => {
      state.payPalPayConfig = null;
    },
  },
});

export const {
  createPayPalOrder,
  createPayPalOrdergSuccess,
  createPayPalOrderFail,
  approvePayPalPay,
  approvePayPalPaySuccess,
  approvePayPalPayFail,
  finalizePayPalPay,
  finalizePayPalPaySuccess,
  finalizePayPalPayFail,
  resetPayPalPayConfig,
} = payPalPaySlice.actions;

export const actions = payPalPaySlice.actions;
export default payPalPaySlice.reducer;
