/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import Swagger from "swagger-client";
import { change } from "redux-form";
import DataLayerHelper from "../../../utils/dataLayer";

/** Worker Sagas */

export function* upload(action) {
  const requestStart = new Date();
  const files = action.payload.files;
  const sku = action.payload.sku;
  const options = action.payload.options;
  const file = files[0];
  try {
    const payload = yield call(
      apiRequest,
      `https://media.myfolie.com/api/upload-local2.php?file=${file.name}&unique_id=${sku}`,
      { file, method: "POST" }
    );
    if (options && options.silentUpload) {
      yield put(actions.uploadSuccessSilent(payload));
      DataLayerHelper.addEventWithOrigin(
        "success",
        "file editor",
        "vector upload",
        "duration",
        new Date() - requestStart
      );
    } else {
      yield put(actions.uploadSuccess(payload));
      DataLayerHelper.addEventWithOrigin(
        "success",
        "file editor",
        "upload",
        "duration",
        new Date() - requestStart
      );
    }
    if (payload.x && payload.y && !options.sizeChanged) {
      // maximum width after upload so we do not shock users with a very high price
      const scaled_width = Math.round(payload.x / 3);
      const scaled_height = Math.round(payload.y / 3);
      if (scaled_width > 1500 || scaled_height > 1500) {
        if (scaled_width > scaled_height) {
          yield put(change("fileEditorForm", "fileWidth", 1500));
          yield put(
            change(
              "fileEditorForm",
              "fileHeight",
              1500 * (scaled_height / scaled_width)
            )
          );
        } else if (scaled_width < scaled_height) {
          yield put(
            change(
              "fileEditorForm",
              "fileWidth",
              1500 * (scaled_width / scaled_height)
            )
          );
          yield put(change("fileEditorForm", "fileHeight", 1500));
        } else {
          yield put(change("fileEditorForm", "fileWidth", scaled_width));
          yield put(change("fileEditorForm", "fileHeight", scaled_height));
        }
      } else {
        yield put(change("fileEditorForm", "fileWidth", scaled_width));
        yield put(change("fileEditorForm", "fileHeight", scaled_height));
      }
      yield put(
        change("fileEditorForm", "fileRatio", scaled_height / scaled_width)
      );
    }
  } catch (e) {
    yield put(actions.uploadFail(e));
    if (options && options.silentUpload) {
      DataLayerHelper.addEventWithOrigin(
        "error",
        "file editor",
        "vector upload",
        `${e.message} - ${file.name}`
      );
    } else {
      DataLayerHelper.addEventWithOrigin(
        "error",
        "file editor",
        "upload",
        `${e.message} - ${file.name}`
      );
    }
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopSaga() {
  yield takeEvery(actions.upload, upload);
}

export default shopSaga;

const apiRequest = (_url, args) => {
  const request = {
    url: _url,
    method: args.method,
    body: args.file,
    headers: args.headers,
  };
  return Swagger.http(request)
    .then((res) => {
      return res.body;
    })
    .catch((err) => {
      console.log("ERROR IN APIREQUEST");
      console.log(err);
      throw err;
    });
};
