import { createSlice } from "@reduxjs/toolkit";
import filter from "lodash/filter";
import keys from "lodash/keys";
import uniq from "lodash/uniq";
import values from "lodash/values";
import difference from "lodash/difference";

const _ = {
  filter: filter,
  keys: keys,
  difference: difference,
  values: values,
  uniq: uniq,
};

const initialState = {
  list: [],
  listLoading: false,
  listError: false,
  listFilters: {},
  show: null,
  showLoading: false,
  showError: false,
  fontsDictionary: {},
  queryDictionary: {},
  showFourOFour: false,
  searchResults: [],
  missingChars: [],
};

const fontsSlice = createSlice({
  name: "fonts",
  initialState,
  reducers: {
    list: (state) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
      state.missingChars = {};
    },
    listSuccess: (state, action) => {
      state.loadingList = false;
      state.loadedList = true;
      state.list = action.payload;
    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    show: (state) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
    },
    showSuccess: (state, action) => {
      const newList = state.list.map((font) =>
        font.id === action.payload.id ? action.payload : font
      );
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
      state.list = newList;
    },
    prependCustomFont: (state, action) => {
      state.list.unshift(action.payload);
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
    },
    uploadCustomFont: (state) => {
      state.uploadingFont = true;
      state.uploadedFont = false;
      state.uploadFontError = null;
    },
    uploadCustomFontSuccess: (state, action) => {
      state.uploadingFont = false;
      state.uploadedFont = true;
      state.uploadedFontFile = action.payload;
      state.imagenameFont = action.payload.imagename;
      state.filetypeFont = action.payload.filetype;
      state.previewFont = action.payload.imagepath;
    },
    uploadCustomFontFail: (state, action) => {
      state.uploadingFont = false;
      state.previewFont = null;
      state.uploadFontError = action.error;
    },
    showFail: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = false;
      state.showError = action.payload.mess;
    },
    checkMissingChars: (state, action) => {
      const { lineIndex, line, text, fontTitle } = action.payload;
      const selectedFont = state.list.find((font) => fontTitle === font.title);
      let styleType = 0;
      if (line.bold) {
        styleType = 1;
        if (line.italic) styleType = 3;
      } else if (line.italic) styleType = 2;

      if (
        selectedFont &&
        selectedFont.styles[styleType] &&
        selectedFont.styles[styleType].loaded
      ) {
        const missing = _.difference(
          _.uniq(text),
          Object.keys(JSON.parse(selectedFont.styles[styleType].svg).glyphs)
        );
        if (missing.length) {
          state.missingChars[lineIndex] = {
            missing: _.uniq(missing),
            font: selectedFont,
          };
        } else {
          delete state.missingChars[lineIndex];
        }
      }
    },
  },
});

export const {
  list,
  listSuccess,
  listFail,
  show,
  showSuccess,
  prependCustomFont,
  uploadCustomFont,
  uploadCustomFontSuccess,
  uploadCustomFontFail,
  showFail,
  checkMissingChars,
} = fontsSlice.actions;

export const actions = fontsSlice.actions;

export default fontsSlice.reducer;
