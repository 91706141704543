/**
 * Actions and ActionTypes definitions for the FaqCategory resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from "redux-actions";
import notificationsActions from "../notifications";

/** LIST faqcategories action-types */
const LIST = "faqcategories/LIST";
const LIST_SUCCESS = "faqcategories/LIST_SUCCESS";
const LIST_FAIL = "faqcategories/LIST_FAIL";

/** SHOW faqcategory action-types */
const SHOW = "faqcategories/SHOW";
const SHOW_SUCCESS = "faqcategories/SHOW_SUCCESS";
const SHOW_FAIL = "faqcategories/SHOW_FAIL";

/** CREATE faqcategory action-types */
const CREATE = "faqcategories/CREATE";
const CREATE_SUCCESS = "faqcategories/CREATE_SUCCESS";
const CREATE_FAIL = "faqcategories/CREATE_FAIL";

/** UPDATE faqcategory action-types */
const UPDATE = "faqcategories/UPDATE";
const UPDATE_SUCCESS = "faqcategories/UPDATE_SUCCESS";
const UPDATE_FAIL = "faqcategories/UPDATE_FAIL";

/** REMOVE faqcategory action-types */
const REMOVE = "faqcategories/REMOVE";
const REMOVE_SUCCESS = "faqcategories/REMOVE_SUCCESS";
const REMOVE_FAIL = "faqcategories/REMOVE_FAIL";

/** STORE faqcategory action-type */
const STORE = "faqcategories/STORE";
/** UNSTORE faqcategory action-type */
const UNSTORE = "faqcategories/UNSTORE";

/**
 * list faqcategories
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show faqcategory
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create faqcategory
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update faqcategory
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);

/**
 * remove faqcategory
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store faqcategory
 */
const storeFaqCategory = createAction(STORE, () => async (dispatch) => {
  if (navigator && "storage" in navigator && "estimate" in navigator.storage) {
    navigator.storage.estimate().then(function (estimate) {
      const { usage, quota } = estimate;
      const percentUsed = Math.round((usage / quota) * 100);
      const usageInMib = Math.round(usage / (1024 * 1024));
      const quotaInMib = Math.round(quota / (1024 * 1024));
      const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
      const color = percentUsed > 80 ? "danger" : "success";
      let alertOptions = { message, color, origin: "storage", timeout: 5 };
      dispatch(notificationsActions.showAlert(alertOptions));
    });
  }
});
/**
 * unstore faqcategory
 */
const unstoreFaqCategory = createAction(UNSTORE);

/** exports all actions as default */
const faqCategoryActions = {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeFaqCategory: storeFaqCategory,
  unstoreFaqCategory: unstoreFaqCategory,
};
export default faqCategoryActions;
