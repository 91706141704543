/**
 * The CookieConsentModal component
 *
 *
 * COOKIE privacy-consent = LTS cookie set by server
 * COOKIE privacy-consent-tmp = set by frontend with same data, should be short lived but ensures that user is not blocked if api does not answer
 *
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  FormGroup,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import apiRequest from "./CookieConsentApiRequest";
import Cookies from "universal-cookie";
import { REACT_APP_GTM } from "../../utils/env";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

const cookieHandler = new Cookies();

const CookieConsentModal = (props, context) => {
  const { lang, enforcePrivacyConsentModal, setEnforcePrivacyConsentModal } =
    props;

  let consentCookieContent = cookieHandler.get("privacy-consent");
  if (!consentCookieContent)
    consentCookieContent = cookieHandler.get("privacy-consent-tmp");

  const [cookieSettingsOpen, setCookieSettingsOpen] = useState(false);
  const [advertisingAccepted, setAdvertisingAccepted] = useState(
    consentCookieContent?.advertising
  );
  const [customizationAccepted, setCustomizationAccepted] = useState(
    consentCookieContent?.customization
  );
  const [analyzeAccepted, setAnalyzeAccepted] = useState(
    consentCookieContent?.analyze
  );

  const [hideConsentModal, setHideConsentModal] = useState(
    !!consentCookieContent && typeof consentCookieContent === "object"
  );

  const sendServerConsent = (consentObject) => {
    // set local cookie (fallback)
    cookieHandler.set("privacy-consent-tmp", JSON.stringify(consentObject), {
      httpOnly: false,
      path: "/",
      maxAge: 60 * 60 * 1000 * 72,
    });
    setHideConsentModal(true);
    setEnforcePrivacyConsentModal(false);
    if (
      (consentObject.advertising || consentObject.analyze) &&
      REACT_APP_GTM &&
      !window.dataLayer.find((element) => element["gtm.start"])
    ) {
      const script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${REACT_APP_GTM}');`;
      document.head.insertBefore(script, document.head.childNodes[0]);
    }
    const scriptUpdate = document.createElement("script");
    scriptUpdate.innerHTML = `
    gtag(
      "consent",
      "update",
      {
        functionality_storage: "${
          consentObject.functional ? "granted" : "denied"
        }",
        security_storage: "${consentObject.security ? "granted" : "denied"}",
        ad_storage: "${consentObject.advertising ? "granted" : "denied"}",
        ad_user_data: "${consentObject.advertising ? "granted" : "denied"}",
        ad_personalization: "${
          consentObject.advertising ? "granted" : "denied"
        }",
        analytics_storage: "${consentObject.analyze ? "granted" : "denied"}",
        personalization_storage: "${
          consentObject.customization ? "granted" : "denied"
        }",
      },
    )`;
    document.head.insertBefore(scriptUpdate, document.head.childNodes[0]);
    window.dataLayer.push({
      event: "consentChange",
      action: "changed",
      label: "changed",
    });
    // send server response, should overwrite local cookie if possible
    const payload = apiRequest(`/`, {
      body: JSON.stringify(consentObject),
      method: "POST",
    });
  };

  const acceptAll = () => {
    const consentObject = {
      security: true,
      functional: true,
      advertising: true,
      customization: true,
      analyze: true,
    };
    setAnalyzeAccepted(true);
    setAdvertisingAccepted(true);
    setCustomizationAccepted(true);
    sendServerConsent(consentObject);
  };

  const denyAll = () => {
    const consentObject = {
      security: true,
      functional: true,
      advertising: false,
      customization: false,
      analyze: false,
    };
    setAnalyzeAccepted(false);
    setAdvertisingAccepted(false);
    setCustomizationAccepted(false);
    sendServerConsent(consentObject);
  };

  const saveSettings = () => {
    const consentObject = {
      security: true,
      functional: true,
      advertising: advertisingAccepted,
      customization: customizationAccepted,
      analyze: analyzeAccepted,
    };
    sendServerConsent(consentObject);
  };

  const [openDetails, setOpenDetails] = useState("");
  const toggle = (id) => {
    if (openDetails === id) {
      setOpenDetails();
    } else {
      setOpenDetails(id);
    }
  };

  return (
    <>
      {(!hideConsentModal || enforcePrivacyConsentModal) && (
        <Modal
          fade={false}
          isOpen={!hideConsentModal || enforcePrivacyConsentModal}
          size="lg"
        >
          <ModalHeader tag="div" cssModule={{ "modal-title": "w-100" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                "justify-content": "space-around",
                "flex-flow": "row wrap",
                "align-items": "stretch",
              }}
            >
              <h5 style={{ flex: "1" }}>
                Einwilligung in die Datenverarbeitung
              </h5>
            </div>
          </ModalHeader>
          <ModalBody
            style={{
              "max-height": "calc(100vh - 300px)",
              "overflow-y": "auto",
            }}
          >
            {(cookieSettingsOpen || enforcePrivacyConsentModal) && (
              <ListGroup>
                <ListGroupItem>
                  <FormGroup check>
                    <Label check>
                      <Input checked={true} type="checkbox" disabled />{" "}
                      <strong>Sicherheit (Technisch Notwendig)</strong>
                      <br />
                      <ul className="small text-remark">
                        <li>Verifizierung mit Zahlungsdienstleistern</li>
                        <li>
                          Ermöglicht das Speichern von sicherheitsbezogenen
                          Daten, z. B. für Authentifizierungsfunktionen,
                          Betrugsprävention und andere Schutzmechanismen für
                          Nutzer
                        </li>
                      </ul>
                    </Label>
                    <Button
                      color="link"
                      block
                      size="sm"
                      className="pull-right text-right"
                      onClick={() => toggle("1")}
                    >
                      {openDetails === "1" && (
                        <FaChevronDown className="small" />
                      )}
                      {openDetails !== "1" && (
                        <FaChevronRight className="small" />
                      )}
                      Alle Dienste dieser Kategorie
                    </Button>
                    {openDetails === "1" && (
                      <Table className="small">
                        <tr>
                          <td>
                            <strong>myfolie.com (myfolie GmbH)</strong>
                            <br />
                            Von-Stauffenberg-Str 27, 82008 Unterhaching
                          </td>
                          <td>
                            Wir setzen Session Cookies für die Speicherung des
                            Warenkorb-Inhalts und permanente Cookies für die
                            Speicherung des Login-Status ein. Die Verarbeitung
                            erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b
                            (Durchführung Bestellvorgänge) und c (Gesetzlich
                            erforderliche Archivierung) DSGVO. Dabei sind die
                            als erforderlich gekennzeichneten Angaben zur
                            Begründung und Erfüllung des Vertrages erforderlich.
                          </td>
                        </tr>
                      </Table>
                    )}
                  </FormGroup>
                </ListGroupItem>
                <ListGroupItem>
                  <FormGroup check>
                    <Label check>
                      <Input checked={true} type="checkbox" disabled />{" "}
                      <strong>Funktional (Technisch Notwendig)</strong>
                      <br />
                      <ul className="small text-remark">
                        <li>Erstellung eines Warenkorb-Session Cookies</li>
                        <li>Zahlungsdienstleister</li>
                        <li>
                          Ermöglicht das Speichern von Daten, die die Funktion
                          der Website oder App unterstützen, z. B. die
                          Spracheinstellungen
                        </li>
                      </ul>
                    </Label>
                    <Button
                      color="link"
                      block
                      size="sm"
                      className="pull-right text-right"
                      onClick={() => toggle("2")}
                    >
                      {openDetails === "2" && (
                        <FaChevronDown className="small" />
                      )}
                      {openDetails !== "2" && (
                        <FaChevronRight className="small" />
                      )}
                      Alle Dienste dieser Kategorie
                    </Button>
                    {openDetails === "2" && (
                      <Table className="small">
                        <tr>
                          <td>
                            <strong>myfolie.com (myfolie GmbH)</strong>
                            <br />
                            Von-Stauffenberg-Str 27, 82008 Unterhaching
                          </td>
                          <td>
                            Cookie zum Speichern der Nutzer-Einwilligungs
                            Einstellungen. Die Verarbeitung der Daten erfolgt
                            auf Grundlage des Art. 6 Abs. 1 lit. f.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Adyen N.V.</strong>
                            <br />
                            Carmiggelstraat 6-50, 1011 DJ, Amsterdam,
                            Niederlande
                          </td>
                          <td>
                            Im Rahmen der Erfüllung von Verträgen setzen wir die
                            Zahlungsdienstleister auf Grundlage des Art. 6 Abs.
                            1 lit. b. DSGVO ein. Im Übrigen setzen wir externe
                            Zahlungsdienstleister auf Grundlage unserer
                            berechtigten Interessen gem. Art. 6 Abs. 1 lit. b.
                            DSGVO ein, um unseren Nutzern eine effektive und
                            sichere Zahlungsmöglichkeit zu bieten.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Amazon Payments Europe s.c.a.</strong>
                            <br />5 Rue Plaetis, L-2338 Luxembourg
                          </td>
                          <td>
                            Im Rahmen der Erfüllung von Verträgen setzen wir die
                            Zahlungsdienstleister auf Grundlage des Art. 6 Abs.
                            1 lit. b. DSGVO ein. Im Übrigen setzen wir externe
                            Zahlungsdienstleister auf Grundlage unserer
                            berechtigten Interessen gem. Art. 6 Abs. 1 lit. b.
                            DSGVO ein, um unseren Nutzern eine effektive und
                            sichere Zahlungsmöglichkeit zu bieten. Dieser
                            Service kann die erfassten Daten an ein anderes Land
                            weiterleiten. Bitte beachten Sie, dass dieser
                            Service Daten außerhalb der Europäischen Union und
                            des Europäischen Wirtschaftsraums und in ein Land,
                            welches kein angemessenes Datenschutzniveau bietet,
                            übertragen kann. Falls die Daten in die USA
                            übertragen werden, besteht das Risiko, dass Ihre
                            Daten von US-Behörden zu Kontroll- und
                            Überwachungszwecken verarbeitet werden können, ohne
                            dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten
                            zustehen. Nachfolgend finden Sie eine Liste der
                            Länder, in die die Daten übertragen werden. Dies
                            kann für verschiedene Zwecke der Fall sein, z. B.
                            zum Speichern oder Verarbeiten.
                            <br /> - USA
                          </td>
                        </tr>
                      </Table>
                    )}
                  </FormGroup>
                </ListGroupItem>
                <ListGroupItem>
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={analyzeAccepted}
                        type="checkbox"
                        onChange={() => {
                          setAnalyzeAccepted(!analyzeAccepted);
                        }}
                      />{" "}
                      <strong>Analyse</strong>
                      <br />
                      <ul className="small text-remark">
                        <li>
                          Beobachtung der Website-Nutzung und Optimierung der
                          Benutzererfahrung
                        </li>
                        <li>Bewertung der erfolgreichsten Marketingkanäle</li>
                        <li>
                          Analyse aggregierter Daten über die Nutzung der
                          Website, um unsere Kunden besser zu verstehen.
                          Erstellung zufälliger Identifikatoren, um diese mit
                          Partnern zu teilen.
                        </li>
                        <li>
                          Ermöglicht das Speichern von analysebezogenen Daten
                          wie Cookies, z. B. zur Besuchsdauer
                        </li>
                      </ul>
                    </Label>
                    <Button
                      color="link"
                      block
                      size="sm"
                      className="pull-right text-right"
                      onClick={() => toggle("3")}
                    >
                      {openDetails === "3" && (
                        <FaChevronDown className="small" />
                      )}
                      {openDetails !== "3" && (
                        <FaChevronRight className="small" />
                      )}
                      Alle Dienste dieser Kategorie
                    </Button>
                    {openDetails === "3" && (
                      <Table className="small">
                        <tr>
                          <td>
                            <strong>
                              Google Tag Manager Google Ireland Limited
                            </strong>
                            <br />
                            Google Building Gordon House, 4 Barrow St, Dublin,
                            D04 E5W5, Ireland
                          </td>
                          <td>
                            Wir setzten den Google Tag Manager ein, um
                            Drittdienste zu laden, anzusprechen, deren Nutzung
                            zu dokumentieren und einen fehlerfreien Betrieb der
                            Webseite sicherzustellen. Die Verarbeitung erfolgt
                            auf Grundlage des Art. 6 Abs. 1 lit. a, da dieses
                            Script nur nach Einwilligung geladen wird.Dieser
                            Service kann die erfassten Daten an ein anderes Land
                            weiterleiten. Bitte beachten Sie, dass dieser
                            Service Daten außerhalb der Europäischen Union und
                            des Europäischen Wirtschaftsraums und in ein Land,
                            welches kein angemessenes Datenschutzniveau bietet,
                            übertragen kann. Falls die Daten in die USA
                            übertragen werden, besteht das Risiko, dass Ihre
                            Daten von US-Behörden zu Kontroll- und
                            Überwachungszwecken verarbeitet werden können, ohne
                            dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten
                            zustehen. Nachfolgend finden Sie eine Liste der
                            Länder, in die die Daten übertragen werden. Dies
                            kann für verschiedene Zwecke der Fall sein, z. B.
                            zum Speichern oder Verarbeiten.
                            <br /> - USA
                          </td>
                        </tr>
                      </Table>
                    )}
                  </FormGroup>
                </ListGroupItem>
                <ListGroupItem>
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={customizationAccepted}
                        type="checkbox"
                        onChange={() => {
                          setCustomizationAccepted(!customizationAccepted);
                        }}
                      />{" "}
                      <strong>Personalisierung</strong>
                      <br />
                      <ul className="small text-remark">
                        <li>
                          Speicherung Ihrer Präfererenzen aus frühreren
                          Besuchen.
                        </li>
                        <li>
                          Sammeln von Benutzer-Feedback zur Verbesserung unserer
                          Website
                        </li>
                        <li>
                          Erfassung Ihrer Interessen, um maßgeschneiderte
                          Inhalte und Angebote anzubieten.
                        </li>
                      </ul>
                    </Label>
                    <Button
                      color="link"
                      block
                      size="sm"
                      className="pull-right text-right"
                      onClick={() => toggle("4")}
                    >
                      {openDetails === "4" && (
                        <FaChevronDown className="small" />
                      )}
                      {openDetails !== "4" && (
                        <FaChevronRight className="small" />
                      )}
                      Alle Dienste dieser Kategorie
                    </Button>
                    {openDetails === "4" && (
                      <p className="small text-info text-center">
                        Aktuell werden keine Dienste in dieser Kategorie
                        eingesetzt.
                      </p>
                    )}
                  </FormGroup>
                </ListGroupItem>
                <ListGroupItem>
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={advertisingAccepted}
                        type="checkbox"
                        onChange={() => {
                          setAdvertisingAccepted(!advertisingAccepted);
                        }}
                      />{" "}
                      <strong>Werbung</strong>
                      <br />
                      <ul className="small text-remark">
                        <li>
                          Steigerung der Relevanz von Online- und Social-Werbung
                          für Sie
                        </li>
                        <li>
                          Identifizierung von Kundengruppen, die wir für
                          bestimmte Produkte wiederholt ansprechen werden
                        </li>
                        <li>
                          Teilen von Daten mit unseren Werbe- und Social
                          Media-Partnern über deren Drittanbieter-Cookies, um
                          Ihren Interessen gerecht zu werden
                        </li>
                        <li>
                          Ermöglicht das Speichern von werbebezogenen Daten wie
                          Cookies
                        </li>
                      </ul>
                    </Label>
                    <Button
                      color="link"
                      block
                      size="sm"
                      className="pull-right text-right"
                      onClick={() => toggle("5")}
                    >
                      {openDetails === "5" && (
                        <FaChevronDown className="small" />
                      )}
                      {openDetails !== "5" && (
                        <FaChevronRight className="small" />
                      )}
                      Alle Dienste dieser Kategorie
                    </Button>
                    {openDetails === "5" && (
                      <Table className="small">
                        <tr>
                          <td>
                            <strong>
                              Google Tag Manager Google Ireland Limited
                            </strong>
                            <br />
                            Google Building Gordon House, 4 Barrow St, Dublin,
                            D04 E5W5, Ireland
                          </td>
                          <td>
                            Wir setzten den Google Tag Manager ein, um
                            Drittdienste zu laden, anzusprechen, deren Nutzung
                            zu dokumentieren und einen fehlerfreien Betrieb der
                            Webseite sicherzustellen. Die Verarbeitung erfolgt
                            auf Grundlage des Art. 6 Abs. 1 lit. a, da dieses
                            Script nur nach Einwilligung geladen wird.Dieser
                            Service kann die erfassten Daten an ein anderes Land
                            weiterleiten. Bitte beachten Sie, dass dieser
                            Service Daten außerhalb der Europäischen Union und
                            des Europäischen Wirtschaftsraums und in ein Land,
                            welches kein angemessenes Datenschutzniveau bietet,
                            übertragen kann. Falls die Daten in die USA
                            übertragen werden, besteht das Risiko, dass Ihre
                            Daten von US-Behörden zu Kontroll- und
                            Überwachungszwecken verarbeitet werden können, ohne
                            dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten
                            zustehen. Nachfolgend finden Sie eine Liste der
                            Länder, in die die Daten übertragen werden. Dies
                            kann für verschiedene Zwecke der Fall sein, z. B.
                            zum Speichern oder Verarbeiten.
                            <br /> - USA
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Google Ads</strong>
                            <br />
                            Google Building Gordon House, 4 Barrow St, Dublin,
                            D04 E5W5, Ireland
                          </td>
                          <td>
                            Wir setzen Google Ads ein um die Produkte in unserem
                            Online Shop zu bewerben. Hierbei verwenden wir
                            sowohl Integrationen zum Retargeting sowie zur
                            Erfolgsmessung. Die Verarbeitung erfolgt auf
                            Grundlage des Art. 6 Abs. 1 lit. a, da dieses Script
                            nur nach Einwilligung geladen wird.Dieser Service
                            kann die erfassten Daten an ein anderes Land
                            weiterleiten. Bitte beachten Sie, dass dieser
                            Service Daten außerhalb der Europäischen Union und
                            des Europäischen Wirtschaftsraums und in ein Land,
                            welches kein angemessenes Datenschutzniveau bietet,
                            übertragen kann. Falls die Daten in die USA
                            übertragen werden, besteht das Risiko, dass Ihre
                            Daten von US-Behörden zu Kontroll- und
                            Überwachungszwecken verarbeitet werden können, ohne
                            dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten
                            zustehen. Nachfolgend finden Sie eine Liste der
                            Länder, in die die Daten übertragen werden. Dies
                            kann für verschiedene Zwecke der Fall sein, z. B.
                            zum Speichern oder Verarbeiten.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Bing Ads Microsoft Corporation</strong>
                            <br />
                            One Microsoft Way, Redmond, WA 98052-6399, USA
                          </td>
                          <td>
                            Wir setzen Google Ads ein um die Produkte in unserem
                            Online Shop zu bewerben. Hierbei verwenden wir
                            sowohl Integrationen zum Retargeting sowie zur
                            Erfolgsmessung. Die Verarbeitung erfolgt auf
                            Grundlage des Art. 6 Abs. 1 lit. a, da dieses Script
                            nur nach Einwilligung geladen wird. Dieser Service
                            kann die erfassten Daten an ein anderes Land
                            weiterleiten. Bitte beachten Sie, dass dieser
                            Service Daten außerhalb der Europäischen Union und
                            des Europäischen Wirtschaftsraums und in ein Land,
                            welches kein angemessenes Datenschutzniveau bietet,
                            übertragen kann. Falls die Daten in die USA
                            übertragen werden, besteht das Risiko, dass Ihre
                            Daten von US-Behörden zu Kontroll- und
                            Überwachungszwecken verarbeitet werden können, ohne
                            dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten
                            zustehen. Nachfolgend finden Sie eine Liste der
                            Länder, in die die Daten übertragen werden. Dies
                            kann für verschiedene Zwecke der Fall sein, z. B.
                            zum Speichern oder Verarbeiten.
                            <br /> - USA
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>
                              DoubleClick Ad Google Ireland Limited
                            </strong>
                            <br />
                            Google Building Gordon House, 4 Barrow St, Dublin,
                            D04 E5W5, Ireland
                          </td>
                          <td>
                            Dies ist ein Werbedienst. Mit diesem Dienst ist es
                            möglich, relevante Anzeigen für Benutzer
                            bereitzustellen und Kampagnenberichte zu erhalten.
                            Die Verarbeitung erfolgt auf Grundlage des Art. 6
                            Abs. 1 lit. a, da dieses Script nur nach
                            Einwilligung geladen wird. Dieser Service kann die
                            erfassten Daten an ein anderes Land weiterleiten.
                            Bitte beachten Sie, dass dieser Service Daten
                            außerhalb der Europäischen Union und des
                            Europäischen Wirtschaftsraums und in ein Land,
                            welches kein angemessenes Datenschutzniveau bietet,
                            übertragen kann. Falls die Daten in die USA
                            übertragen werden, besteht das Risiko, dass Ihre
                            Daten von US-Behörden zu Kontroll- und
                            Überwachungszwecken verarbeitet werden können, ohne
                            dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten
                            zustehen. Nachfolgend finden Sie eine Liste der
                            Länder, in die die Daten übertragen werden. Dies
                            kann für verschiedene Zwecke der Fall sein, z. B.
                            zum Speichern oder Verarbeiten.
                            <br /> - USA
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>ADCELL / Firstlead GmbH</strong>
                            <br />
                            Rosenfelder St. 15-16, 10315 Berlin
                          </td>
                          <td>
                            Dies ist ein Affiliate Werbedienstleister. Mit
                            diesem Dienst ist es möglich, relevante Anzeigen für
                            Benutzer bereitzustellen und Kampagnenberichte zu
                            erhalten. Die Verarbeitung erfolgt auf Grundlage des
                            Art. 6 Abs. 1 lit. a, da dieses Script nur nach
                            Einwilligung geladen wird.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>belboon GmbH</strong>
                            <br />
                            Weinmeisterstr. 12-14 10178 Berlin Germany
                          </td>
                          <td>
                            Dies ist ein Affiliate Werbedienstleister. Mit
                            diesem Dienst ist es möglich, relevante Anzeigen für
                            Benutzer bereitzustellen und Kampagnenberichte zu
                            erhalten. Die Verarbeitung erfolgt auf Grundlage des
                            Art. 6 Abs. 1 lit. a, da dieses Script nur nach
                            Einwilligung geladen wird.
                          </td>
                        </tr>
                      </Table>
                    )}
                  </FormGroup>
                </ListGroupItem>
              </ListGroup>
            )}
            <p className="small text-muted pt-1">
              <Link to={`/${lang}/p/impressum/`}>Wir</Link> verwenden Cookies
              und ähnliche Technologien, die für den Betrieb des Webshop und der
              Website erforderlich sind. Zusätzliche Cookies und Dienste werden
              nur mit Ihrer Zustimmung geladen und verwendet. Durch einen Klick
              auf Datenschutz- & Cookie-Einstellungen können sie individuelle
              Anpassungen vornehmen. Bei einem Klick auf Nur technisch
              Notwendige werden nur zum Betrieb erforderliche Dienste geladen
              und Cookies gesetzt (Das betrifft Dienste der Kategorie Sicherheit
              und Funktional). Mit einem Klick auf Einverstanden akzeptieren sie
              sämtliche Einsatzzwecke. Ihre Einwilligung können Sie jederzeit
              widerrufen, indem Sie im Footer auf der Webseite auf
              Datenschutzeinstellungen klicken. Weitere Informationen - auch
              über die mit einem Drittlandtransfer verbunden Risiken - finden
              Sie in unserer{" "}
              <Link to={`/${lang}/p/datenschutz/`}>Datenschutzerklärung</Link>.
              Für weitere Informationen darüber, welche Daten gesammelt und wie
              sie an unsere Partner weitergegeben werden, lesen Sie bitte unsere
              Datenschutzerklärung.{" "}
            </p>
          </ModalBody>
          <ModalFooter style={{ display: "block" }}>
            <Row>
              {!enforcePrivacyConsentModal && (
                <Col xs={12} md={4} className="text-center mb-2">
                  {cookieSettingsOpen && (
                    <Button
                      color="success"
                      size="md"
                      block
                      onClick={saveSettings}
                    >
                      Einstellungen speichern
                    </Button>
                  )}
                  {!cookieSettingsOpen && (
                    <Button
                      block
                      size="md"
                      color="success"
                      onClick={() => setCookieSettingsOpen(true)}
                    >
                      Datenschutzeinstellungen 
                    </Button>
                  )}
                </Col>
              )}
              <Col
                xs={12}
                md={enforcePrivacyConsentModal ? 6 : 4}
                className="text-center mb-2"
              >
                <Button block size="md" color="success" onClick={denyAll}>
                  Nur technisch Notwendige
                </Button>
              </Col>
              <Col
                xs={12}
                md={enforcePrivacyConsentModal ? 6 : 4}
                className="text-center mb-2"
              >
                {!enforcePrivacyConsentModal && (
                  <Button color="success" size="md" block onClick={acceptAll}>
                    {!cookieSettingsOpen ? "Einverstanden" : "Allen zustimmen"}
                  </Button>
                )}
                {enforcePrivacyConsentModal && (
                  <Button
                    color="success"
                    size="md"
                    block
                    onClick={saveSettings}
                  >
                    Einstellungen speichern
                  </Button>
                )}
              </Col>
            </Row>
            <p className="text-center text-muted small">
              <Link to={`/${lang}/p/datenschutz/`}>
                {context.t("Data privacy")}
              </Link>{" "}
              | <Link to={`/${lang}/p/impressum/`}>Impressum</Link>
            </p>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
CookieConsentModal.contextTypes = {
  t: PropTypes.func,
};

/** export the redux connected component and add a frontload call */
export default CookieConsentModal;
