import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: [],
  loadingSearch: false,
  loadedSearch: false,
  searchError: null,
  list: [],
  dictionary: {},
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null,
  errorRedirect: null,
};

const landingpagesSlice = createSlice({
  name: "landingpages",
  initialState,
  reducers: {
    list: (state) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
    },
    listSuccess: (state, action) => {
      state.loadingList = false;
      state.loadedList = true;
      state.list = action.payload;
    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    search: (state) => {
      state.loadingSearch = true;
      state.loadedSearch = false;
      state.searchError = null;
    },
    searchSuccess: (state, action) => {
      state.loadingSearch = false;
      state.loadedSearch = true;
      state.search = action.payload;
    },
    searchFail: (state, action) => {
      state.loadingSearch = false;
      state.loadedSearch = false;
      state.searchError = action.payload.mess;
    },
    show: (state) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
      state.errorRedirect = null;
    },
    showSuccess: (state, action) => {
      let show = { ...action.payload };
      delete show.cms_blocks;
      state.loadingShow = false;
      state.errorRedirect = null;
      state.loadedShow = true;
      state.show = show;
    },
    showFail: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = false;
      state.showError = action.payload.mess;
      if (action?.payload?.errorRedirect) state.errorRedirect = action?.payload?.errorRedirect;
    },
    storeLandingpage: (state, action) => {
      let newStoredLandingpages = { ...state.stored };
      newStoredLandingpages[state.show.id] = state.show;
      let newShow = { ...state.show };
      newShow.stored = true;
      state.stored = newStoredLandingpages;
      state.show = newShow;
    },
    unstoreLandingpage: (state, action) => {
      let newStoredLandingpages = { ...state.stored };
      delete newStoredLandingpages[action.payload];
      let newShow = { ...state.show };
      newShow.stored = false;
      state.stored = newStoredLandingpages;
      state.show = newShow;
    },
  },
});

export default landingpagesSlice.reducer;

export const {
  list,
  listSuccess,
  listFail,
  search,
  searchSuccess,
  searchFail,
  show,
  showSuccess,
  showFail,
  storeLandingpage,
  unstoreLandingpage,
} = landingpagesSlice.actions;
export const actions = landingpagesSlice.actions;
