import { createSlice } from "@reduxjs/toolkit";

const amazonPaySlice = createSlice({
  name: "payAmazon",
  initialState: {
    amazonPayConfig: null,
    amazonPayConfigLoading: false,
    amazonPayConfigError: false,
    resetAmazonPayLoading: false,
    resetAmazonPayLoadingSuccess: false,
    resetAmazonPayLoadingError: false,
    finalizeAmazonPayLoading: false,
    finalizeAmazonPayLoadingSuccess: false,
    finalizeAmazonPayLoadingError: false,
  },
  reducers: {
    showAmazonPayConfig: (state) => {
      state.amazonPayConfigLoading = true;
      state.amazonPayConfigError = false;
    },
    showAmazonPayConfigSuccess: (state, action) => {
      state.amazonPayConfigLoading = true;
      state.amazonPayConfigError = false;
      state.amazonPayConfig = action.payload;
    },
    showAmazonPayConfigFail: (state, action) => {
      state.amazonPayConfigLoading = false;
      state.amazonPayConfigError = action.payload || true;
      state.amazonPayConfig = null;
    },
    resetAmazonPay: (state) => {
      state.resetAmazonPayLoading = true;
      state.resetAmazonPayLoadingSuccess = false;
      state.resetAmazonPayLoadingError = false;
    },
    resetAmazonPaySuccess: (state) => {
      state.resetAmazonPayLoading = false;
      state.resetAmazonPayLoadingSuccess = true;
      state.resetAmazonPayLoadingError = false;
    },
    resetAmazonPayFail: (state, action) => {
      state.resetAmazonPayLoading = false;
      state.resetAmazonPayLoadingSuccess = false;
      state.resetAmazonPayLoadingError = action.payload || true;
    },
    finalizeAmazonPay: (state) => {
      state.finalizeAmazonPayLoading = true;
      state.finalizeAmazonPayLoadingSuccess = false;
      state.finalizeAmazonPayLoadingError = false;
    },
    finalizeAmazonPaySuccess: (state) => {
      state.finalizeAmazonPayLoading = false;
      state.finalizeAmazonPayLoadingSuccess = true;
      state.finalizeAmazonPayLoadingError = false;
    },
    finalizeAmazonPayFail: (state, action) => {
      state.finalizeAmazonPayLoading = false;
      state.finalizeAmazonPayLoadingSuccess = false;
      state.finalizeAmazonPayLoadingError = action.payload || true;
    },
    resetAmazonPayConfig: (state) => {
      state.amazonPayConfig = null;
    },
  },
});

export const {
  showAmazonPayConfig,
  showAmazonPayConfigSuccess,
  showAmazonPayConfigFail,
  resetAmazonPay,
  resetAmazonPaySuccess,
  resetAmazonPayFail,
  finalizeAmazonPay,
  finalizeAmazonPaySuccess,
  finalizeAmazonPayFail,
  resetAmazonPayConfig,
} = amazonPaySlice.actions;

export const actions = amazonPaySlice.actions;
export default amazonPaySlice.reducer;
