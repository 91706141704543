import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  listLoading: false,
  listError: false,
  listFilters: {},
  show: null,
  showLoading: false,
  showError: false,
  errorRedirect: null,
  productsDictionary: {},
  queryDictionary: {},
  showFourOFour: false,
  searchResults: [],
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    listProducts: (state, action) => {
      const { language, filters, productIds } = action.payload;
      let newList = { ...state.list };

      if (language) {
        let requestFilters = "";
        filters.forEach((filter) => {
          requestFilters += `&filter[${filter.key}]=${filter.value}`;
        });
        if (
          state.queryDictionary[language] &&
          state.queryDictionary[language][requestFilters]
        ) {
          newList = state.queryDictionary[language][requestFilters];
        } else if (
          state.listFilters &&
          state.listFilters.find &&
          state.listFilters.find((filt) => filt.key === "taxons") &&
          filters &&
          filters.find((filt) => filt.key === "taxons") &&
          state.listFilters.find((filt) => filt.key === "taxons").value !==
            filters.find((filt) => filt.key === "taxons").value
        ) {
          newList = {};
        } else if (
          productIds &&
          productIds.length > 0 &&
          state.productsDictionary[language]
        ) {
          let selectedProducts = [];
          productIds.forEach((prodId) => {
            if (state.productsDictionary[language][prodId])
              selectedProducts.push(state.productsDictionary[language][prodId]);
          });
          if (selectedProducts.length > 0) newList = { data: selectedProducts };
        }
      }

      state.listLoading = true;
      state.listError = null;
      state.list = newList;
      state.listFilters = filters;
      state.showFourOFour = false;
    },
    listProductsSuccess: (state, action) => {
      const { language, requestFilters, data } = action.payload;
      let newProductsDict = { ...state.productsDictionary };
      if (!newProductsDict[language]) newProductsDict[language] = {};
      data.forEach((prod) => (newProductsDict[language][prod.id] = prod));
      let newQueryDict = { ...state.queryDictionary };
      if (!newQueryDict[language]) newQueryDict[language] = {};
      newQueryDict[language][requestFilters] = action.payload;
      state.listLoading = false;
      state.list = action.payload;
      state.showFourOFour = false;
      state.productsDictionary = newProductsDict;
      state.queryDictionary = newQueryDict;
    },
    listProductsFail: (state, action) => {
      state.listLoading = false;
      state.list = action.payload;
      state.listError = action.payload ? action.payload.message || true : true;
    },
    searchProducts: (state) => {
      state.searchLoading = true;
      state.showFourOFour = false;
      state.searchError = false;
    },
    searchProductsSuccess: (state, action) => {
      const { language, requestFilters, data } = action.payload;
      let newProductsDict = { ...state.productsDictionary };
      if (!newProductsDict[language]) newProductsDict[language] = {};
      data.forEach((prod) => (newProductsDict[language][prod.id] = prod));
      let newQueryDict = { ...state.queryDictionary };
      if (!newQueryDict[language]) newQueryDict[language] = {};
      newQueryDict[language][requestFilters] = action.payload;
      state.searchLoading = false;
      const params = new URLSearchParams(requestFilters);
      const nameValue = params.get("filter[name]");
      state.currentSearchTerm = nameValue;
      state.searchResults = action.payload;
      state.showFourOFour = false;
      state.productsDictionary = newProductsDict;
      state.queryDictionary = newQueryDict;
    },
    searchProductsFail: (state, action) => {
      state.searchLoading = false;
      state.search = action.payload;
      state.searchError = action.payload
        ? action.payload.message || true
        : true;
    },
    showProduct: (state, action) => {
      const currentList = state.list;
      let newShow = { ...state.show };
      const { language, slug } = action.payload;
      if (
        language &&
        slug &&
        state.productsDictionary[language] &&
        currentList.data
      ) {
        let listEntry = currentList.data.find(
          (prod) => prod.attributes.slug === slug
        );
        if (
          listEntry &&
          listEntry.id &&
          state.productsDictionary[language][listEntry.id]
        ) {
          newShow = {
            data: { ...state.productsDictionary[language][listEntry.id] },
          };
        }
      }

      state.showLoading = true;
      state.showError = null;
      state.errorRedirect = null;
      state.show = newShow;
      state.showFourOFour = false;
    },
    showProductSuccess: (state, action) => {
      state.showLoading = false;
      state.showFourOFour = false;
      state.show = action.payload;
    },
    showProductFail: (state, action) => {
      let showFourOFour = false;
      if (action?.payload?.responseStatus === 404) {
        showFourOFour = true;
      }
      if (action?.payload?.errorRedirect) {
        state.errorRedirect = action?.payload?.errorRedirect;
      }

      state.showLoading = false;
      state.show = showFourOFour ? null : action.payload;
      state.showError = action.payload?.message || true;
      state.showFourOFour = showFourOFour;
    },
    showProductAndLoadCMSBlocks: (state, action) => {
      const currentList = state.list;
      let newShow = { ...state.show };
      const { language, slug } = action.payload;
      if (
        language &&
        slug &&
        state.productsDictionary[language] &&
        currentList.data
      ) {
        let listEntry = currentList.data.find(
          (prod) => prod.attributes.slug === slug
        );
        if (
          listEntry &&
          listEntry.id &&
          state.productsDictionary[language][listEntry.id]
        ) {
          newShow = {
            data: { ...state.productsDictionary[language][listEntry.id] },
          };
        }
      }
      state.showLoading = true;
      state.showError = null;
      state.errorRedirect = null;
      state.show = newShow;
      state.showFourOFour = false;
    },
  },
});

export const {
  listProducts,
  listProductsSuccess,
  listProductsFail,
  searchProducts,
  searchProductsSuccess,
  searchProductsFail,
  showProduct,
  showProductSuccess,
  showProductFail,
  showProductAndLoadCMSBlocks,
} = productsSlice.actions;
export const actions = productsSlice.actions;

export default productsSlice.reducer;
