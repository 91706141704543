/**
 *  Sagas for the content resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from 'redux-saga/effects';
import buildHeaders from '../../../utils/buildHeaders';
import { actions } from './index';
import apiClient, { applyHeaders } from '../utils/apiSwaggerRequest';
import { storeIncluded } from '../utils/dictionarySagas';

/** Worker Sagas */

/** Show Saga
 *  @description: connects to the showStaticPage operation
 *  @param {number} action.payload the content id
 */
export function* show(action) {
  const lang = yield select(state => state.i18nState.lang);
  let headers = buildHeaders();
  headers['Accept-Language'] = lang
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.FormSteps.showFormStep,
      { id: action.payload },
      { requestInterceptor: applyHeaders(headers) },
    );
    if (payload.obj?.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }
    yield put({ type: actions.showSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* formStepsSaga() {
  yield takeEvery(actions.show, show);
}
export default formStepsSaga;
