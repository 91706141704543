/**
 * The FlashAlert component
 */
import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from "prop-types";
import {isServer} from "../../redux/store";

/**
 * Enhancer for reactstrap Alert
 * - adds timeout property (in seconds) to define the timeout to close the Alert
 */
class FlashAlert extends React.Component {
  static propTypes = {
    /** The flash alert color ('danger', 'success'...) */
    color: PropTypes.string,
    /** The timeout in seconds */
    timeout: PropTypes.number,
    /** A link to navigate */
    link: PropTypes.string,
  };

  /**
   * @constructor adds the visible property to the state, binds the onDismiss method
   * @param {Object} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  /**
   * Sets the timeout on component mount
   */
  componentDidMount() {
    const {timeout} = this.props
    if (timeout) {
      setTimeout(() => {
        this.onDismiss()
      }, timeout * 1000);
    }
  }

  /**
   * Dismisses the flash alert by changing its visibility
   */
  onDismiss() {
    this.setState({ visible: false });
  }

  /**
   * click action
   */
  onClick() {
    const { link } = this.props;
    const reload = link === 'reload'
    if(reload){
      this.setState({ visible: false });
      if(!isServer) window.location.reload()
    }

  }

  /**
   * Renders the Alert component
   */
  render() {
    const { children, color, link, skipDismiss } = this.props;
    const showReload = link === 'reload'
    const context = this.context;
    
    return (
      <Alert color={color} isOpen={this.state.visible} toggle={skipDismiss ? undefined : this.onDismiss}>
        <div onClick={this.onClick}>
          {children}
          { showReload && 
            <span><br/>{context.t("New content is available. Please click here to refresh the page and avoid inconsistencies.")}</span>
          }
        </div>
        
      </Alert>
    );
  }
}

/**  define proptype for the 'translation' function  */
FlashAlert.contextTypes = {
  t: PropTypes.func
}

export default FlashAlert;