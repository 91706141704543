/**
 *  Reducer function for the faqs resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/faqs
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';


/** The faqs reducer initial state */
export const defaultState = {
  stored: {},
  search: [],
  loadingSearch: false,
  loadedSearch: false,
  searchError: null,
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The faqs list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The faqs list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of faqs
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload
      };
    },
    /** The faqs list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The faqs search action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.search]: (state) => {
      return {
        ...state,
        loadingSearch: true,
        loadedSearch: false,
        searchError: null,
      };
    },
    /** The faqs search action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the search of faqs
     */
    [actions.searchSuccess]: (state, action) => {
      return {
        ...state,
        loadingSearch: false,
        loadedSearch: true,
        search: action.payload
      };
    },
    /** The faqs search action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.searchFail]: (state, action) => {
      return {
        ...state,
        loadingSearch: false,
        loadedSearch: false,
        searchError: action.payload.mess
      };
    },
    /** The faq show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null
      };
    },
    /** The faq show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested faq
     */
    [actions.showSuccess]: (state, action) => {
      if (state.stored[action.payload.id]) {
        action.payload.stored = true
      }    
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The faq show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload
      };
    },
    /** The faq create action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.create]: (state) => {
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };
    },
    /** The faq create action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the created faq
     */
    [actions.createSuccess]: (state, action) => {
      var newList = state.list.push(action.payload)
      return {
        ...state,
        list: newList,
        creating: false,
        created: true,
        show: action.payload
      };
    },
    /** The faq create action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.createFail]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: false,
        createError: action.payload.mess
      };
    },
    /** The faq update action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.update]: (state) => {
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };
    },
    /** The faq update action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the updated of faqs
     */
    [actions.updateSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        show: action.payload
      };
    },
    /** The faq update action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.updateFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.payload.mess
      };
    },
    /** The faq remove action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.remove]: (state) => {
      return {
        ...state,
        removing: true,
        removed: false,
        removeError: null,
      };
    },
    /** The faq remove action success
     * @param {Object} state the current state of this reducer
     */
    [actions.removeSuccess]: (state) => {
      return {
        ...state,
        removing: false,
        removed: true,
      };
    },
    /** The faq remove action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.removeFail]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action.payload.mess
      };
    },
    /** The store faq action. Saves the currently shown faq in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeFaq]: (state) => {
      let newStoredFaqs = Object.assign({}, state.stored)
      newStoredFaqs[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredFaqs,
        show: newShow
      };
    },
    /** The unstore faq action. Removes the currently shown faq from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the id of the faq to unstore
     */
    [actions.unstoreFaq]: (state, action) => {
      let newStoredFaqs = Object.assign({}, state.stored)
      delete newStoredFaqs[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredFaqs,
        show: newShow
      };
    }
  },
  defaultState
);
