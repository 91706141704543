import { createSlice } from "@reduxjs/toolkit";

const fileSlice = createSlice({
  name: "editorFile",
  initialState: {
    uploading: false,
    uploaded: false,
    uploadedFile: null,
    uploadedSilent: false,
    preview: "",
    crop: false,
    cropping: false,
    imagename: null,
    filetype: null,
    uploadError: null,
  },
  reducers: {
    upload: (state) => {
      state.uploading = true;
      state.uploaded = false;
      state.error = null;
    },
    uploadSuccess: (state, action) => {
      state.uploading = false;
      state.uploaded = true;
      state.uploadedFile = action.payload;
      state.imagename = action.payload.imagename;
      state.filetype = action.payload.filetype;
      state.preview = action.payload.imagepath;
    },
    uploadSuccessSilent: (state) => {
      state.uploading = false;
      state.uploadedSilent = true;
    },
    uploadFail: (state, action) => {
      state.uploading = false;
      state.preview = null;
      state.uploadError = action.error;
    },
    updateCrop: (state, action) => {
      state.crop = action.payload.crop;
    },
    startCrop: (state) => {
      state.cropping = true;
    },
    endCrop: (state) => {
      state.cropping = false;
      state.crop = false;
    },
    reset: (state) => {
      state.uploading = false;
      state.uploaded = false;
      state.uploadedFile = null;
      state.uploadedSilent = false;
      state.preview = "";
      state.crop = false;
      state.cropping = false;
      state.imagename = null;
      state.filetype = null;
      state.uploadError = null;
    },
  },
});

// Extract the actions to use them in your components or sagas
export const {
  upload,
  uploadSuccess,
  uploadSuccessSilent,
  uploadFail,
  updateCrop,
  startCrop,
  endCrop,
  reset,
} = fileSlice.actions;
export const actions = fileSlice.actions;

// And don't forget to export the reducer
export default fileSlice.reducer;
