/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import { all } from "redux-saga/effects";
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import xapisSaga from "./xapis/sagas";
import notificationsSaga from "./notifications/sagas";
import faqsSaga from "./faqs/sagas";
import faqcategoriesSaga from "./faqcategories/sagas";
import fontsSaga from "./fonts/sagas";
import landingpagesSaga from "./landingpages/sagas";
import editorShopSaga from "./editor/shop/sagas";
import shopProductsSagas from "./shop/products/sagas";
import shopTaxonsSagas from "./shop/taxons/sagas";
import shopCartSagas from "./shop/cart/sagas";
import supportCenterFormStepsSagas from "./supportcenter/form_steps/sagas";
import supportCenterCasesSagas from "./supportcenter/cases/sagas";
import shopCheckoutSagas from "./shop/checkout/sagas";
import shopAdyenSagas from "./shop/pay-adyen/sagas";
import shopAmazonPaySagas from "./shop/pay-amazon/sagas";
import shopPayPalPaySagas from "./shop/pay-paypal/sagas";
import shopCountriesSagas from "./shop/countries/sagas";
import editorFileSaga from "./editor/file/sagas";
import cmsblocksSaga from "./cmsblocks/sagas";
import blogPostsSaga from "./blogposts/sagas";
import orderReviewsSaga from "./cop/order_reviews/sagas";
import lineItemFeedbacksSaga from "./cop/line_item_feedback/sagas";
import tmpFilesSaga from "./cop/tmpFiles/sagas";

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    xapisSaga(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
    faqsSaga(),
    faqcategoriesSaga(),
    fontsSaga(),
    landingpagesSaga(),
    cmsblocksSaga(),
    editorShopSaga(),
    editorFileSaga(),
    shopProductsSagas(),
    shopTaxonsSagas(),
    shopAdyenSagas(),
    shopAmazonPaySagas(),
    shopPayPalPaySagas(),
    shopCartSagas(),
    shopCheckoutSagas(),
    shopCountriesSagas(),
    supportCenterFormStepsSagas(),
    supportCenterCasesSagas(),
    blogPostsSaga(),
    orderReviewsSaga(),
    lineItemFeedbacksSaga(),
    tmpFilesSaga(),
  ]);
}
