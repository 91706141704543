import { createSlice, createSelector } from "@reduxjs/toolkit";

export const orderReviewsSlice = createSlice({
  name: "orderReviews",
  initialState: {
    show: null,
    list: [],
    loadingShow: false,
    loadedShow: false,
    showError: null,
    creating: false,
    created: false,
    createError: null,
    updating: false,
    updated: false,
    updateError: null,
    removing: false,
    removed: false,
    removeError: null,
    idsList: [],
    dictionary: {},
    slugIndex: {},
    showId: null,
    filtersToIdsDictionary: {},
  },
  reducers: {
    list: (state, action) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
      state.list = [];
    },
    listSuccess: (state, action) => {
      const responseList = action.payload.data || [];
      const responseIds = action.payload.data.map((a) => a.id);

      state.loadingList = false;
      state.loadedList = true;

      state.list = responseList;
      state.idsList = responseIds;
      responseList.forEach((entry) => (state.dictionary[entry.id] = entry));
    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    show: (state, action) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
      state.show = state.dictionary[action.payload] || {};
      state.showId = action.payload?.review_hash || null;
    },
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload.data;
      state.showId = action.payload.data.id;
      state.dictionary[action.payload.data.id] = action.payload.data;
    },
    showFail: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = false;
      state.showError = action.payload?.response?.body;
    },
    updateDictionary: (state, action) => {
      action.payload.forEach((static_page) => {
        state.dictionary[static_page.id] = static_page;
      });
    },
    addToDictionary: (state, action) => {
      state.dictionary[action.payload.id] = {
        ...state.dictionary[action.payload.id],
        ...action.payload,
      };
    },
  },
});

export const {
  list: listOrderReviews,
  show: showOrderReview,
  updateDictionary: updateOrderReviewsDictionary,
  addToDictionary: addToOrderReviewsDictionary,
} = orderReviewsSlice.actions;

export const actions = orderReviewsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const orderReviewsList = (state) =>
  state.orderReviews.idsList.map((id) => state.orderReviews.dictionary[id]);

export const orderReviewShowId = (state) => state.orderReviews.showId;

export const getOrderReview = createSelector(
  (state) => state.orderReviews.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getOrderReviewIdByHash = createSelector(
  (state) => state.orderReviews.dictionary,
  (_, reviewHash) => reviewHash,
  (dictionary, reviewHash) => {
    return (
      reviewHash &&
      dictionary[
        Object.keys(dictionary).find((d_order_review_id) => {
          return (
            dictionary[d_order_review_id].attributes.review_hash === reviewHash
          );
        })
      ]?.attributes
    );
  }
);

export const getOrderReviewAttributes = createSelector(
  (state) => state.orderReviews.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getOrderReviewRelationships = createSelector(
  (state) => state.orderReviews.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export default orderReviewsSlice.reducer;
