/**
 * The FormattedPrice component
 */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router';

/**
 * Stateless react component to render the FormattedPrice component
 * @param {Object} props the props passed to the page.
 */

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithDots(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const FormattedPrice = (props) => {
  if (props.value){
    let value = parseFloat(props.value);
    let integerPart = '' + parseInt(value);
    let decimals = props.decimals || 0 
    let decimalPart = `${(value % 1).toFixed(decimals)}`.substring(2);
    let separator = '';
    if (props.language &&  props.language === 'en'){
      separator = '.';
      integerPart = numberWithCommas(integerPart);
    }
    else {
      separator = ',';
      integerPart = numberWithDots(integerPart);
    }
    let unit = props.unit || ''
    if (props.addSchema) {
      return (
        <span className="formattednumber">
          <span className="formattednumber__integer" itemprop="price" content={`${parseFloat(props.value)}`}>
            {integerPart}
            { (decimals > 0 )&&
              <>{separator}{decimalPart}</>
            }
          </span>
          {unit && <span itemprop="priceCurrency" content="EUR">&nbsp;{unit}</span>}
        </span>
      );
    } else {
      return (
        <span className="formattednumber">
          <span className="formattednumber__integer">
            {integerPart}
            { (decimals > 0 )&&
              <>{separator}{decimalPart}</>
            }
          </span>
          {unit && <span>&nbsp;{unit}</span>}
        </span>
      );
    }
  } else {
    return '';
  }
  
  
}

/** define proptypes for the form */
FormattedPrice.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
  decimals: PropTypes.number,
};

/**  define proptype for the 'translation' function  */
FormattedPrice.contextTypes = {
  t: PropTypes.func
}
export default withRouter(FormattedPrice);
