/**
 *  Sagas for the font resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import { actions } from './index';
import {isServer} from "../store";
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';
import DataLayerHelper from '../../utils/dataLayer'
import Swagger from 'swagger-client'

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getFonts operation
 */
export function* list() {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedFonts = yield select(state => state.fonts.stored);
    Object.keys(storedFonts).forEach(storedFontIndex => {
      storedList.push(Object.assign({}, storedFonts[storedFontIndex]))
    })
    yield put(actions.listSuccess(storedList));
  } else { 
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.Fonts.getFonts, {}, {requestInterceptor: applyHeaders(headers)})
      yield put(actions.listSuccess(payload.obj));
    } catch (e) {
      yield put(actions.listFail(e));
    }
  }
}

/** Show Saga
 *  @description: connects to the showFont operation
 *  @param {number} action.payload the font id
 */
export function* show(action) {
  const fontId = action.payload.id;
  const text = action.payload.text;
  const lineIndex = action.payload.lineIndex;
  let line = action.payload.line;
  const storedFonts = yield select(state => state.fonts.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedFonts[fontId]) {
    yield put(actions.showSuccess(storedFonts[fontId])
    );
  } else { // else we are online -> we fetch
    let headers = buildHeaders();
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.Fonts.showFont, {id: fontId}, {requestInterceptor: applyHeaders(headers)})
      yield put(actions.showSuccess(payload.obj));
      if (text && line){
        yield put(actions.checkMissingChars({lineIndex, text, line, fontTitle: payload.obj.title }));
      }
      yield put
    } catch (e) {
      yield put(actions.showFail(e));
    }
  }
}

/** upload custom font goes to leagacy api! */

export function* uploadCustomFont(action) {
  const requestStart = new Date();
  const files = action.payload.files;
  const sku = action.payload.sku;
  // const options = action.payload.options;
  const file = files[0]
  try {
    const payload = yield call(apiRequest, `https://media.myfolie.com/api/upload-local2.php?file=${file.name}&unique_id=${sku}`, { file, method: 'POST' });
    yield put(actions.uploadCustomFontSuccess(payload));

    // very complexe but it works...
    const svgObj = JSON.parse(payload.svg);
    const fontSvg = svgObj[Object.keys(svgObj)[0]].substr(1);

    const customFont = {
      id: 'custom-' + payload.fontname,
      min_font_size: 13,
      title: payload.fontname,
      slant: 1,
      thickness: 8,
      width: 3,
      styles: [
        {
          available: true,
          loaded: true,
          svg: fontSvg,
          title: "regular"
        }
      ]
    }
    yield put(actions.prependCustomFont(customFont));
    DataLayerHelper.addEventWithOrigin('success', 'text editor', 'upload', 'duration', new Date() - requestStart)

  } catch (e) {
    yield put(actions.uploadCustomFontFail(e));
    DataLayerHelper.addEventWithOrigin('error', 'text editor', 'upload', `${e.message} - ${file.name}`)
  }
}


/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* fontsSaga() {
  yield takeEvery(actions.uploadCustomFont, uploadCustomFont);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}
export default fontsSaga;


const apiRequest = (_url, args) => {
  const request = {
    url: _url,
    method: args.method,
    body: args.file,
    headers: args.headers,
  }
  return Swagger.http(request)
  .then((res) => {
    return res.body;
  })
  .catch((err) => {
    console.log('ERROR IN APIREQUEST')
    console.log(err);
    throw err
  })
};

