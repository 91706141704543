/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import DataLayerHelper from "../../../utils/dataLayer";
import { actions as checkoutActions } from "../checkout";
import { actions as cartActions } from "../cart";
export function* createPayPalOrder(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/pay_pal/create_order.json`,
      {
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    yield put(actions.showPayPalPayConfigSuccess(payload));
  } catch (e) {
    yield put(actions.showPayPalPayConfigFail(e));
    DataLayerHelper.addErrorEvent(
      "showApayPalPayConfig",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* approvePayPalPay(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/pay_pal/approve_order.json?include=shipping_address,user,payments,billing_address,line_items,line_items.products,line_items.product,variants.product,variants.products,variants,variants.images,shipping_address,user,payments,billing_address,variants.products,variants.products.images`,
      {
        body: JSON.stringify(action.data),
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    if (action?.payload?.callback) action.payload.callback();
    if (payload?.data?.attributes?.number)
      yield put(cartActions.showCartSuccess(payload)); // update cart if we get back a cart
    yield put(actions.approvePayPalPaySuccess(payload));
  } catch (e) {
    yield put(actions.approvePayPalPayFail(e));
    DataLayerHelper.addErrorEvent(
      "approvePayPal",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* finalizePayPalPay(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  const { callbackSuccess, cart } = action.payload;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/pay_pal/complete_order.json?include=shipping_address,user,payments,billing_address,line_items,line_items.products,line_items.product,variants.product,variants.products,variants,variants.images,shipping_address,user,payments,billing_address,variants.products,variants.products.images`,
      {
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    if (payload?.name === "UNPROCESSABLE_ENTITY") {
      if (payload?.details[0]?.issue === "PAYER_ACTION_REQUIRED") {
        const payerActionRedirect = payload?.links?.find(
          (ll) => ll.rel === "payer-action"
        )?.href;
        if (payerActionRedirect) window.location = payerActionRedirect;
      } else {
        alert(
          "Unkown Error, please contact Support: " + payload?.details[0]?.issue
        );
      }
    } else {
      yield put(cartActions.showCartSuccess(payload));
      if (cart) DataLayerHelper.addPurchaseEvent(cart); // FIXME this is not perfect, we might track failed transactions this way!
      yield put(checkoutActions.completeCheckoutSuccess(payload));
    }
    yield put(actions.finalizePayPalPaySuccess(payload));
  } catch (e) {
    alert(e.error || e.message);
    yield put(actions.finalizePayPalPayFail(e));
    DataLayerHelper.addErrorEvent(
      "resetPayPalPayConfig",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopPayPalPaySagas() {
  yield takeEvery(actions.createPayPalOrder, createPayPalOrder);
  yield takeEvery(actions.approvePayPalPay, approvePayPalPay);
  yield takeEvery(actions.finalizePayPalPay, finalizePayPalPay);
}

export default shopPayPalPaySagas;
