import Swagger from 'swagger-client'
import { REACT_APP_CONSENT_API_SERVER } from '../../utils/env';
const CONSENT_API_URL = REACT_APP_CONSENT_API_SERVER || 'http://localhost:9090'

const apiRequest = (_url, args) => {
  const request = {
    url: CONSENT_API_URL + _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
    credentials: "include",
  }
  return Swagger.http(request)
  .then((res) => {
    return res.body;
  })
  .catch((err) => {
    console.log('ERROR IN CONSENT_API_REQUEST')
    console.log(err);
    throw err;
  })
};

export default apiRequest;
