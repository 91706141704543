/**
 * The Loading component, used while resources load
 * Keep this component as similar as possible to the resource page importing it
 */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Row,
  Container,
  Col,
  Button,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import FormattedNumber from "../FormattedNumber";
import {
  updateLineItemInCart,
  showCart,
  removeLineItemFromCart,
} from "../../redux/shop/cart";
import {
  FaShoppingCart,
  FaRegCreditCard,
  FaTrash,
  FaGhost,
  FaUpload,
  FaCar,
  FaScroll,
  FaHeading,
} from "react-icons/fa";
import CheckoutModal from "./utils/CheckoutModal";
import DataLayerHelper from "../../utils/dataLayer";
import { useSsrEffect } from "@issr/core";
import {
  REACT_APP_SHOP_CDN_SERVER,
  REACT_APP_CDN_SERVER,
} from "../../utils/env";
import fileguideIcon from "../../assets/images/icons/fileguide_colored.svg";
import { push } from "connected-react-router";

const CheckoutCart = (props, context) => {
  const {
    preventFrontload,
    language,

    navigateTo,
    isFullscreen,

    isUneditable,
    closeCart,
  } = props;

  const { cart, cartUpdating, cartError, deletingItemId, updatingItemId } =
    useSelector((state) => ({
      cart: state.shopCart.cart,
      cartUpdating: state.shopCart.cartUpdating,
      cartError: state.shopCart.cartError,
      deletingItemId: state.shopCart.deletingItemId,
      updatingItemId: state.shopCart.updatingItemId,
    }));

  const dispatch = useDispatch();
  const frontload = async () => {
    if (!preventFrontload) {
      dispatch(showCart());
    }
  };

  useSsrEffect(frontload, [preventFrontload, showCart]);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [redirectToCart, setRedirectToCart] = useState(false);

  const deleteProduct = (product) => {
    if (
      window.confirm(
        product.attributes.sku === "889900-sku"
          ? context.t(
              "Caution: You're on the verge of removing the Professional Filecheck. Ensure your file adheres to our Production Data Guidelines to prevent unforeseen outcomes!"
            )
          : context.t(
              "Are you sure you wish to delete this item from your cart? This cannot be undone."
            )
      )
    ) {
      dispatch(removeLineItemFromCart({ lineItemId: product.id }));
    }
  };

  const checkForEnter = (product, e) => {
    if (e.keyCode === 13) {
      updateAmount(product, e);
    }
  };

  const cancelSendToCheckout = (closeCartOnCancel) => {
    if (closeCartOnCancel === true && closeCart) closeCart();
    setRedirectToCheckout(false);
  };

  const sendToCheckout = () => {
    setRedirectToCheckout(false);
    DataLayerHelper.addRedirectEvent("checkout shop", "checkoutModal");
    if (navigateTo) {
      navigateTo(`/${language}/checkout/process/`);
    } else {
      window.location = `/${language}/checkout/process/`;
    }

    // this.setState({ redirectToCheckout: true })
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   'event':'redirect',
    //   'action': 'checkout selection',
    //   'label': 'SidebarCart',
    //   'value': 0
    // });
  };

  const sendToShipping = () => {
    DataLayerHelper.addRedirectEvent("shippingCost", "SidebarCart");
  };

  const getLineItemImage = (variant_id) => {
    const variant = cart.included.find((item) => {
      return item.type === "variant" && item.id === variant_id;
    });
    let image = {};
    let fallbackImage = REACT_APP_CDN_SERVER + "/myfolie-logo.svg";
    if (variant) {
      if (
        variant.relationships.images &&
        variant.relationships.images.data?.length > 0
      ) {
        image = cart.included.find((item) => {
          return (
            item.type === "image" &&
            item.id === variant.relationships.images.data[0].id
          );
        });
      } else {
        image = cart.included.find((item) => {
          return (
            item.type === "image" &&
            item.id === variant.relationships.default_image.data.id
          );
        });
      }
      fallbackImage = REACT_APP_SHOP_CDN_SERVER + variant.attributes.thumbnail;
    }
    return image && image.attributes
      ? REACT_APP_SHOP_CDN_SERVER + image.attributes.styles[3].url
      : fallbackImage;
  };
  const sendToCart = () => {
    setRedirectToCart(true);
    DataLayerHelper.addRedirectEvent("cart", "SidebarCart");
    if (navigateTo) {
      dispatch(push(`/${language}/checkout/cart/`));
      if (closeCart) closeCart();
    } else {
      window.location = `/${language}/checkout/cart/`;
    }
  };

  const updateAmount = (product, e) => {
    let newAmount = parseInt(e.target.value);
    if (newAmount === 0 || isNaN(newAmount)) {
      e.target.value = product.attributes.quantity;
      return;
    }
    if (product.attributes.quantity !== newAmount) {
      if (newAmount < 1) {
        newAmount = 1;
      }
      if (
        newAmount < 50 &&
        (product.attributes.sku === "individual-sticker-diecut" ||
          product.attributes.sku === "individual-sticker-rectangular" ||
          product.attributes.sku === "individual-sticker-circle")
      ) {
        newAmount = 50; // minimum amount for sticker
      }
      e.target.value = newAmount;
      dispatch(
        updateLineItemInCart({ line_item_id: product.id, quantity: newAmount })
      );
    }
  };

  const line_items =
    cart && cart.included
      ? cart.included.filter((item) => {
          return item.type === "line_item";
        }) || []
      : [];

  let skuExtras = []; // products.filter((prod) => { return (prod.sku === '889900-sku' || prod.sku === 'productiondata-sku' || prod.sku === 'vektorisierung-sku' || prod.sku === '889900' || prod.sku === 'productiondata' || prod.sku === 'vektorisierung') });
  const renderSkuExtras = (sku) => {
    if (sku?.length > 10) {
      // all items should have more than 10 characters that are possible for filechecks
      const thisSkuExtras = skuExtras.filter((prod) => {
        return prod.options.includes(sku);
      });
      if (thisSkuExtras.length > 0) {
        skuExtras = skuExtras.filter((prod) => {
          return !prod.options.includes(sku);
        });
        return thisSkuExtras.map((extra) => {
          return (
            <>
              <Col xs={2}>
                <img
                  style={{ maxWidth: "40px" }}
                  src={extra?.thumbnail?.img_url || extra?.thumbnail}
                  alt={extra.title}
                  title={extra.title}
                />
              </Col>
              <Col xs={7} className="text-small">
                {extra.title}
                <br />
                <Button
                  color="link"
                  className="p-0"
                  size="sm"
                  onClick={() => deleteProduct(extra)}
                >
                  {context.t("Remove")}
                </Button>
              </Col>
              <Col xs={3} className="text-right">
                <span className="text-success pt-1" style={{ width: "60px" }}>
                  <FormattedNumber
                    value={extra.total}
                    language={language}
                    unit="€"
                    decimals={2}
                  />
                </span>
              </Col>
            </>
          );
        });
      }
    }
  };

  return (
    <div>
      {!isFullscreen &&
        cart?.data?.attributes?.item_count > 0 &&
        cart?.data?.attributes?.state !== "complete" && (
          <Container className="bg-light py-3" style={{ height: "100%" }}>
            <Row>
              <Col md={{ size: 6, order: 2 }}>
                <Button
                  color="success"
                  disabled={
                    cartUpdating || redirectToCheckout || redirectToCart
                  }
                  className="mt-1"
                  block
                  onClick={sendToCheckout}
                >
                  {context.t("checkout")} <FaRegCreditCard />
                </Button>
              </Col>
              <Col md={{ size: 6, order: 1 }}>
                {!isFullscreen && (
                  <Button
                    color="secondary"
                    disabled={
                      cartUpdating || redirectToCheckout || redirectToCart
                    }
                    className="mt-1"
                    block
                    outline
                    onClick={sendToCart}
                  >
                    {context.t("show cart")} <FaShoppingCart />
                  </Button>
                )}
              </Col>
              {(redirectToCheckout || redirectToCart) && (
                <Col xs={12} className="text-center mt-1 text-secondary">
                  <Spinner size="sm" className="mr-2" />
                  {context.t("one moment please")}
                </Col>
              )}
            </Row>
          </Container>
        )}
      {line_items && (
        <Container
          className={
            isFullscreen
              ? "bg-white shadow-sm"
              : "bg-white checkoutcart__lineitems--notfullscreen"
          }
          style={
            !isFullscreen
              ? { overflow: "auto", boxShadow: "inset 0 5px 5px -5px #ddd" }
              : {}
          }
        >
          {cart?.data?.attributes?.state === "confirm" && (
            <h3 className="h6 text-secondary pt-2">{context.t("Cart")}</h3>
          )}

          {cartError && (
            <p className="alert alert-danger">
              {cartError}
              {cartError === true && context.t("An unkown error occured.")}
              <br />
              {context.t(
                "Please try again. If the error persists, please contact our support and attach a screenshot: kontakt@myfolie.com."
              )}
            </p>
          )}
          {(line_items.length === 0 ||
            cart?.data?.attributes?.state === "complete") && (
            <>
              <p
                className="text-center py-4 text-secondary"
                style={{ opacity: 0.5 }}
              >
                <span className="pb-2 d-block h1">
                  <FaGhost />
                </span>
                {context.t("Your cart is empty.")}
              </p>
              {cartUpdating && (
                <p className="semi-small ml-2 text-center">
                  <Spinner size="sm" color="primary" />{" "}
                  {context.t("updating cart")}
                </p>
              )}
            </>
          )}

          {(line_items.length === 0 ||
            (cart &&
              cart.data &&
              cart.data.attributes.state === "complete")) && (
            <Row>
              <Col xs={12} className="h6 text-center">
                {context.t("A few ideas for your cart")}
              </Col>
              <Col xs={6}>
                <Link
                  onClick={closeCart ? closeCart : false}
                  to={`/${language}/klebeschriften/`}
                  className="shadow-sm p-2 my-2 d-block text-center text-secondary"
                >
                  <span className="pb-2 d-block h1">
                    <FaHeading />
                  </span>
                  {context.t("Design adhesive lettering")}
                </Link>
              </Col>
              <Col xs={6}>
                <Link
                  onClick={closeCart ? closeCart : false}
                  to={`/${language}/eigenes-motiv/`}
                  className="shadow-sm p-2 my-2 d-block text-center text-secondary"
                >
                  <span className="pb-2 d-block h1">
                    <FaUpload />
                  </span>
                  {context.t("Upload your design")}
                </Link>
              </Col>
              <Col xs={6}>
                <Link
                  onClick={closeCart ? closeCart : false}
                  to={`/${language}/p/autoaufkleber/`}
                  className="shadow-sm p-2 my-2 d-block text-center text-secondary"
                >
                  <span className="pb-2 d-block h1">
                    <FaCar />
                  </span>
                  {context.t("More products for your vehicle")}
                </Link>
              </Col>
              <Col xs={6}>
                <Link
                  onClick={closeCart ? closeCart : false}
                  to={`/${language}/p/moebelfolie/`}
                  className="shadow-sm p-2 my-2 d-block text-center text-secondary"
                >
                  <span className="pb-2 d-block h1">
                    <FaScroll />
                  </span>
                  {context.t("Shop vinyls for your furniture")}
                </Link>
              </Col>
            </Row>
          )}
          {line_items.map((product) => (
            <Row
              style={{
                borderBottom: "1px solid #eee",
                opacity: deletingItemId === product.id ? "0.4" : "1",
              }}
              className="py-1"
              key={product.id}
            >
              <Col xs={12}>
                <span
                  className="h5 py-1 d-inline-block"
                  style={{ maxWidth: "90%" }}
                >
                  {product.attributes.name}
                </span>
                {!isUneditable && (
                  <Button
                    onClick={() => deleteProduct(product)}
                    color="danger"
                    size="sm"
                    outline
                    className="pull-right border-0"
                  >
                    <FaTrash />
                  </Button>
                )}
              </Col>
              <Col xs={4}>
                {product.attributes.project_uid &&
                  product.attributes.project_meta &&
                  JSON.parse(product.attributes.project_meta).thumbnail && (
                    <img
                      style={{ maxWidth: "100%", maxHeight: "120px" }}
                      src={
                        JSON.parse(product.attributes.project_meta).thumbnail
                      }
                      alt={product.attributes.name}
                      title={product.attributes.name}
                    />
                  )}
                {(!product.attributes.project_uid ||
                  (product.attributes.project_uid &&
                    product.attributes.project_meta &&
                    JSON.parse(product.attributes.project_meta)
                      .skip_file_upload) ||
                  product.attributes.project_uid.startsWith("shop_")) && (
                  <>
                    {getLineItemImage(
                      product.relationships.variant.data.id
                    ) && (
                      <img
                        style={{ maxWidth: "100%", maxHeight: "120px" }}
                        src={getLineItemImage(
                          product.relationships.variant.data.id
                        )}
                        alt={product.attributes.name}
                        title={product.attributes.name}
                      />
                    )}
                  </>
                )}
              </Col>
              <Col xs={8}>
                {!product.attributes.project_uid &&
                  product.attributes.options_text && (
                    <p>{product.attributes.options_text}</p>
                  )}
                {product.attributes.project_uid && (
                  <>
                    {/*FIXME uneeded double parsing*/}
                    {product.attributes.project_meta &&
                      JSON.parse(product.attributes.project_meta) && (
                        <p>
                          {JSON.parse(product.attributes.project_meta)
                            .title && (
                            <>
                              <strong>
                                {
                                  JSON.parse(product.attributes.project_meta)
                                    .title
                                }
                              </strong>
                              <br />
                            </>
                          )}
                          {
                            JSON.parse(product.attributes.project_meta)
                              .description
                          }
                        </p>
                      )}
                    <em className="text-remark">
                      Projekt ID:{" "}
                      <span className="bg-light p-1 rounded">
                        {product.attributes.project_uid}
                      </span>
                    </em>
                  </>
                )}
                <p className="text-remark">
                  {context.t("SKU")}: {product.attributes.sku}
                </p>
                {("889900-sku" === product.attributes.sku ||
                  product?.attributes?.project_uid?.startsWith(
                    "designer_"
                  )) && (
                  <p className="small">
                    <a
                      href={`/${language}/fileguide/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={fileguideIcon}
                        alt={context.t("Production Data Guidelines")}
                        height="18"
                      />{" "}
                      {context.t("Production Data Guidelines")}
                    </a>
                  </p>
                )}
                <Row>
                  <Col sm={6} xs={12} className="text-remark">
                    <strong>
                      <FormattedNumber
                        value={parseFloat(product.attributes.price)}
                        language={language}
                        unit="€"
                        decimals={2}
                      />
                    </strong>{" "}
                    ({context.t("per unit")})
                  </Col>
                  <Col sm={6} xs={12} className="text-right">
                    <InputGroup size="sm">
                      {updatingItemId === product.id && (
                        <span className="d-inline-block p-1">
                          <Spinner size="sm" color="secondary" />
                        </span>
                      )}
                      {isUneditable && (
                        <span className="text-info pt-1 pr-3">
                          {context.t("amount")}: {product.attributes.quantity}
                        </span>
                      )}
                      {!isUneditable && (
                        <Input
                          onKeyUp={(e) => checkForEnter(product, e)}
                          min={
                            product.attributes.sku ===
                              "individual-sticker-diecut" ||
                            product.attributes.sku ===
                              "individual-sticker-rectangular" ||
                            product.attributes.sku ===
                              "individual-sticker-circle"
                              ? "50"
                              : "1"
                          }
                          onBlur={(e) => updateAmount(product, e)}
                          defaultValue={product.attributes.quantity}
                          disabled={updatingItemId}
                          placeholder={context.t("amount")}
                          style={{ textAlign: "center" }}
                          className="mr-1 no-spin-buttons"
                          type="number"
                          step="1"
                        />
                      )}
                      <span
                        className="text-success pt-1"
                        style={{ width: "60px" }}
                      >
                        <strong>
                          <FormattedNumber
                            value={parseFloat(product.attributes.total)}
                            language={language}
                            unit="€"
                            decimals={2}
                          />
                        </strong>
                      </span>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              {product.sku && renderSkuExtras(product.sku)}
            </Row>
          ))}

          {skuExtras.map((product) => (
            <Row
              style={{
                borderBottom: "1px solid #eee",
                opacity: deletingItemId === product.id ? "0.4" : "1",
              }}
              className="py-1"
              key={product.id}
            >
              <Col xs={12}>
                {product.url && (
                  <a
                    href={`${product.url}`}
                    className="h6 py-1 d-inline-block"
                    style={{ maxWidth: "90%" }}
                  >
                    {product.title}
                  </a>
                )}
                {!product.url && (
                  <span
                    className="h6 py-1 d-inline-block"
                    style={{ maxWidth: "90%" }}
                  >
                    {product.title}
                  </span>
                )}
                {!isUneditable && (
                  <Button
                    onClick={() => deleteProduct(product)}
                    color="danger"
                    size="sm"
                    outline
                    className="pull-right border-0"
                  >
                    <FaTrash />
                  </Button>
                )}
              </Col>
              <Col xs={2}>
                <img
                  style={{ maxWidth: "50px" }}
                  src={product?.thumbnail?.img_url || product.thumbnail}
                  alt={product.title}
                  title={product.title}
                />
              </Col>
              <Col xs={10}>
                <p className="text-remark">
                  {context.t(
                    "This extra is normaly related to a specific item. The item was not found anymore in your order. We'll apply this extra on the first matching item of your order."
                  )}
                </p>
                {product?.options?.length > 0 && <p>{product.options}</p>}
                <p className="text-remark">
                  {context.t("SKU")}: {product.attributes.sku}
                </p>
                <Row>
                  <Col sm={6} xs={12} className="text-remark">
                    <strong>
                      <FormattedNumber
                        value={product.price}
                        language={language}
                        unit="€"
                        decimals={2}
                      />
                    </strong>{" "}
                    ({context.t("per unit")})
                  </Col>
                  <Col sm={6} xs={12} className="text-right">
                    <InputGroup size="sm">
                      {updatingItemId === product.id && (
                        <span className="d-inline-block p-1">
                          <Spinner size="sm" color="secondary" />
                        </span>
                      )}
                      {isUneditable && (
                        <span className="text-info pt-1 pr-3">
                          {context.t("amount")}: {product.attributes.quantity}
                        </span>
                      )}
                      {!isUneditable && (
                        <Input
                          onBlur={() => updateAmount(product)}
                          min={
                            product.attributes.sku ===
                              "individual-sticker-diecut" ||
                            product.attributes.sku ===
                              "individual-sticker-rectangular" ||
                            product.attributes.sku ===
                              "individual-sticker-circle"
                              ? "50"
                              : "1"
                          }
                          defaultValue={product.attributes.quantity}
                          disabled={updatingItemId}
                          placeholder={context.t("amount")}
                          style={{ textAlign: "center", fontSize: "16px" }}
                          className="mr-1 no-spin-buttons"
                          type="number"
                          step="1"
                        />
                      )}
                      <span
                        className="text-success pt-1"
                        style={{ width: "60px" }}
                      >
                        <strong>
                          <FormattedNumber
                            value={product.price * product.attributes.quantity}
                            language={language}
                            unit="€"
                            decimals={2}
                          />
                        </strong>
                      </span>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Container>
      )}

      {!isFullscreen && cart && cart.data && (
        <Container
          className="bg-white border-top border-bottom my-0 py-2"
          style={{
            width: "100%",
            boxShadow: "0 -5px 5px -5px #ddd",
            position: "relative",
          }}
        >
          <Row>
            <Col xs={6}>
              <span className="h6 pt-1 d-inline-block">
                {cart.data.attributes.item_count} {context.t("items")}
              </span>
              {cartUpdating && (
                <span className="semi-small ml-2">
                  <Spinner size="sm" color="primary" />{" "}
                  {context.t("updating cart")}
                </span>
              )}
            </Col>
            <Col
              xs={6}
              className="text-right"
              style={{ opacity: cartUpdating ? "0.4" : "1" }}
            >
              {parseFloat(cart.data.attributes.total) > 0 && (
                <>
                  {context.t("subtotal")}{" "}
                  <span className="h4 text-success">
                    <FormattedNumber
                      value={parseFloat(cart.data.attributes.total)}
                      language={language}
                      unit="€"
                      decimals={2}
                    />
                  </span>
                  <br />
                  <span className="small text-secondary">
                    {context.t("included VAT")}:{" "}
                    <FormattedNumber
                      value={parseFloat(cart.data.attributes.tax_total)}
                      language={language}
                      unit="€"
                      decimals={2}
                    />
                  </span>
                  <br />
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}

      {!isUneditable && (
        <>
          {redirectToCheckout && (
            <CheckoutModal
              language={language}
              onClose={cancelSendToCheckout}
              navigateTo={navigateTo}
            />
          )}
          <div
            className={isFullscreen ? "text-center my-2 small" : "text-center"}
          >
            {context.t("all prices incl. VAT")}
            &#160;-&#160;
            <Link to={`/${language}/p/versandarten/`} onClick={sendToShipping}>
              {context.t("plus shipping")}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
/**  define proptype for the 'translation' function  */
CheckoutCart.contextTypes = {
  t: PropTypes.func,
};

CheckoutCart.propTypes = {
  navigateTo: PropTypes.func,
  closeCart: PropTypes.func,
  preventFrontload: PropTypes.bool,
  isFullscreen: PropTypes.bool,
};

/** export the redux connected component and add a frontload call */
export default CheckoutCart;
