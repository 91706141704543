import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
/**
 * The app index
 */
import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore from "./redux/store";
import configureWorker from "./utils/serviceWorkerConfig";
import { isServer } from "./redux/store";
import "./index.sass";
import Loadable from "react-loadable";
import { createSsr } from "@issr/core";
import Root from "./routes/Root";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import I18n from "redux-i18n";
import { translations } from "./locales/translations";
import rootSaga from "../src/redux/rootSaga";

const SSR = createSsr();

/** create a store */
const { store, history } = configureStore();

/** run sagas */
store.runSaga(rootSaga);

const languages = ["de", "es", "it", "en"];

let initialLang = "de";
let reqLang = history.location.pathname.split("/");
if (reqLang.length > 1) {
  if (languages.includes(reqLang[1])) initialLang = reqLang[1];
}
console.log("initialLang client", initialLang);
/** The application */
const Application = () => (
  <Provider store={store}>
    <I18n translations={translations} initialLang={initialLang}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </I18n>
  </Provider>
);

/** the root element to load react */
const root = document.querySelector("#root");

/**
 * the index main
 */
if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(
      <SSR>
        <Application />
      </SSR>,
      root
    );
  });
} else {
  // If we're not running on the server, just render like normal
  render(
    <SSR>
      <Application />
    </SSR>,
    root
  );
}

/** build the service worker configuration object */
var config = configureWorker(store);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!isServer) {
  serviceWorkerRegistration.unregister();
  // serviceWorkerRegistration.register(config);
} else {
  serviceWorkerRegistration.unregister();
}
