/**
 *  Sagas for the xapi resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { actions as cmsBlocksActions } from "../cmsblocks";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

/** Worker Sagas */

/** Home Saga
 *  @description: connects to the `home` endpoint of xapi
 */
export function* loadHome() {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const SwaggerClient = yield call(apiClient);

    const payload = yield call(
      SwaggerClient.apis.Xapis.getHome,
      { lang: lang },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload?.obj?.cms_blocks) {
      yield put(cmsBlocksActions.updateList({ ...payload.obj.cms_blocks }));
    }

    yield put(actions.loadHomeSuccess(payload.obj));
  } catch (e) {
    yield put(actions.loadHomeFail(e));
  }
}

/** Home Saga
 *  @description: connects to the `home` endpoint of xapi
 */
export function* loadHomeWithSearch() {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Xapis.getHomeWithSearch,
      { lang: lang },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload?.obj?.cms_blocks) {
      yield put(cmsBlocksActions.updateList({ ...payload.obj.cms_blocks }));
    }

    yield put(actions.loadHomeWithSearchSuccess(payload.obj));
  } catch (e) {
    yield put({ type: actions.loadHomeWithSearchFail, payload: e });
  }
}

/** TextEditor Saga
 *  @description: connects to the `texteditor` endpoint of xapi
 */
export function* loadTextEditor() {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Xapis.getTextEditor,
      { lang: lang },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload?.obj?.cms_blocks) {
      yield put(cmsBlocksActions.updateList({ ...payload.obj.cms_blocks }));
    }

    yield put(actions.loadTextEditorSuccess(payload.obj));
  } catch (e) {
    yield put({ type: actions.loadTextEditorFail, payload: e });
  }
}

/** BlankCutEditor Saga
 *  @description: connects to the `blankcut` endpoint of xapi
 */
export function* loadBlankCutEditor() {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Xapis.getBlankCutEditor,
      { lang: lang },
      { requestInterceptor: applyHeaders(headers) }
    );

    if (payload?.obj?.cms_blocks) {
      yield put(cmsBlocksActions.updateList({ ...payload.obj.cms_blocks }));
    }

    yield put(actions.loadBlankCutEditorSuccess(payload.obj));
  } catch (e) {
    yield put({ type: actions.loadBlankCutEditorFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* xapiSaga() {
  yield takeEvery(actions.loadHome, loadHome);
  yield takeEvery(actions.loadHomeWithSearch, loadHomeWithSearch);
  yield takeEvery(actions.loadTextEditor, loadTextEditor);
  yield takeEvery(actions.loadBlankCutEditor, loadBlankCutEditor);
}
export default xapiSaga;
