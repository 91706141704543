/**
 *  Sagas for the landingpage resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { isServer } from "../store";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import { actions as cmsBlocksActions } from "../cmsblocks";
import { push } from "connected-react-router";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getLandingpages operation
 */
export function* list() {
  /** if we are offline we use persisted data */
  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedLandingpages = yield select(
      (state) => state.landingpages.stored
    );
    Object.keys(storedLandingpages).forEach((storedLandingpageIndex) => {
      storedList.push(
        Object.assign({}, storedLandingpages[storedLandingpageIndex])
      );
    });
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const SwaggerClient = yield call(apiClient);
      const payload = yield call(
        SwaggerClient.apis.Landingpages.getLandingpages,
        { lang: lang },
        { requestInterceptor: applyHeaders(headers) }
      );
      yield put({ type: actions.listSuccess, payload: payload.obj });
    } catch (e) {
      yield put({ type: actions.listFail, payload: e });
    }
  }
}

/** Show Saga
 *  @description: connects to the showLandingpage operation
 *  @param {number} action.payload the landingpage id
 */
export function* show(action) {
  const landingpageSlug = action.payload;
  const storedLandingpages = yield select((state) => state.landingpages.stored);
  /** if we are offline we use persisted data */
  if (
    !isServer &&
    navigator &&
    !navigator.onLine &&
    storedLandingpages[landingpageSlug]
  ) {
    yield put({
      type: actions.showSuccess,
      payload: storedLandingpages[landingpageSlug],
    });
  } else {
    // else we are online -> we fetch
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const SwaggerClient = yield call(apiClient);
      const payload = yield call(
        SwaggerClient.apis.Landingpages.showLandingpage,
        { slug: landingpageSlug, lang: lang },
        { requestInterceptor: applyHeaders(headers) }
      );

      // set requestedSlug since we might give back a fallback page and this ends in a loop
      payload.obj.requestedSlug = landingpageSlug;

      if (payload?.obj?.cms_blocks) {
        yield put(cmsBlocksActions.updateList({ ...payload.obj.cms_blocks }));
      }
      yield put(actions.showSuccess(payload.obj));
    } catch (e) {
      console.log("LP LOAD ERROR", e);
      if (e.message === "Failed to fetch") {
        yield put(push("/" + lang + "/404-lp/?source=" + landingpageSlug));
        yield put(
          actions.showFail({
            message: e.message,
            ...e,
            errorRedirect: "/" + lang + "/404-lp/?source=" + landingpageSlug,
          })
        );
      } else {
        yield put({ type: actions.showFail, payload: e });
      }
    }
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an landingpage is unstored
 */
export function* unstoreLandingpage() {
  const image = yield select((state) => state.landingpages.show.image);
  caches
    .open("images-cache")
    .then((imagesCache) => {
      // console.log(imagesCache)
      return imagesCache.delete(image);
    })
    .then((response) => {
      // console.log(response)
    })
    .catch((err) => {
      console.log("Could not clear cache", err);
    });
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* landingpagesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.unstoreLandingpage, unstoreLandingpage);
}
export default landingpagesSaga;
