/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import { actions as cartActions } from "../cart";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import DataLayerHelper from "../../../utils/dataLayer";

/** Worker Sagas */
// const CHECKOUT_INCLUDES = 'shipping_address,user,payments,shipments,billing_address,order.shipping_address,order.user,order.payments,order.shipments,shipment.shipping_rates,shipping_rates,order.billing_address,cart.shipping_address,cart.user,cart.payments,cart.shipments,cart.billing_address,line_items,variants,variants.images,images,shipping_address,user,payments,shipments,billing_address,variants.products,images,variants.products.images,product.images';
// const CHECKOUT_INCLUDES = 'shipping_address,user,payments,shipments,billing_address,order.shipping_address,order.user,order.payments,order.shipments,order.billing_address,cart.shipping_address,cart.user,cart.payments,cart.shipments,cart.billing_address,line_items,line_items.products,line_items.product,product,variants.product,variants.products,variants,variants.images,images,shipping_address,user,payments,shipments,billing_address,variants.products,images,variants.products.images,product.images';
// const CHECKOUT_INCLUDES = 'shipping_address,user,payments,shipments,billing_address,order.shipping_address,order.user,order.payments,order.shipments,order.billing_address,cart.shipping_address,cart.user,cart.payments,cart.shipments,cart.billing_address,line_items,line_items.products,line_items.product,product,variants.product,variants.products,variants,variants.images,images,shipping_address,user,payments,shipments,billing_address,variants.products,images,variants.products.images,product.images';
const CHECKOUT_INCLUDES =
  "shipping_address,user,shipments,billing_address,line_items,line_items.products,line_items.product,variants.product,variants.products,variants,variants.images,shipping_address,user,payments,payments.source,billing_address,variants.products,variants.products.images";

export function* showShippingMethods(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/checkout/shipping_rates.json`,
      {
        method: "GET",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    yield put(actions.showShippingMethodsSuccess(payload));
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.showShippingMethodsFail(e));
    DataLayerHelper.addErrorEvent(
      "showShippingMethods",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* setOrderReviewConsent(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  headers["X-Spree-Order-Token"] = action.payload.order_token;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/reviewconsent.json`,
      { method: "POST", headers: headers }
    );
    yield put(actions.setOrderReviewConsentSuccess(payload));
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.setOrderReviewConsentFail(e));
    DataLayerHelper.addErrorEvent(
      "setOrderReviewConsent",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* showPaymentMethods(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/checkout/payment_methods.json`,
      {
        method: "GET",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    yield put(actions.showPaymentMethodsSuccess(payload));
  } catch (e) {
    yield put(actions.showPaymentMethodsFail(e));
    DataLayerHelper.addErrorEvent(
      "showPaymentMethods",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* updateCheckout(action) {
  const { orderData, successCallback } = action.payload;
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  const cart = yield select((state) => state.shopCart.cart);
  headers["Accept-Language"] = lang;
  let paymentData = false;
  if (
    orderData.payments_attributes &&
    orderData.payments_attributes.length > 0
  ) {
    paymentData = orderData.payments_attributes[0].source_attributes || false;
  }

  const body = JSON.stringify({ order: orderData });
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/checkout.json?include=${CHECKOUT_INCLUDES}`,
      {
        body,
        method: "PATCH",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    if (paymentData) {
      yield put(cartActions.storeCard(paymentData));
    }
    yield put(actions.updateCheckoutSuccess());
    yield put(cartActions.showCartSuccess(payload));
    if (successCallback) successCallback(payload);
    if (payload.newToken) {
      yield put(actions.resetPaymentMethods({}));
      yield put(actions.resetShippingMethods({}));
    }
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.updateCheckoutFail(e));
    DataLayerHelper.addErrorEvent(
      "updateCheckout",
      e?.error || e?.message || "Shop API Failure"
    );
    if (e.errors) {
      let action = "updateCheckout - ";
      if (cart?.data?.attributes) action += cart.data.attributes.state;
      else action += "new-cart";
      DataLayerHelper.addFormErrorsEvent(action, e.errors);
    }

    if (e.newToken) {
      yield put(actions.resetPaymentMethods({}));
      yield put(actions.resetShippingMethods({}));
    }
  }
}

export function* nextStepCheckout(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/checkout/next.json?include=${CHECKOUT_INCLUDES}`,
      {
        method: "PATCH",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );

    if (action?.payload?.successCallback) {
      action.payload.successCallback(payload);
    }
    yield put(actions.nextStepCheckoutSuccess());
    yield put(cartActions.showCartSuccess(payload));
    if (payload.newToken) {
      yield put(actions.resetPaymentMethods({}));
      yield put(actions.resetShippingMethods({}));
    }
    // IF state is complete, also fire complete sage as failsafe
    if (payload.data.attributes.state === "complete") {
      yield put(actions.completeCheckoutSuccess(payload));
      DataLayerHelper.addPurchaseEvent(payload);
    }
    // log to dataLayer that user checked out step successfully
    DataLayerHelper.addCheckoutStepEvent(
      payload,
      payload.data.attributes.state
    );
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.nextStepCheckoutFail());
    yield put(actions.updateCheckoutFail(e));
    DataLayerHelper.addErrorEvent(
      "nextStepCheckout",
      e?.error || e?.message || "Shop API Failure"
    );
    if (e.errors) {
      const cart = yield select((state) => state.shopCart.cart);
      let action = "nextStepCheckout - ";
      if (cart && cart.data && cart.data.attributes)
        action += cart.data.attributes.state;
      else action += "new-cart";
      DataLayerHelper.addFormErrorsEvent(action, e.errors);
    }
  }
}

export function* completeCheckout(action) {
  let headers = buildHeaders();
  let sourceAttributes = action.payload ? action.payload.sourceAttributes : {};
  const lang = yield select((state) => state.i18nState.lang);
  const paymentData = yield select((state) => state.shopCart.paymentCard);
  const cart = yield select((state) => state.shopCart.cart);
  if (
    cart.data.relationships.payments.data &&
    cart.data.relationships.payments.data.length > 0
  ) {
    let payment = cart.included.find((obj) => {
      return (
        obj.type === "payment" &&
        cart.data.relationships.payments.data
          .map((objj) => parseInt(objj.id))
          .includes(parseInt(obj.id))
      );
    }).attributes;
    /* FIXME id 4 = credit cart = hardcoded */
    if (payment.payment_method_id === 4 && paymentData && paymentData.number) {
      sourceAttributes = paymentData;
    }
  }
  headers["Accept-Language"] = lang;

  const body = JSON.stringify({ source_attributes: sourceAttributes });

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/checkout/complete.json?include=${CHECKOUT_INCLUDES}`,
      {
        body,
        method: "PATCH",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    yield put(cartActions.showCartSuccess(payload));
    if (payload.newToken) {
      yield put(actions.resetPaymentMethods({}));
      yield put(actions.resetShippingMethods({}));
    }
    yield put(actions.completeCheckoutSuccess(payload));
    DataLayerHelper.addPurchaseEvent(payload);
  } catch (e) {
    console.error("CHECKOUT ERROR", e);
    yield put(actions.completeCheckoutFail(e));
    DataLayerHelper.addErrorEvent(
      "completeCheckout",
      e?.error || e?.message || "Shop API Failure"
    );
    if (e.errors) {
      const cart = yield select((state) => state.shopCart.cart);
      let action = "completeCheckout - ";
      if (cart && cart.data && cart.data.attributes)
        action += cart.data.attributes.state;
      else action += "new-cart";
      DataLayerHelper.addFormErrorsEvent(action, e.errors);
    }
  }
}

export function* updateAmazonPayment(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  const body = JSON.stringify({
    order_reference: action.payload.amazon_reference_id,
    access_token: action.payload.access_token,
  });

  try {
    let payload;
    // TODO remove uneeded includes
    payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/amazon_order/${
        action.payload.step || "payment"
      }.json?include=${CHECKOUT_INCLUDES}`,
      {
        body,
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    yield put(cartActions.showCartSuccess(payload));

    if (action && action.payload && action.payload.successCallback) {
      action.payload.successCallback(payload);
    }
    if (action.payload.step === "confirm") {
      yield put(actions.completeCheckoutSuccess(payload));
      DataLayerHelper.addPurchaseEvent(payload);
    }
  } catch (e) {
    if (action && action.payload && action.payload.step === "confirm") {
      console.error("CHECKOUT ERROR", e);
      DataLayerHelper.addErrorEvent(
        "updateAmazonPayment-checkout",
        e?.error || e?.message || "Shop API Failure"
      );

      yield put(actions.completeCheckoutFail(e));
    } else {
      console.error("ERROR", e);
      DataLayerHelper.addErrorEvent(
        "updateAmazonPayment",
        e?.error || e?.message || "Shop API Failure"
      );
    }
    if (action && action.payload && action.payload.errorCallback) {
      action.payload.errorCallback(e);
    }
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopCheckoutSaga() {
  yield takeEvery(actions.showShippingMethods, showShippingMethods);
  yield takeEvery(actions.showPaymentMethods, showPaymentMethods);
  yield takeEvery(actions.updateAmazonPayment, updateAmazonPayment);
  yield takeEvery(actions.setOrderReviewConsent, setOrderReviewConsent);
  yield takeEvery(actions.updateCheckout, updateCheckout);
  yield takeEvery(actions.completeCheckout, completeCheckout);
  yield takeEvery(actions.nextStepCheckout, nextStepCheckout);
}

export default shopCheckoutSaga;
