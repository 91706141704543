/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
// import {isServer} from "../../store";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import DataLayerHelper from "../../../utils/dataLayer";

/** Worker Sagas */

export function* list(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/countries.json?lang=${lang}`,
      { method: "GET", headers: headers, useSdn: false, tokenRequired: false }
    );
    yield put({ type: actions.listCountriesSuccess, payload: payload.data });
  } catch (e) {
    yield put({ type: actions.listCountriesFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "listCountries",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* show(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/countries/${action.payload}?include=states&lang=${lang}`,
      { method: "GET", headers: headers, useSdn: true }
    );
    yield put({ type: actions.showCountrySuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.showCountryFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "showCountry",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopCartSaga() {
  yield takeEvery(actions.listCountries, list);
  yield takeEvery(actions.showCountry, show);
}

export default shopCartSaga;
