/**
 * The Loading component, used while resources load
 * Keep this component as similar as possible to the resource page importing it
 */
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'reactstrap';

/**
 * Dummy content to mock the general structure of the pages while content loads
 */
const LoadingContentLayouts = (layout, invert) => {
  switch(layout) {
    case 'editor':
      return (<Row>
        <Col md={{ size: 4, order: invert? 'last' : 'first'}} xs={12} className="sk-loading sk-bg400"></Col>
        <Col md={{ size: 8, order: invert? 'first' : 'last'}}  xs={12}>
          <div className="sk-loading w100 sk-b30 mt-2 sk-bg300" />
          <div className="sk-loading h1 mt-4 sk-bg300" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
        </Col>
      </Row>);
    case 'product':
      return (<Row>
        <Col md={6} xs={12}>
          <div className="sk-loading w100 sk-b30 mt-2 sk-bg300" />
          <span style={{ display: 'inline-block', width: '48px', height: '48px' }} className="mr-1 sk-loading sk-line bg-light" />
          <span style={{ display: 'inline-block', width: '48px', height: '48px' }} className="mr-1 sk-loading sk-line bg-light" />
          <span style={{ display: 'inline-block', width: '48px', height: '48px' }} className="mr-1 sk-loading sk-line bg-light" />
          <span style={{ display: 'inline-block', width: '48px', height: '48px' }} className="mr-1 sk-loading sk-line bg-light" />
        </Col>
        <Col md={6} xs={12}>
          <div className="sk-loading h1 mt-4 sk-bg300" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading btn btn-block mt-4 sk-bg300" style={{ height: '40px' }} />
        </Col>
        <Col xs={12}>
          <div className="sk-loading h1 mt-4 sk-bg300" />
          <div className="sk-loading sk-b10 mb-1 bg-light" />
          <div className="sk-loading sk-b10 mb-1 bg-light" />
          <div className="sk-loading sk-b10 mb-1 bg-light" />
        </Col>
      </Row>);
    case 'category':
      return (<>
        <Row>
          <Col xs={12} className="sk-loading sk-bg400 sk-b10 mb-2"></Col>
        </Row>
        <Row>
          <Col lg={3}>
            <div className="sk-loading sk-bg300 sk-b50 mb-3" />
          </Col>
          <Col lg={9}>
            <Row>
              <Col lg={3} md={4} xs={6}><div className="sk-loading sk-b30 bg-light mb-3" /></Col>
              <Col lg={3} md={4} xs={6}><div className="sk-loading sk-b30 bg-light mb-3" /></Col>
              <Col lg={3} md={4} xs={6}><div className="sk-loading sk-b30 bg-light mb-3" /></Col>
              <Col lg={3} md={4} xs={6}><div className="sk-loading sk-b30 bg-light mb-3" /></Col>
              <Col lg={3} md={4} xs={6}><div className="sk-loading sk-b30 bg-light mb-3" /></Col>
              <Col lg={3} md={4} xs={6}><div className="sk-loading sk-b30 bg-light mb-3" /></Col>
            </Row>
          </Col>
        </Row>
      </>);
    default:
      return (<><Row>
        <Col xs={12} className="sk-loading sk-b50 sk-bg400"></Col>
        <Col xs={4} className="sk-loading sk-b10 mt-4 sk-bg400"></Col>
        <Col xs={8}>
          <div className="sk-loading h1 mt-4 sk-bg300" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
          <div className="sk-loading sk-line bg-light" />
        </Col>
      </Row></>);

  }
}

const LoadingContent = (props, context) => {
  return (
    <div>
      <section>
        <Container>
          { LoadingContentLayouts(props.layout, props.invert) }
        </Container>
      </section>
    </div>
  );
}
/**  define proptype for the 'translation' function  */
LoadingContent.contextTypes = {
  t: PropTypes.func
}
export default LoadingContent;
