/**
 * The Root container
 */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import Sticky from "react-sticky-el";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import OfflineBar from "../components/OfflineBar";
import ScrollToTop from "../components/ScrollToTop";
import Loadable from "react-loadable";
import LoadingContent from "../components/LoadingContent";
import CookieConsentModal from "../components/CookieConsentModal";
import { isServer } from "../redux/store";
// import TeaserBar from "../components/TeaserBar";

/**
 * Loadable import for Home container
 */
const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ "./CMS/Home"),
  loading: () => {
    return <LoadingContent />;
  },
  delay: 600,
  modules: ["Home"],
});
/**
 * Loadable import for HomeWithSearch container
 */
const HomeWithSearch = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeWithSearch" */ "./CMS/HomeWithSearch"),
  loading: () => {
    return <LoadingContent />;
  },
  delay: 600,
  modules: ["HomeWithSearch"],
});

const TextEditor = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TextEditor" */ "./TextEditor/TextEditor"),
  loading: () => {
    return <LoadingContent layout="editor" />;
  },
  delay: 600,
  modules: ["TextEditor"],
});

const FileUploadEditor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "FileUploadEditor" */ "./FileUploadEditor/FileUploadEditor"
    ),
  loading: () => {
    return <LoadingContent layout="editor" />;
  },
  delay: 600,
  modules: ["FileUploadEditor"],
});

const BlankCutEditor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BlankCutEditor" */ "./BlankCutEditor/BlankCutEditor"
    ),
  loading: () => {
    return <LoadingContent layout="editor" invert />;
  },
  delay: 600,
  modules: ["BlankCutEditor"],
});

const Landingpage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Landingpage" */ "./CMS/Landingpage"),
  loading: () => {
    return <LoadingContent />;
  },
  delay: 600,
  modules: ["Landingpage"],
});

const FourOFour = Loadable({
  loader: () => import(/* webpackChunkName: "FourOFour" */ "./Utils/FourOFour"),
  loading: LoadingContent,
  delay: 600,
  modules: ["FourOFour"],
});

const AccountRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccountRoot" */ "./Account/AccountRoot"),
  loading: LoadingContent,
  delay: 600,
  modules: ["AccountRoot"],
});

// Shop
// Loadable import for ShopCategory container
const ShopCategory = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ShopCategory" */ "./Shop/ShopCategoryLp"),
  loading: () => {
    return <LoadingContent layout="category" invert />;
  },
  delay: 600,
  modules: ["ShopCategory"],
});

// Shop
// Loadable import for ShopCategory container
const ShopCategoryLegacy = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ShopCategoryLegacy" */ "./Shop/ShopCategory"),
  loading: () => {
    return <LoadingContent layout="category" invert />;
  },
  delay: 600,
  modules: ["ShopCategoryLegacy"],
});
// Loadable import for ShopProduct container
const ShopProduct = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ShopProduct" */ "./Shop/ShopProduct"),
  loading: () => {
    return <LoadingContent layout="product" invert />;
  },
  delay: 600,
  modules: ["ShopProduct"],
});

// Loadable import for ShopProduct container
const ShopSearch = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ShopSearch" */ "./Shop/ShopSearch"),
  loading: () => {
    return <LoadingContent layout="category" invert />;
  },
  delay: 600,
  modules: ["ShopSearch"],
});

// Loadable import for ShopProduct container
const Shop = Loadable({
  loader: () => import(/* webpackChunkName: "Shop" */ "./Shop/ShopHome"),
  loading: () => {
    return <LoadingContent invert />;
  },
  delay: 600,
  modules: ["Shop"],
});

// Loadable import for ShopProduct container
const CheckoutRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CheckoutRoot" */ "./Checkout/CheckoutRoot"),
  loading: () => LoadingContent,
  delay: 600,
  modules: ["CheckoutRoot"],
});

// Loadable import for BlogPosts container
const BlogRoot = Loadable({
  loader: () => import(/* webpackChunkName: "BlogRoot" */ "./Blog/BlogRoot"),
  loading: () => LoadingContent,
  delay: 600,
  modules: ["BlogRoot"],
});

// Loadable import for BlogPosts container
const SupportCenterRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BlogPost" */ "./SupportCenter/SupportCenterRoot"
    ),
  loading: () => LoadingContent,
  delay: 600,
  modules: ["SupportCenterRoot"],
});

// Loadable import for SupportCenter container
const DesignReview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DesignReview" */ "./DesignReview/DesignReview"
    ),
  loading: () => LoadingContent,
  delay: 600,
  modules: ["DesignReview"],
});

// Loadable import for SupportCenter container
const DesignReviewDialog = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DesignReview" */ "./DesignReview/DesignReviewDialog"
    ),
  loading: () => LoadingContent,
  delay: 600,
  modules: ["DesignReviewDialog"],
});
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
const Root = (props) => {
  // FIXME would be nicer if we can pass this to our page component
  const validLanguage = ["/de/", "/en/", "/es/", "/it/"].some((element) => {
    if (
      props.location.pathname.toLowerCase().startsWith(element.toLowerCase())
    ) {
      return true;
    }

    return false;
  });

  const focusMode = [
    "/de/checkout/process",
    "/en/checkout/process",
    "/es/checkout/process",
    "/it/checkout/process",
  ].some((element) => {
    if (
      props.location.pathname.toLowerCase().startsWith(element.toLowerCase())
    ) {
      return true;
    }

    return false;
  });
  const [enforcePrivacyConsentModal, setEnforcePrivacyConsentModal] =
    useState(false);

  const checkPrivacyConsent = ![
    "/de/p/datenschutz/",
    "/en/p/datenschutz/",
    "/es/p/datenschutz/",
    "/it/p/datenschutz/",
    "/de/p/impressum/",
    "/en/p/impressum/",
    "/es/p/impressum/",
    "/it/p/impressum/",
  ].some((element) => {
    if (
      props.location.pathname.toLowerCase().startsWith(element.toLowerCase())
    ) {
      return true;
    }

    return false;
  });
  return (
    <>
      {!validLanguage && <Redirect to={`https://www.myfolie.com/de/`} />}
      <OfflineBar />
      {/* {props.lang === "de" && <TeaserBar />} */}
      <TopBar isCheckout={focusMode} />
      {/* {!focusMode && <Navbar />} */}

      {!focusMode && (
        <Sticky className="sticky--wrapper" topOffset={37}>
          <Navbar />
        </Sticky>
      )}
      <div id="content">
        <ScrollToTop isServer={isServer}>
          <Switch>
            <Redirect exact from="/" to={`/de/`} />
            <Route exact path={"/:lang/"} component={Home} />
            <Route exact path={"/:lang/overview/"} component={HomeWithSearch} />
            <Route exact path={"/:lang/search/"} component={ShopSearch} />
            <Route path={"/:lang/klebeschriften/"} component={TextEditor} />
            <Route
              path={"/:lang/eigenes-motiv/"}
              component={FileUploadEditor}
            />
            <Route
              path={"/:lang/folienzuschnitt/"}
              component={BlankCutEditor}
            />
            <Route path={"/:lang/checkout/"} component={CheckoutRoot} />
            <Route exact path={"/:lang/p/:slug"} component={Landingpage} />
            <Route
              exact
              path={"/:lang/kontakt/"}
              component={SupportCenterRoot}
            />
            <Route path={"/:lang/faq/"} component={SupportCenterRoot} />
            <Route path={"/:lang/faqs/"} component={SupportCenterRoot} />
            <Route path={"/:lang/fileguide/"} component={SupportCenterRoot} />

            <Route
              exact
              path={"/:lang/order_reviews/:slug?"}
              component={DesignReview}
            />
            <Route
              path={"/:lang/order_reviews/:slug/:feedbackId"}
              component={DesignReviewDialog}
            />

            <Route path={"/:lang/blog/"} component={BlogRoot} />
            <Route exact path={"/:lang/shop/"} component={Shop} />
            <Route
              exact
              path={"/:lang/c/:slug?"}
              component={ShopCategoryLegacy}
            />
            <Route exact path={"/:lang/t/:slug?"} component={ShopCategory} />
            <Route
              exact
              path={"/:lang/c/:slug?/:subslug?"}
              component={ShopCategoryLegacy}
            />
            <Route
              exact
              path={"/:lang/t/:slug?/:subslug?"}
              component={ShopCategory}
            />
            <Route
              exact
              path={"/:lang/t/:slug?/:subslug?/:subsubslug?"}
              component={ShopCategory}
            />
            <Route exact path={"/:lang/i/:slug?"} component={ShopProduct} />

            <Route path={"/:lang/account/"} component={AccountRoot} />

            <Route component={FourOFour} />
          </Switch>
        </ScrollToTop>
      </div>
      <Footer
        lang={props.lang}
        isMinimalFooter={focusMode}
        setEnforcePrivacyConsentModal={setEnforcePrivacyConsentModal}
      />
      {(checkPrivacyConsent || enforcePrivacyConsentModal) && (
        <CookieConsentModal
          lang={props.lang}
          setEnforcePrivacyConsentModal={setEnforcePrivacyConsentModal}
          enforcePrivacyConsentModal={enforcePrivacyConsentModal}
        />
      )}
    </>
  );
};

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
