import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  listLoading: false,
  listError: false,
  cart: {},
  cartUpdating: false,
  cartFinalError: false,
  addToShopCartSuccess: false,
  addToCartLoading: false,
  addToCartProcessing: false,
  cartError: false,
  updatingItemId: false,
  deletingItemId: false,
  cartCouponError: false,
  paymentCard: {},
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCartState: (state, action) => {
      let newCart = { ...state.cart };
      if (action.payload && action.payload.deleteCart) newCart = {};

      state.loading = false;
      state.loaded = false;
      state.cartUpdating = false;
      state.addToShopCartSuccess = false;
      state.addToCartProcessing = false;
      state.addToCartLoading = false;
      state.error = false;
      state.cartFinalError = false;
      state.cart = newCart;
    },
    addItemToCart: (state) => {
      state.cartUpdating = true;
      state.addToShopCartSuccess = false;
      state.addToCartProcessing = true;
      state.addToCartLoading = true;
    },
    addItemToCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.addToCartLoading = false;
      state.addToCartProcessing = false;
      state.addToShopCartSuccess = true;
      state.cart = action.payload;
    },
    addItemToCartFail: (state, action) => {
      state.cartUpdating = false;
      state.addToCartLoading = false;
      state.addToCartProcessing = false;
      state.addToShopCartSuccess = false;
      state.error = action.payload ? action.payload.message || true : true;
    },
    showCart: (state) => {
      state.cartUpdating = true;
      state.cartFinalError = null;
    },
    showCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
    },
    showCartFail: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.cartFinalError = action.payload
        ? action.payload.message || true
        : true;
    },
    createCart: (state) => {
      state.cartUpdating = true;
      state.cartFinalError = null;
      state.cart = {};
    },
    removeLineItemFromCart: (state, action) => {
      state.cartUpdating = true;
      state.cartFinalError = null;
      state.deletingItemId = action.payload.lineItemId;
    },
    removeLineItemFromCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.deletingItemId = false;
    },
    removeLineItemFromCartFail: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.cartFinalError = action.payload
        ? action.payload.message || true
        : true;
      state.deletingItemId = false;
    },
    updateLineItemInCart: (state, action) => {
      state.cartUpdating = true;
      state.cartFinalError = null;
      state.updatingItemId = action.payload.line_item_id;
    },
    updateLineItemInCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.updatingItemId = false;
    },
    updateLineItemInCartFail: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.cartFinalError = action.payload
        ? action.payload.message || true
        : true;
      state.updatingItemId = false;
    },
    applyCouponCode: (state) => {
      state.cartUpdating = true;
    },
    applyCouponCodeFail: (state, action) => {
      state.cartUpdating = false;
      state.cartCouponError = action.payload.error || "invalid coupon";
    },
    storeCard: (state, action) => {
      state.paymentCard = action.payload;
    },
  },
});

export const {
  resetCartState,
  addItemToCart,
  addItemToCartSuccess,
  addItemToCartFail,
  showCart,
  showCartSuccess,
  showCartFail,
  createCart,
  removeLineItemFromCart,
  removeLineItemFromCartSuccess,
  removeLineItemFromCartFail,
  updateLineItemInCart,
  updateLineItemInCartSuccess,
  updateLineItemInCartFail,
  applyCouponCode,
  applyCouponCodeFail,
  storeCard,
} = cartSlice.actions;

export const actions = cartSlice.actions;

export default cartSlice.reducer;
