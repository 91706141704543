import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sku: false,
  loading: false,
  error: false,
  loaded: false,
  redirect: false,
  cart: {},
  cartUpdating: false,
  cartFinalError: false,
  addToShopCartSuccess: false,
  addToCartLoading: false,
  addToCartProcessing: false,
  cartError: false,
  updatingItemId: false,
  deletingItemId: false
};

const shopSlice = createSlice({
  name: 'editorShop',
  initialState,
  reducers: {
    addToCart: (state) => {
      state.loading = true;
      state.addToCartProcessing = true;
      state.loaded = false;
      state.error = null;
    },
    addToCartSuccess: (state, action) => {
      state.loading = false;
      state.loaded = true;
      state.addToCartProcessing = false;
      state.redirect = action.payload.url;
    },
    addToCartFail: (state, action) => {
      state.loading = false;
      state.loaded = false;
      state.addToCartProcessing = false;
      state.error = action.payload.message || true;
    },
    addToShopCart: (state) => {
      state.cartUpdating = true;
      state.addToShopCartSuccess = false;
      state.addToCartProcessing = true;
      state.addToCartLoading = true;
    },
    addToShopCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.addToCartLoading = false;
      state.addToCartProcessing = false;
      state.addToShopCartSuccess = true;
      state.cart = action.payload;
    },
    addToShopCartFail: (state, action) => {
      state.cartUpdating = false;
      state.addToCartLoading = false;
      state.addToCartProcessing = false;
      state.addToShopCartSuccess = false;
      state.error = action.payload.message || true;
    },
    getCart: (state) => {
      state.cartUpdating = true;
      state.cartError = false;
    },
    getCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
    },
    getCartFail: (state, action) => {
      state.cartUpdating = false;
      state.cartError = action.payload.message || true;
    },
    deleteFromCart: (state, action) => {
      state.cartUpdating = true;
      state.cartError = false;
      state.deletingItemId = action.payload;
    },
    deleteFromCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.deletingItemId = false;
    },
    deleteFromCartFail: (state, action) => {
      state.cartUpdating = false;
      state.cartError = action.payload.message || true;
      state.deletingItemId = false;
    },
    updateItemInCart: (state, action) => {
      state.cartUpdating = true;
      state.cartError = false;
      state.updatingItemId = action.payload.id;
    },
    updateItemInCartSuccess: (state, action) => {
      state.cartUpdating = false;
      state.cart = action.payload;
      state.updatingItemId = false;
    },
    updateItemInCartFail: (state, action) => {
      state.cartUpdating = false;
      state.cartError = action.payload.message || true;
      state.updatingItemId = false;
    },
    reset: (state) => {
      state.loading = false;
      state.loaded = false;
      state.error = false;
      state.addToCartProcessing = false;
      state.addToShopCartSuccess = false;
      state.addToCartLoading = false;
    },
    updateSku: (state, action) => {
      state.sku = action.payload.sku;
    }
  }
});

export const {
  addToCart,
  addToCartSuccess,
  addToCartFail,
  addToShopCart,
  addToShopCartSuccess,
  addToShopCartFail,
  getCart,
  getCartSuccess,
  getCartFail,
  deleteFromCart,
  deleteFromCartSuccess,
  deleteFromCartFail,
  updateItemInCart,
  updateItemInCartSuccess,
  updateItemInCartFail,
  reset,
  updateSku
} = shopSlice.actions;

export const actions = shopSlice.actions;

export default shopSlice.reducer;
