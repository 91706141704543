/** ----- apiRequest.js -----
 *  File: apiRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling
 * ----------------------------- */
import Swagger from "swagger-client";
import Cookies from "universal-cookie";
import { isServer } from "../../store";
import DataLayerHelper from "../../../utils/dataLayer";
import { REACT_APP_SHOP_API_SERVER } from "../../../utils/env";
const cookieHandler = new Cookies();

function CookiesDisabledError(message) {
  this.name = "CookiesDisabledError";
  this.code = 412;
  this.message = message || "";
}
CookiesDisabledError.prototype = Error.prototype;

function CookiesCouldNotBeSetError(message) {
  this.name = "CookiesCouldNotBeSetError";
  this.code = 412;
  this.message = message || "";
}
CookiesCouldNotBeSetError.prototype = Error.prototype;
/**
 * Wrapper for private API requests that provider additional error handling.
 */
const apiShopRequest = (_url, args) => {
  if (isServer && args.tokenRequired) throw new Error("Token required in ssr");
  if (!isServer && navigator && !navigator.cookieEnabled && args.tokenRequired)
    throw new CookiesDisabledError(
      "Cookies must be enabled for the checkout process to work"
    );
    console.log("API REQUEST SHOP", REACT_APP_SHOP_API_SERVER + _url);
  const request = {
    url: REACT_APP_SHOP_API_SERVER + _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  };
  if (args.useSdn) {
    request.url = request.url.replace("//shop.", "//sdn.");
    const skipCdnCookie = cookieHandler.get("skipcdn");
    if (skipCdnCookie) {
      request.url = request.url.replace("//sdn.", "//shop.");
    }
  }

  request.headers["Content-Type"] = "application/vnd.api+json";
  if (args.tokenRequired) {
    let orderToken = cookieHandler.get("osid");
    let tokenSource = "osid";
    if (!orderToken || orderToken.length === 0) {
      orderToken = cookieHandler.get("osidsrc");
      tokenSource = "osidsrc";
    }
    if (!orderToken || orderToken.length === 0) {
      orderToken = cookieHandler.get("osidn"); // safari edge case fallback
      tokenSource = "osidn";
    }
    if (!orderToken) {
      // console.log('TOKEN REQUIRED: User has no valid token');
      if (args.precheckToken) {
        throw new Error("TOKEN REQUIRED: User did not yet create a cart");
      }

      // Return if token is required but does not exist -> requesting token
      return Swagger.http({
        url: `${REACT_APP_SHOP_API_SERVER}/api/v2/storefront/cart.json`,
        method: "POST",
        credentials: "include",
      })
        .then((cres) => {
          cookieHandler.set("osid", cres.body.data.attributes.token, {
            httpOnly: false,
            secure: false,
            sameSite: "strict",
            path: "/",
            maxAge: 3600 * 30 * 100,
          });

          request.headers["X-Spree-Order-Token"] =
            cres.body.data.attributes.token;
          if (!cookieHandler.cookies || !cookieHandler.cookies.osid) {
            throw new CookiesCouldNotBeSetError(
              "Cookie for tracking the order could not be set"
            );
          }
          // Return if token is required but does not exist -> performing request
          return Swagger.http(request)
            .then((res) => {
              res.body.newToken = true;
              return res.body;
            })
            .catch((err) => {
              console.log("ERROR IN API SHOP CREATE CART REQUEST");
              console.log(err);
              if (err.response && err.response.obj) {
                err.response.obj.newToken = true;
                throw err.response.obj;
              } else {
                throw err;
              }
            });
        })
        .catch((err) => {
          console.log("ERROR IN API SHOP REQUEST");
          console.log(err);
          if (err.response && err.response.obj) throw err.response.obj;
          else throw err;
        });
    } else {
      // console.log('TOKEN REQUIRED: Using existing token', orderToken)
      // Return if token is required and token exists
      request.headers["X-Spree-Order-Token"] = orderToken;
      // Extend cookie lifetime
      cookieHandler.set("osid", orderToken, {
        httpOnly: false,
        secure: false,
        sameSite: "strict",
        path: "/",
        maxAge: 3600 * 30 * 100,
      });
      return Swagger.http(request)
        .then((res) => {
          return res.body;
        })
        .catch((err, res) => {
          console.log("ERROR IN API SHOP REQUEST WITH TOKEN");

          if (
            err.response &&
            err.response.status === 404 &&
            !args.skipRenewToken
          ) {
            // NOT FOUND FOR TOKEN; RESET TOKEN AND RETRY
            cookieHandler.remove("osid", {
              httpOnly: false,
              secure: false,
              sameSite: "strict",
              path: "/",
            });
            cookieHandler.remove("osidsrc", {
              httpOnly: false,
              secure: false,
              sameSite: "strict",
              path: "/",
            });
            cookieHandler.remove("osidn", {
              httpOnly: false,
              secure: false,
              sameSite: "none",
              path: "/",
            });
            // Return if token is required but does not exist -> requesting token
            return Swagger.http({
              url: `${REACT_APP_SHOP_API_SERVER}/api/v2/storefront/cart.json`,
              method: "POST",
              credentials: "include",
            })
              .then((cres) => {
                // console.log('obtaining new token')
                DataLayerHelper.addErrorEvent(
                  "cartNotFound",
                  orderToken +
                    " (" +
                    tokenSource +
                    ") | " +
                    cres.body.data.attributes.token
                );

                cookieHandler.set("osid", cres.body.data.attributes.token, {
                  httpOnly: false,
                  secure: false,
                  sameSite: "strict",
                  path: "/",
                  maxAge: 3600 * 30 * 100,
                });

                request.headers["X-Spree-Order-Token"] =
                  cres.body.data.attributes.token;
                if (!cookieHandler.cookies || !cookieHandler.cookies.osid) {
                  throw new CookiesCouldNotBeSetError(
                    "Cookie for tracking the order could not be set"
                  );
                }
                // Return if token is required but does not exist -> performing request
                return Swagger.http(request)
                  .then((res) => {
                    res.body.newToken = true;
                    return res.body;
                  })
                  .catch((err) => {
                    console.log("ERROR IN API SHOP CREATE CART REQUEST");
                    console.log(err);
                    if (err.response && err.response.obj) {
                      err.response.obj.newToken = true;
                      throw err.response.obj;
                    } else {
                      throw err;
                    }
                  });
              })
              .catch((err) => {
                console.log("ERROR IN RETRY API SHOP REQUEST");
                console.log(err);
                if (err.response) {
                  throw err.response.obj;
                } else {
                  throw err;
                }
              });
          } else {
            if (err.response && err.response.obj) {
              err.response.obj.responseStatus = err.response.status;
              throw err.response.obj;
            } else {
              throw err;
            }
          }
        });
    }
  }

  // Return if no token is required
  return Swagger.http(request)
    .then((res) => {
      return res.body;
    })
    .catch((err) => {
      console.log("ERROR IN API SHOP REQUEST");
      console.log(err);

      if (err.response && err.response.status === 404) {
        if (!err.response.obj) err.response.obj = {};
        err.response.obj.responseStatus = err.response.status;
      } else if (
        err?.name === "TypeError" &&
        err?.message === "Failed to fetch"
      ) {
        if (!err.response) err.response = {};
        if (!err.response.obj) err.response.obj = {};
        err.response.obj.responseStatus = 417;
      }

      if (err && err.response) {
        throw err.response.obj;
      } else {
        throw err;
      }
    });
};

export default apiShopRequest;
