/**
 * Sagas for the blog posts managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import DataLayerHelper from "../../utils/dataLayer";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the listBlogPosts operation
 */
export function* list(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.BlogPosts.getBlogPosts,
      {lang: lang},
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.listSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "listBlogPosts",
      e?.error || e?.message || "BlogPost Error"
    );
  }
}

/** Show Saga
 *  @description: connects to the showBlogPost operation
 */
export function* show(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.BlogPosts.showBlogPost,
      { slug: action.payload, lang: lang },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.showSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "showBlogPost",
      e?.error || e?.message || "BlogPost Error"
    );
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* blogPostsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}

export default blogPostsSaga;
