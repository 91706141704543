/**
 *  Reducer function for the faqcategories resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/faqcategories
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';


/** The faqcategories reducer initial state */
export const defaultState = {
  stored: {},
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The faqcategories list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The faqcategories list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of faqcategories
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload
      };
    },
    /** The faqcategories list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The faqcategory show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    /** The faqcategory show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested faqcategory
     */
    [actions.showSuccess]: (state, action) => {
      if (state.stored[action.payload.slug]) {
        action.payload.stored = true
      }    
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The faqcategory show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    /** The faqcategory create action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.create]: (state) => {
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };
    },
    /** The faqcategory create action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the created faqcategory
     */
    [actions.createSuccess]: (state, action) => {
      var newList = state.list.push(action.payload)
      return {
        ...state,
        list: newList,
        creating: false,
        created: true,
        show: action.payload
      };
    },
    /** The faqcategory create action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.createFail]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: false,
        createError: action.payload.mess
      };
    },
    /** The faqcategory update action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.update]: (state) => {
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };
    },
    /** The faqcategory update action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the updated of faqcategories
     */
    [actions.updateSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        show: action.payload
      };
    },
    /** The faqcategory update action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.updateFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.payload.mess
      };
    },
    /** The faqcategory remove action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.remove]: (state) => {
      return {
        ...state,
        removing: true,
        removed: false,
        removeError: null,
      };
    },
    /** The faqcategory remove action success
     * @param {Object} state the current state of this reducer
     */
    [actions.removeSuccess]: (state) => {
      return {
        ...state,
        removing: false,
        removed: true,
      };
    },
    /** The faqcategory remove action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.removeFail]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action.payload.mess
      };
    },
    /** The store faqcategory action. Saves the currently shown faqcategory in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeFaqCategory]: (state) => {
      let newStoredFaqCategories = Object.assign({}, state.stored)
      newStoredFaqCategories[state.show.slug] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredFaqCategories,
        show: newShow
      };
    },
    /** The unstore faqcategory action. Removes the currently shown faqcategory from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the slug of the faqcategory to unstore
     */
    [actions.unstoreFaqCategory]: (state, action) => {
      let newStoredFaqCategories = Object.assign({}, state.stored)
      delete newStoredFaqCategories[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredFaqCategories,
        show: newShow
      };
    }
  },
  defaultState
);
