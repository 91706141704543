import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  dictionary: {},
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null,
};

const cmsBlocksSlice = createSlice({
  name: "cmsblocks",
  initialState,
  reducers: {
    listCmsBlock: (state) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
    },
    listSuccess: (state, action) => {
      let newDictionary = state.dictionary;
      action.payload.forEach(
        (entry) => (newDictionary[entry.language + "-" + entry.slug] = entry)
      );

      state.dictionary = newDictionary;
      state.loadingList = false;
      state.loadedList = true;
      state.list = action.payload;
    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    updateList: (state, action) => {
      let updatedBlocks = [];
      let newList = state.list.map((block) => {
        if (
          action.payload[block.slug] &&
          action.payload[block.slug].language === block.language
        ) {
          updatedBlocks.push(block.slug);
          return action.payload[block.slug];
        }
        return block;
      });

      updatedBlocks.forEach(
        (updatedBlockSlug) => delete action.payload[updatedBlockSlug]
      );

      Object.keys(action.payload).forEach((slug) =>
        newList.push(action.payload[slug])
      );

      let newDictionary = state.dictionary;
      Object.keys(action.payload).forEach((slug) => {
        newDictionary[
          action.payload[slug].language + "-" + action.payload[slug].slug
        ] = action.payload[slug];
      });

      state.dictionary = newDictionary;
      state.list = newList;
    },
    show: (state) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
    },
    showSuccess: (state, action) => {
      state.dictionary[action.payload.language + "-" + action.payload.slug] =
        action.payload;
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
    },
    showFail: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = false;
      state.showError = action.payload.mess;
    },
    storeCmsBlock: (state) => {
      let newStoredCmsBlocks = Object.assign({}, state.stored);
      newStoredCmsBlocks[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      state.stored = newStoredCmsBlocks;
      state.show = newShow;
    },
    unstoreCmsBlock: (state, action) => {
      let newStoredCmsBlocks = Object.assign({}, state.stored);
      delete newStoredCmsBlocks[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      state.stored = newStoredCmsBlocks;
      state.show = newShow;
    },
  },
});

// Add async logic here using Redux thunk if necessary

export const {
  listCmsBlock,
  listSuccess,
  listFail,
  updateList,
  show,
  showSuccess,
  showFail,
  storeCmsBlock,
  unstoreCmsBlock,
} = cmsBlocksSlice.actions;

export const actions = cmsBlocksSlice.actions;
export default cmsBlocksSlice.reducer;
