import React, {Component} from 'react';
// import {ChevronLeft} from 'react-icons/fa';

export default class DefaultBackComponent extends Component {
  render(){
    const {backIcon} = this.props;
    return (
        <span className="link">
          <span className={'rpm-back-item'} >
            {backIcon}
          </span>
        </span>
    );
  }
}