/**
 * The navbar container
 */
import React, { useReducer, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  Badge,
  Col,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// import PropTypes from "prop-types";
import FlashAlert from "../FlashAlert";
import logo from "../../assets/images/myfolie-logo.svg";
import { Link } from "react-router-dom";
import PushMenu from "./utils/push-menu";
import CheckoutCart from "../CheckoutCart";
import {
  FaChevronRight,
  FaChevronLeft,
  FaShoppingCart,
  FaUser,
  FaCar,
  FaShip,
  FaMagnet,
  FaMotorcycle,
  FaCaravan,
  FaFile,
  FaFlagCheckered,
  FaSearch,
} from "react-icons/fa";
import { isServer } from "../../redux/store";
import LazyLoad from "react-lazyload";
import { forceCheck } from "react-lazyload";
import ProductSearch from "../ProductSearch/ProductSearch";
import { push } from "connected-react-router";

const defaultPropMaps = {
  displayName: "name",
  linkClasses: "classes",
  childPropName: "children",
  expanderClasses: "expClasses",
  url: "link",
  id: "id",
  teaser: "teaser",
  img: "img",
  text: "text",
  cta: "cta",
  external: "external",
  excludedLang: "excludedLang",
  teaserChildren: "teaserChildren",
};

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  const {
    alertMessage,
    alertColor,
    alertTimeout,
    alertLink,
    alertOrigin,
    lang,
    cart,
  } = useSelector((state) => ({
    alertMessage: state.notifications.message,
    alertColor: state.notifications.color,
    alertTimeout: state.notifications.timeout,
    alertLink: state.notifications.link,
    alertOrigin: state.notifications.origin,
    lang: state.i18nState.lang,
    cart: state.shopCart.cart,
  }));

  // useState hooks to replace state
  const [isOpen, setIsOpen] = useState(false);
  const [openNav, setOpenNav] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [navigationTimeOut, setNavigationTimeOut] = useState(null);

  const dispatch = useDispatch();

  const myfolieBaseURL = `/${lang}`;

  const menu = {
    children: [
      {
        name: context.t("Vehicle Decals"),
        id: 9,
        link: `${myfolieBaseURL}/t/vehicle-decals/`,
        teaser: [
          {
            name: context.t("Car Lettering"),
            link: `${myfolieBaseURL}/klebeschriften/`,
            children: [],
            text: context.t(
              "Transform your text into standout monochrome adhesive vinyl, delivered on a carrier foil for easy application. Perfect for making your vehicle stand out with individual, free-standing letters."
            ),
            cta: context.t("Design online"),
            img: "https://cdn.myfolie.de/media/lettering_teaser.jpg",
          },
          {
            name: context.t("Your Design as Car Sticker"),
            link: `${myfolieBaseURL}/i/sticker-vehicle-own-design/`,
            img: "https://cdn.myfolie.de/media/dein_motiv.jpg",
            text: context.t(
              "Upload your design, and we'll print it on an adhesive vinyl for your vehicle. Select the cutting and finishing style to best suit your car. Rest assured, it's car wash safe!"
            ),
            cta: context.t("discover"),
          },
        ],
        children: [
          // {
          //   name: context.t("Advertising & Individual Designs"),
          //   id: 911,
          //   link: `${myfolieBaseURL}/c/autoaufkleber/individuelle-designs/`,
          //   children: [],
          // },
          {
            name: context.t("Car Stickers"),
            id: 913,
            icon: <FaCar />,
            link: `${myfolieBaseURL}/t/vehicle-decals/car-stickers/`,
            children: [
              {
                name: context.t("Car Lettering"),
                id: 9131,
                label: "Topseller",
                link: `${myfolieBaseURL}/klebeschriften/`,
                children: [],
              },
              // {
              //   name: "Autoaufkleber mit Logo",
              //   id: 9132,
              //   link: `${myfolieBaseURL}/i/sticker-with-own-logo-car/`,
              //   children: [],
              // },
              {
                name: context.t("Sticker with logo for your car"),
                id: 9133,
                link: `${myfolieBaseURL}/i/sticker-with-own-logo-car/`,
                children: [],
              },
              {
                name: context.t("rear window sticker"),
                id: 9134,
                link: `${myfolieBaseURL}/p/heckscheibenaufkleber/`,
                children: [],
              },
              {
                name: context.t("view all products"),
                id: 9139,
                category_overview: true,
                link: `${myfolieBaseURL}/t/vehicle-decals/car-stickers/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Boat labelling"),
            id: 915,
            icon: <FaShip />,
            link: `${myfolieBaseURL}/t/vehicle-decals/boat-labeling/`,
            children: [
              {
                name: context.t("Boat license plate"),
                id: 9151,
                label: context.t("Topseller"),
                link: `${myfolieBaseURL}/i/sticker-boat-license-plate/`,
                children: [],
              },
              // {
              //   name: context.t("Boat license plate"),
              //   id: 9152,
              //   link: `${myfolieBaseURL}/eigenes-motiv/`,
              //   children: [],
              // },
              {
                name: context.t("view all products"),
                id: 9159,
                category_overview: true,
                link: `${myfolieBaseURL}/t/vehicle-decals/boat-labeling/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("magnetic signs"),
            id: 916,
            icon: <FaMagnet />,
            link: `${myfolieBaseURL}/p/automagnetschilder/`,
            children: [
              // {
              //   name: "Automagnet mit eigenem Motiv",
              //   id: 9161,
              //   link: `${myfolieBaseURL}/i/magnetic-car-stickers-for-companies/`,
              //   children: [],
              // },
              // {
              //   name: "Baustellenfahrzeuge",
              //   id: 9162,
              //   link: `${myfolieBaseURL}/i/magnetic-car-stickers-for-companies/`,
              //   children: [],
              // },
            ],
          },
          {
            name: context.t("Caravan & Motorhome"),
            id: 917,
            icon: <FaCaravan />,
            link: `${myfolieBaseURL}/t/vehicle-decals/carvan-and-motorhome/`,
            children: [
              // {
              //   name: "Wunschmotiv",
              //   id: 9171,
              //   link: `${myfolieBaseURL}/i/sticker-for-caravan-and-camper/`,
              //   children: [],
              // },
            ],
          },
          {
            name: context.t("Motorbike Stickers"),
            id: 918,
            icon: <FaMotorcycle />,
            link: `${myfolieBaseURL}/t/vehicle-decals/motorbike-stickers/`,
            children: [],
          },
          {
            name: context.t("Car Film"),
            id: 920,
            icon: <FaFile />,
            link: `${myfolieBaseURL}/t/vehicle-decals/car-film/`,
            label: context.t("new"),
            children: [
              {
                name: context.t("Car Wrapping Film"),
                id: 9201,
                link: `${myfolieBaseURL}/t/vehicle-decals/car-film/car-wrapping/`,
                children: [],
              },
              // {
              //   name: context.t("Paint Protection Film"),
              //   id: 9202,
              //   link: `${myfolieBaseURL}/t/vehicle-decals/car-film/paint-protection-film/`,
              //   children: [],
              // },
              {
                name: context.t("Tinting Film"),
                id: 9203,
                link: `${myfolieBaseURL}/t/vehicle-decals/car-film/tinting-film/`,
                children: [],
              },
              {
                name: context.t("Carbon Foil"),
                id: 9204,
                link: `${myfolieBaseURL}/t/vehicle-decals/car-film/carbon-foil/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Vehicle Markings"),
            id: 919,
            label: context.t("new"),
            icon: <FaFlagCheckered />,
            link: `${myfolieBaseURL}/t/vehicle-decals/vehicle-markings/`,
            children: [
              // {
              //   name: "Eigenes Motiv",
              //   id: 9191,
              //   link: `${myfolieBaseURL}/c/autoaufkleber/`,
              //   children: [],
              // },
              {
                name: context.t("Contour Marker"),
                id: 9192,
                link: `${myfolieBaseURL}/t/vehicle-decals/vehicle-markings/contour-marker/`,
                children: [],
              },
              {
                name: context.t("Angle Morts Sticker"),
                id: 9193,
                link: `${myfolieBaseURL}/t/vehicle-decals/vehicle-markings/angle-morts-sticker/`,
                children: [],
              },
              // {
              //   name: context.t("Warning Markers"),
              //   id: 9194,
              //   link: `${myfolieBaseURL}/t/vehicle-decals/vehicle-markings/warning-markers/`,
              //   children: [],
              // },
              // {
              //   name: context.t("Speed Limit Sticker"),
              //   id: 9195,
              //   link: `${myfolieBaseURL}/t/vehicle-decals/vehicle-markings/speed-limit-sticker/`,
              //   children: [],
              // },
              {
                name: context.t("Flags & Countrys"),
                id: 9196,
                link: `${myfolieBaseURL}/t/vehicle-decals/vehicle-markings/flags-and-countries/`,
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: context.t("Shop Window Stickers"),
        id: 8,
        link: `${myfolieBaseURL}/t/shop-window-stickers/`,
        teaser: [
          {
            name: context.t("Window Lettering"),
            link: `${myfolieBaseURL}/klebeschriften/`,
            children: [],
            text: context.t(
              "Revamp your storefront with our monochrome adhesive foil. Sent on a carrier for hassle-free application, these free-standing letters are ideal for shop window displays, catching the eyes of passersby."
            ),
            cta: context.t("Design online"),
            img: "https://cdn.myfolie.de/media/lettering_teaser.jpg",
          },
          {
            name: context.t("Holiday Window: Festive Decals"),
            link: `${myfolieBaseURL}/t/shop-window-stickers/promotional-stickers/event-and-seasonal-promotions/`,
            img: "https://cdn.myfolie.de/media/christmas_teaser.jpg",
            text: context.t(
              "Elevate your business's festive appeal with our seasonal adhesive stickers! Tailored for shop windows, these decals brilliantly capture the spirit of Christmas and New Year. Set the holiday mood and engage clients with captivating window displays."
            ),
            cta: context.t("discover"),
          },
        ],
        children: [
          {
            name: context.t("Custom Stickers & Letterings"),
            id: 80,
            link: `${myfolieBaseURL}/t/shop-window-stickers/customized-stickers-and-letterings/`,
            children: [
              {
                name: context.t("Adhesive Lettering"),
                label: "Topseller",
                id: 801,
                link: `${myfolieBaseURL}/klebeschriften/`,
                children: [],
              },
              {
                name: context.t("Window film with your design"),
                label: context.t("upload file"),
                label_color: "success",
                id: 802,
                link: `${myfolieBaseURL}/i/window-film-your-design/`,
                children: [],
              },
              {
                name: context.t("Frosted glass film with your logo"),
                label: context.t("upload file"),
                label_color: "success",
                id: 803,
                link: `${myfolieBaseURL}/i/frosted-glass-film-with-your-logo/`,
                children: [],
              },
              {
                name: context.t("view all products"),
                id: 809,
                category_overview: true,
                link: `${myfolieBaseURL}/t/shop-window-stickers/customized-stickers-and-letterings/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Promotional Stickers"),
            id: 81,
            link: `${myfolieBaseURL}/t/shop-window-stickers/promotional-stickers/`,
            children: [
              {
                name: context.t("Sales & Discount Stickers"),
                id: 811,
                link: `${myfolieBaseURL}/t/shop-window-stickers/promotional-stickers/sale-and-discount-stickers/`,
                children: [],
              },
              {
                name: context.t("New Arrival Announcements"),
                id: 812,
                link: `${myfolieBaseURL}/t/shop-window-stickers/promotional-stickers/new-arrival-announcements/`,
                children: [],
              },
              {
                name: context.t("Event & Seasonal Promotions"),
                id: 813,
                link: `${myfolieBaseURL}/t/shop-window-stickers/promotional-stickers/event-and-seasonal-promotions/`,
                children: [],
              },
              {
                name: context.t("Limited Time Offer Stickers"),
                id: 814,
                link: `${myfolieBaseURL}/t/shop-window-stickers/promotional-stickers/limited-time-offers/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Branding Films"),
            id: 82,
            link: `${myfolieBaseURL}/t/shop-window-stickers/branding/`,
            children: [
              {
                name: context.t("Logo & Custom Stickers"),
                id: 821,
                link: `${myfolieBaseURL}/t/shop-window-stickers/branding/logo-and-custom-brand-stickers/`,
                children: [],
              },
              {
                name: context.t("Business Hours & Information"),
                id: 822,
                link: `${myfolieBaseURL}/t/shop-window-stickers/branding/business-hours-and-information/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Functional Films"),
            id: 83,
            link: `${myfolieBaseURL}/t/shop-window-stickers/functional-films/`,
            children: [
              {
                name: context.t("Privacy Films"),
                id: 831,
                link: `${myfolieBaseURL}/t/shop-window-stickers/functional-films/privacy-films/`,
                children: [],
              },
              {
                name: context.t("Sun-blocking & UV Protective Films"),
                id: 833,
                link: `${myfolieBaseURL}/t/shop-window-stickers/functional-films/sun-blocking-and-uv-protective-films/`,
                children: [],
              },
              {
                name: context.t("Anti-Glare & Reflective Films"),
                id: 834,
                link: `${myfolieBaseURL}/t/shop-window-stickers/functional-films/anti-glare-and-reflective-films/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Decorative Films"),
            id: 84,
            link: `${myfolieBaseURL}/t/shop-window-stickers/decorative-films/`,
            children: [
              {
                name: context.t("Frosted Glass Film"),
                id: 841,
                link: `${myfolieBaseURL}/t/shop-window-stickers/decorative-films/frosted-glass-film/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Industries Spotlights"),
            id: 89,
            link: `${myfolieBaseURL}/t/shop-window-stickers/industries/`,
            children: [
              {
                name: context.t("Food & Beverages"),
                id: 891,
                link: `${myfolieBaseURL}/t/shop-window-stickers/industries/food-and-beverage/`,
                children: [],
              },
              {
                name: context.t("Fashion & Retail"),
                id: 892,
                link: `${myfolieBaseURL}/t/shop-window-stickers/industries/fashion-and-retail/`,
                children: [],
              },
              {
                name: context.t("Hair Salon, Spa & Nail Shops"),
                id: 893,
                link: `${myfolieBaseURL}/t/shop-window-stickers/industries/hair-spa-nail/`,
                children: [],
              },
              {
                name: context.t("Entertainment & Leasure"),
                id: 894,
                link: `${myfolieBaseURL}/t/shop-window-stickers/industries/entertainment-and-leasure/`,
                children: [],
              },
              {
                name: context.t("Travel & Hospitality"),
                id: 895,
                link: `${myfolieBaseURL}/t/shop-window-stickers/industries/travel-and-hospitality/`,
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: context.t("Foils & Adhesive Films"),
        id: 4,
        link: `${myfolieBaseURL}/t/foils-and-adhesive-films/`,
        children: [
          {
            name: context.t("Adhesive Film, permanent"),
            id: 401,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/`,
            children: [
              {
                name: context.t("Single Colored Vinyls"),
                id: 4011,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/solid-colored-self-adhesive-film/`,
                children: [],
              },
              {
                name: context.t("Patterned Self-Adhesive Film"),
                id: 4012,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/patterned-self-adhesive-film/`,
                children: [],
              },
              {
                name: context.t("Frosted Glass"),
                id: 4013,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/frosted-glass-film/`,
                children: [],
              },
              {
                name: context.t("Floor Stickers"),
                id: 4014,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/floor-stickers/`,
                children: [],
              },
              {
                name: context.t("Perforated Film"),
                id: 4015,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/perforated-film/`,
                children: [],
              },
              {
                name: context.t("view all products"),
                id: 4019,
                category_overview: true,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/adhesive-film-permanent/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Film, reusable"),
            id: 42,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/film-reusable/`,
            children: [
              {
                name: context.t("Adhesion Film"),
                id: 421,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/film-reusable/adhesion-film/`,
                children: [],
              },
              {
                name: context.t("Magnetic Film"),
                id: 422,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/film-reusable/magnetic-film/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Furniture Film"),
            id: 43,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/furniture-film/`,
            children: [
              {
                name: context.t("Textured"),
                id: 431,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/furniture-film/textured-film/`,
              },
              {
                name: context.t("Solid Colored Adhesive Film"),
                id: 432,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/furniture-film/solid-color-adhesive-film/`,
              },
              {
                name: context.t("Patterned Adhesive Film"),
                id: 433,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/furniture-film/patterned-adhesive-film/`,
              },

              {
                name: context.t("Individual Size"),
                id: 439,
                link: `${myfolieBaseURL}/folienzuschnitt/`,
              },
            ],
          },
          {
            name: context.t("Tile Film"),
            id: 44,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/tile-film/`,
            children: [
              {
                name: context.t("Solid Colored"),
                id: 441,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/tile-film/solid-colors/`,
              },
              {
                name: context.t("Patterned"),
                id: 442,
                link: `${myfolieBaseURL}/t/foils-and-adhesive-films/tile-film/patterned/`,
              },
            ],
          },
          {
            name: context.t("Frosted Glass Film"),
            id: 45,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/frosted-glass-film/`,
            children: [],
          },
          {
            name: context.t("Chalkboard Film"),
            id: 46,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/chalkboard-film/`,
            children: [],
          },
          {
            name: context.t("Whiteboard Film"),
            id: 47,
            link: `${myfolieBaseURL}/t/foils-and-adhesive-films/whiteboard-film/`,
            children: [],
          },
          {
            name: context.t("Accessories"),
            id: 49,
            link: `${myfolieBaseURL}/t/accessories/`,
            children: [
              {
                name: context.t("Installation"),
                id: 491,
                link: `${myfolieBaseURL}/t/accessories/installation/`,
                children: [],
              },
              // {
              //   name: context.t("Pens & chalk"),
              //   id: 492,
              //   link: `${myfolieBaseURL}/t/accessories/pens-and-chalk/`,
              //   children: [],
              // },
              {
                name: context.t("Color chart & samples"),
                id: 493,
                link: `${myfolieBaseURL}/t/accessories/color-samples/`,
                children: [],
              },
              {
                name: context.t("Mixed remains"),
                id: 494,
                link: `${myfolieBaseURL}/t/accessories/mixed-remains/`,
                children: [],
              },
            ],
          },
        ],
        teaser: [
          {
            name: context.t("Individual Size"),
            link: `${myfolieBaseURL}/folienzuschnitt/`,
            img: "https://cdn.myfolie.de/media/individualcut-nav.png",
            text: context.t(
              "Order adhesive vinyl in any size required with no minimum amount"
            ),
            cta: context.t("calculate"),
          },
          {
            name: context.t("Remix your furniture"),
            link: `${myfolieBaseURL}/p/moebelfolie/`,
            img: "https://cdn.myfolie.de/media/nav_blankfilmcut.png",
            text: context.t(
              "Customize your furniture in unique colors and patterns"
            ),
            cta: context.t("discover"),
          },
        ],
      },
      {
        name: context.t("More Products"),
        id: 3,
        link: `${myfolieBaseURL}/shop/`,
        children: [
          {
            name: context.t("Floor Stickers"),
            id: 34,
            link: `${myfolieBaseURL}/t/floor-stickers/`,
            children: [
              {
                name: context.t("Keep Distance Floor Marking"),
                id: 341,
                link: `${myfolieBaseURL}/t/floor-stickers/keep-distance/`,
                children: [],
              },
              {
                name: context.t("Guides & Navigation Floor Markings"),
                id: 342,
                link: `${myfolieBaseURL}/t/floor-stickers/guides-and-navigation/`,
                children: [],
              },
              {
                name: context.t("Sale & Promotion Floor Stickers"),
                id: 343,
                link: `${myfolieBaseURL}/t/floor-stickers/sale-and-promotion/`,
                children: [],
              },
              {
                name: context.t("Custom Design & Logo"),
                id: 344,
                link: `${myfolieBaseURL}/t/floor-stickers/custom-design-and-logo/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("stickers"),
            id: 310,
            link: `${myfolieBaseURL}/t/stickers/`,
            children: [],
          },
          {
            name: context.t("Safety Stickers"),
            id: 33,
            link: `${myfolieBaseURL}/t/safety-stickers/`,
            children: [
              {
                name: context.t("Mandatory Signs"),
                id: 331,
                link: `${myfolieBaseURL}/t/safety-stickers/mandatory-signs/`,
                children: [],
              },
              {
                name: context.t("Warning Signs"),
                id: 332,
                link: `${myfolieBaseURL}/t/safety-stickers/warning-signs/`,
                children: [],
              },
              {
                name: context.t("Emergency Signs"),
                id: 333,
                link: `${myfolieBaseURL}/t/safety-stickers/emegerncy-signs/`,
                children: [],
              },
              {
                name: context.t("Prohibition Signs"),
                id: 334,
                link: `${myfolieBaseURL}/t/safety-stickers/prohibition-signs/`,
                children: [],
              },
              {
                name: context.t("Fire Protection Sign"),
                id: 335,
                link: `${myfolieBaseURL}/t/safety-stickers/fire-protection-signs/`,
                children: [],
              },
            ],
          },

          {
            name: context.t("Walltattoo"),
            id: 313,
            link: `${myfolieBaseURL}/t/walltattoo/`,
            children: [
              {
                name: context.t("Personalized Walltattoo"),
                id: 3131,
                link: `${myfolieBaseURL}/t/walltattoo/personalized-walltattoo/`,
                children: [],
              },
              {
                name: context.t("Designers Walltattoo Collection"),
                id: 3132,
                link: `${myfolieBaseURL}/t/walltattoo/designers-walltattoo-collection/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Photo Murals & Wallpaper"),
            id: 308,
            link: `${myfolieBaseURL}/t/photo-murals-and-wallpaper/`,
            children: [
              {
                name: context.t("Custom Photo Wallpapers"),
                id: 3081,
                link: `${myfolieBaseURL}/t/photo-murals-and-wallpaper/custom-photo-wallpapers/`,
                children: [],
              },
              {
                name: context.t("Designer Wallpaper Collection"),
                id: 3082,
                link: `${myfolieBaseURL}/t/photo-murals-and-wallpaper/designer-wallpaper-collection/`,
                children: [],
              },
              {
                name: context.t("Custom Canvas Prints"),
                id: 3083,
                link: `${myfolieBaseURL}/t/photo-murals-and-wallpaper/custom-canvas-prints/`,
                children: [],
              },
              {
                name: context.t("Personalized Poster Prints"),
                id: 3084,
                link: `${myfolieBaseURL}/t/photo-murals-and-wallpaper/personalized-poster-prints/`,
                children: [],
              },
            ],
          },
          {
            name: context.t("Signs & Signage"),
            id: 309,
            link: `${myfolieBaseURL}/t/signs-and-signage/`,
            children: [],
          },
          {
            name: context.t("Tarps & Banners"),
            id: 306,
            link: `${myfolieBaseURL}/t/tarps-and-banners/`,
            children: [],
          },

          {
            name: context.t("Accessories"),
            id: 311,
            link: `${myfolieBaseURL}/t/accessories/`,
            children: [
              {
                name: context.t("Installation"),
                id: 3111,
                link: `${myfolieBaseURL}/t/accessories/installation/`,
                children: [],
              },
              {
                name: context.t("Pens & chalk"),
                id: 3112,
                link: `${myfolieBaseURL}/t/accessories/pens-and-chalk/`,
                children: [],
              },
              {
                name: context.t("Color chart & samples"),
                id: 3113,
                link: `${myfolieBaseURL}/t/accessories/color-samples/`,
                children: [],
              },
              {
                name: context.t("Mixed remains"),
                id: 3114,
                link: `${myfolieBaseURL}/t/accessories/mixed-remains/`,
                children: [],
              },
            ],
          },
        ],
        teaser: [
          {
            name: context.t("Personalized Walltattoo Lettering"),
            link: `${myfolieBaseURL}/i/text-of-your-choice-as-wall-tattoo/`,
            img: "https://cdn.myfolie.de/media/walltattoo_text.jpg",
            text: context.t(
              "Transform your spaces with our custom adhesive lettering. Tailored to your design, these wall tattoos effortlessly infuse personality and charm into any room. Make a statement that's uniquely yours."
            ),
            cta: context.t("Customize"),
          },
          {
            name: context.t("Capture Memories on Canvas"),
            link: `${myfolieBaseURL}/p/fotoleinwand/`,
            img: "https://cdn.myfolie.de/media/canvas_teaser.jpg",
            text: context.t(
              "Bring your photos to life with our exquisite canvas prints. Perfectly transferring your cherished moments onto canvas, they become stunning pieces of art for any space. Celebrate memories that last a lifetime."
            ),
            cta: context.t("upload photo"),
          },
        ],
      },
      {
        name: context.t("Help & FAQs"),
        id: 5,
        link: `${myfolieBaseURL}/faqs/`,
        teaser: [
          {
            name: context.t("Easy Installation Guide"),
            link: `${myfolieBaseURL}/p/montage/`,
            img: "https://cdn.myfolie.de/media/installation_teaser.jpg",
            text: context.t(
              "Dive into our straightforward installation instructions for adhesive letterings, stickers, and more. Ensure a flawless finish with step-by-step guidance, turning your vision into a reality with ease."
            ),
            cta: context.t("discover"),
          },
          {
            name: context.t("Production Data Guidelines"),
            link: `${myfolieBaseURL}/fileguide/`,
            img: "https://cdn.myfolie.de/media/fileguide_teaser.jpg",
            text: context.t(
              "Master your print projects with our file guide. Understand the key requirements for print files, ensuring crisp results and impeccable quality. Your blueprint for print perfection."
            ),
            cta: context.t("discover"),
          },
        ],
        children: [
          {
            name: context.t("Contact Support"),
            id: 51,
            link: `${myfolieBaseURL}/kontakt/`,
            children: [],
          },
          {
            name: context.t("FAQs"),
            id: 52,
            link: `${myfolieBaseURL}/faqs/`,
            children: [],
          },
          {
            name: context.t("Installation instruction"),
            id: 56,
            link: `${myfolieBaseURL}/p/montage/`,
            children: [],
          },
          {
            name: context.t("Production Data Guideline"),
            id: 53,
            link: `${myfolieBaseURL}/fileguide/`,
            children: [],
          },
          {
            name: context.t("Shipping & Pricing"),
            id: 54,
            link: `${myfolieBaseURL}/p/versandarten/`,
            children: [],
          },
          {
            name: context.t("Where is my order?"),
            id: 55,
            link: `${myfolieBaseURL}/faq/wann-kommt-meine-bestellung/`,
            children: [],
          },
        ],
      },
      {
        name: context.t("Design Lettering"),
        id: 6,
        link: `${myfolieBaseURL}/klebeschriften/`,
        children: [],
        classes: `nav-link nav-link-pill`,
      },
      {
        name: context.t("Upload File"),
        id: 7,
        link: `${myfolieBaseURL}/eigenes-motiv/`,
        children: [],
        classes: `nav-link nav-link-pill`,
      },
    ],
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const openMobileNav = () => {
    setIsOpen(true);
  };

  const closeMobileNav = () => {
    setIsOpen(false);
  };

  const openCart = () => {
    if (isCartOpen) {
      window.location = `${myfolieBaseURL}/checkout/cart/`;
    } else {
      setIsCartOpen(true);
    }
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  const onMouseEnterMainNav = (i) => {
    if (navigationTimeOut) {
      clearTimeout(navigationTimeOut);
      setNavigationTimeOut(null);
    }

    const mainNavItem = menu?.children?.find((item) => item.id === i);
    const openNavItem =
      mainNavItem?.children[0]?.children?.length > 0
        ? mainNavItem?.children[0]?.id
        : i;

    setOpenNav(openNavItem);
    forceCheck(); // Assuming forceCheck doesn't depend on this component's state or props
  };

  const onMouseLeaveMainNav = (timeout) => {
    if (timeout === 0) {
      setNavigationTimeOut(null);
      setOpenNav(null);
    }
    if (timeout !== 0) {
      setNavigationTimeOut(
        setTimeout(() => {
          setOpenNav("");
        }, timeout)
      );
    }
  };

  const renderNode = (node, key, propMap) => {
    const hasChildren = node?.children?.length > 0;
    const teaser = node[propMap.teaser];
    const hasTeaser = teaser?.length > 0;
    const nodeData = {
      node,
      propMap,
    };
    if (
      !node[propMap.excludedLang] ||
      !node[propMap.excludedLang].includes(lang)
    ) {
      return (
        <NavItem
          key={`${key}`}
          onMouseEnter={() => onMouseEnterMainNav(node[propMap.id])}
          className={
            openNav &&
            node[propMap.id] &&
            openNav.toString().startsWith(node[propMap.id].toString()) &&
            !node.category_overview
              ? "hover"
              : ""
          }
        >
          {!node[propMap.external] && (
            <Link
              to={node[propMap.url]}
              className={`nav-link ${node[propMap.linkClasses] || ""} ${
                node.category_overview
                  ? "small text-primary bg-white border-0"
                  : ""
              }`}
              onClick={() => onMouseLeaveMainNav(0)}
            >
              {node?.icon && <span className="mr-1">{node?.icon}</span>}
              {node[propMap.displayName]}
              {node.label && (
                <Badge color={node.label_color || "danger"} className="ml-1">
                  {node.label}
                </Badge>
              )}
              {openNav &&
                node[propMap.id] &&
                node[propMap.id].toString().length === 1 &&
                openNav.toString().startsWith(node[propMap.id].toString()) &&
                hasChildren > 0 && (
                  <div style={{ height: "3px" }} className="bg-primary"></div>
                )}
              {hasChildren > 0 && (
                <span className="nav-link-arrow">
                  <FaChevronRight />
                </span>
              )}
            </Link>
          )}
          {node[propMap.external] && (
            <a
              href={node[propMap.url]}
              className={`nav-link ${node[propMap.linkClasses] || ""}`}
              onClick={() => onMouseLeaveMainNav(0)}
            >
              {node?.icon && <span className="mr-1">{node?.icon}</span>}
              {node[propMap.displayName]}
              {hasChildren > 0 && (
                <span className="nav-link-arrow">
                  <FaChevronRight />
                </span>
              )}
            </a>
          )}
          {(hasChildren || hasTeaser) &&
            openNav &&
            node[propMap.id] &&
            openNav.toString().startsWith(node[propMap.id].toString()) &&
            renderSubMenu(nodeData)}
        </NavItem>
      );
    } else {
      return null;
    }
  };
  const renderTeaser = (node, key, propMap) => {
    return (
      <div
        key={`${key}`}
        className={
          openNav &&
          node[propMap.id] &&
          openNav.toString().startsWith(node[propMap.id].toString())
            ? "nav-teaser hover"
            : "nav-teaser"
        }
      >
        {!node[propMap.external] && (
          <Link
            to={node[propMap.url]}
            className={classNames(
              "nav-teaser__link",
              node[propMap.linkClasses]
            )}
            onClick={() => onMouseLeaveMainNav(0)}
          >
            {node[propMap.img] && (
              <LazyLoad once>
                <img
                  src={node[propMap.img]}
                  className="card-img"
                  alt={node[propMap.displayName]}
                  loading="lazy"
                />
              </LazyLoad>
            )}
            <div className="py-3">
              <p className="h5">{node[propMap.displayName]}</p>
              <p className="mb-1">{node[propMap.text]}</p>
              {node[propMap.cta] && (
                <span className="btn btn-link pl-0">
                  {node[propMap.cta]} <FaChevronRight />
                </span>
              )}
            </div>
          </Link>
        )}
        {node[propMap.external] && (
          <a
            href={node[propMap.url]}
            className={classNames(
              "nav-teaser__link",
              node[propMap.linkClasses]
            )}
            onClick={() => onMouseLeaveMainNav(0)}
          >
            {node[propMap.img] && (
              <LazyLoad once>
                <img
                  src={node[propMap.img]}
                  className="card-img"
                  alt={node[propMap.displayName]}
                  loading="lazy"
                />
              </LazyLoad>
            )}
            <div className="py-3">
              <p className="h5">{node[propMap.displayName]}</p>
              <p className="mb-1">{node[propMap.text]}</p>
              {node[propMap.cta] && (
                <span className="btn btn-link pl-0">
                  {node[propMap.cta]} <FaChevronRight />
                </span>
              )}
            </div>
          </a>
        )}
      </div>
    );
  };

  const renderSubMenu = (nodeData) => {
    const { node, propMap } = nodeData;
    const nodeChildren = node[propMap.childPropName];
    const hasChildren = node?.children?.length > 0;
    const teaser = node[propMap.teaser];
    const hasTeaser = teaser && teaser.length > 0;
    const teaserChildren = node.teaserChildren;
    return (
      <div className="navigationbar__links__submenu">
        {!teaserChildren && (
          <ul className="navigationbar__links__submenu_items pull-left">
            {nodeChildren &&
              nodeChildren.length > 0 &&
              nodeChildren.map((node, key) => {
                return renderNode(node, key, propMap);
              })}
          </ul>
        )}
        {teaserChildren && (
          <div className="d-flex flex-wrap">
            {nodeChildren &&
              nodeChildren.length > 0 &&
              nodeChildren.map((node, key) => {
                return renderTeaser(node, key, propMap);
              })}
          </div>
        )}
        {hasTeaser && (
          <div
            className={classNames(hasChildren ? "pull-right" : "", "d-flex")}
          >
            {teaser.map((node, key) => {
              return renderTeaser(node, key, propMap);
            })}
          </div>
        )}
      </div>
    );
  };

  const renderDesktopNav = (nodes) => {
    const propMap = Object.assign({}, defaultPropMaps, props.propMap);
    const nodeChildren = nodes[propMap.childPropName] || [];
    return (
      <Nav
        navbar
        className="navigationbar__links"
        onMouseLeave={() => onMouseLeaveMainNav(250)}
      >
        {nodeChildren &&
          nodeChildren.map((node, key) => {
            return renderNode(node, key, propMap);
          })}
      </Nav>
    );
  };

  const propMap = Object.assign({}, defaultPropMaps, props.propMap);
  const smallDevice = !isServer
    ? window.matchMedia("(max-width: 1199px)").matches
    : false;

  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar light color="white" expand="xl" className="container">
        <div
          className={classNames("rpm-trigger mobile-menu", {
            active: isOpen,
          })}
          onClick={toggle}
          id="rpm-trigger"
        >
          <div className="hamburg">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        <Link to={`${myfolieBaseURL}/`} className="navbar-brand">
          <img
            src={logo}
            width="145"
            height="42"
            data-src-retina={logo}
            className="logo"
            title="myfolie.com"
            alt="myfolie.com"
          />
        </Link>
        {!smallDevice && renderDesktopNav(menu)}
        <div className="navigationbar__useractions d-flex">
          <Button
            key="search-button"
            outline
            color="link"
            style={{ border: 0 }}
            onClick={openSearch}
            className="sm-link d-flex flex-column text-center ml-2"
          >
            <FaSearch className="mx-auto" />
            {!smallDevice && (
              <span className="small mt-1">{context.t("Search")}</span>
            )}
          </Button>
          {false && !isServer && !smallDevice && (
            <a
              key="customer-account-button"
              href={`${myfolieBaseURL}/customer/account/`}
              className="btn btn-outline-link d-flex flex-column text-center"
            >
              <FaUser className="mx-auto" />
              <span className="small mt-1" style={{ minWidth: "80px" }}>
                {context.t("My Account")}
              </span>
            </a>
          )}
          <Button
            key="shopping-cart-button"
            outline
            color="link"
            style={{ border: 0, position: "relative" }}
            onClick={openCart}
            className="sm-link d-flex flex-column text-center ml-2"
          >
            <FaShoppingCart className="mx-auto" />
            {!smallDevice && (
              <span className="small mt-1">{context.t("Cart")}</span>
            )}
            {cart?.data?.attributes?.item_count > 0 &&
              cart?.data?.attributes?.state !== "complete" && (
                <Badge
                  color="primary"
                  style={{ top: 0, right: 0, position: "absolute" }}
                >
                  {cart.data.attributes.item_count}
                </Badge>
              )}
          </Button>
        </div>
      </Navbar>
      {smallDevice && isOpen && (
        <PushMenu
          lang={lang}
          expanderComponent={FaChevronRight}
          backIcon={<FaChevronLeft />}
          nodes={menu}
          type={"cover"}
          isOpen={isOpen}
          onMenuOpen={openMobileNav}
          onMenuClose={closeMobileNav}
          onNodeClick={(e, data) => {
            data.menu.tools.reset();
            closeMobileNav();
          }}
          propMap={propMap}
        ></PushMenu>
      )}

      {isCartOpen && (
        <>
          <div className="sidebar-cart bg-light">
            <Container>
              <div
                className="row bg-light shadow-sm py-2"
                style={{
                  boxShadow: "0 -5px 5px -5px #ddd",
                  position: "relative",
                  zIndex: 2050,
                }}
              >
                {!smallDevice && (
                  <Col xs={2}>
                    <Button outline onClick={closeCart}>
                      <FaChevronRight />
                    </Button>
                  </Col>
                )}
                <Col xs={10} className="text-center pt-1">
                  <Link
                    className="h4"
                    to={`/${lang}/checkout/cart/`}
                    onClick={closeCart}
                  >
                    {context.t("Cart")}
                  </Link>
                </Col>
                {smallDevice && (
                  <Col xs={2}>
                    <Button outline onClick={closeCart}>
                      X
                    </Button>
                  </Col>
                )}
              </div>
            </Container>
            <CheckoutCart
              navigateTo={(url) => dispatch(push(url))}
              closeCart={closeCart}
              language={lang}
            />
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setIsCartOpen(false)}
          ></div>
        </>
      )}

      {isSearchOpen && (
        <Modal
          fade={false}
          size="lg"
          centered
          isOpen={isSearchOpen}
          toggle={closeSearch}
        >
          <ModalHeader toggle={closeSearch}>
            {context.t("Search myfolie.com")}
          </ModalHeader>
          <ModalBody>
            <ProductSearch searchType="nav" closeSearch={closeSearch} />
          </ModalBody>
        </Modal>
      )}
      {alertMessage && (
        <Container>
          <FlashAlert
            link={alertLink}
            color={alertColor || "success"}
            origin={alertOrigin}
            timeout={alertTimeout}
          >
            {alertMessage}
          </FlashAlert>
        </Container>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};
export default Navigationbar;
