import { createSlice } from "@reduxjs/toolkit";

export const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState: {
    list: [],
    loadingList: false,
    loadedList: false,
    listError: null,
    subscribing: false,
    subscribed: false,
    subscribeError: null,
    showError: false,
    unsubscribing: false,
    unsubscribed: false,
    unsubscribeError: null,
    fallback: {},
    dictionary: {},
  },
  reducers: {
    list: (state) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
    },
    listSuccess: (state, action) => {
      state.loadingList = false;
      state.loadedList = true;
      state.count = action.payload.length;
      state.list = action.payload;
      action.payload.forEach((entry) => (state.dictionary[entry.id] = entry));
    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    show: (state, action) => {
      state.subscribing = true;
      state.subscribed = false;
      state.subscribeError = null;
      state.fallback = { slug: action.payload };
    },
    showSuccess: (state, action) => {
      state.subscribing = false;
      state.subscribed = true;
      state.dictionary[action.payload.id] = action.payload;
      state.show = action.payload;
      state.fallback = {};
    },
    showFail: (state, action) => {
      state.subscribing = false;
      state.subscribed = false;
      state.showError = state.fallback;
      state.subscribeError = action.payload.mess;
    },
  },
});

export const { list, show } = blogPostsSlice.actions;
export const actions = blogPostsSlice.actions;

const sorter = (a, b) => {
  if (a.key > b.key) {
    return 1;
  }
  if (a.key < b.key) {
    return -1;
  }
  return 0;
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const blogPosts = (state) =>
  Object.keys(state.blogPosts.dictionary)
    .map((entry) => state.blogPosts.dictionary[entry])
    .sort(sorter);
export const blogPost = (state) => state.blogPosts.show; // TODO: should only return if slug is the same

export default blogPostsSlice.reducer;
