import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentMethods: false,
  paymentMethodsLoading: false,
  paymentMethodsError: false,
  payment: false,
  paymentDetails: false,
  createPaymentLoading: false,
  createPaymentError: false,
  adyenConfig: null,
  adyenConfigLoading: false,
  adyenConfigError: false,
};

const adyenSlice = createSlice({
  name: "adyen",
  initialState,
  reducers: {
    showAdyenPaymentMethods: (state) => {
      state.paymentMethodsLoading = true;
      state.paymentMethodsError = false;
    },
    showAdyenPaymentMethodsSuccess: (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethodsError = false;
      state.paymentMethods = action.payload;
    },
    showAdyenPaymentMethodsFail: (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethodsError = action.payload || true;
      state.paymentMethods = null;
    },
    createAdyenPayment: (state) => {
      state.createPaymentLoading = true;
      state.createPaymentError = false;
    },
    createAdyenPaymentSuccess: (state, action) => {
      state.createPaymentLoading = false;
      state.createPaymentError = false;
      state.payment = action.payload;
    },
    createAdyenPaymentFail: (state, action) => {
      state.createPaymentLoading = false;
      state.createPaymentError = action.payload || true;
      state.payment = null;
    },
    showAdyenConfig: (state) => {
      state.adyenConfigLoading = true;
      state.adyenConfigError = false;
    },
    showAdyenConfigSuccess: (state, action) => {
      state.adyenConfigLoading = false;
      state.adyenConfigError = false;
      state.adyenConfig = action.payload;
    },
    showAdyenConfigFail: (state, action) => {
      state.adyenConfigLoading = false;
      state.adyenConfigError = action.payload || true;
      state.adyenConfig = null;
    },
    resetAdyenConfig: (state) => {
      state.adyenConfig = null;
    },
    resetPaymentMethods: (state) => {
      state.paymentMethods = null;
    },
  },
});

export const {
  showAdyenPaymentMethods,
  showAdyenPaymentMethodsSuccess,
  showAdyenPaymentMethodsFail,
  createAdyenPayment,
  createAdyenPaymentSuccess,
  createAdyenPaymentFail,
  showAdyenConfig,
  showAdyenConfigSuccess,
  showAdyenConfigFail,
  resetAdyenConfig,
  resetPaymentMethods,
} = adyenSlice.actions;

export const actions = adyenSlice.actions;
export default adyenSlice.reducer;
