/**
 *  Sagas for the content resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from 'redux-saga/effects';
import buildHeaders from '../../../utils/buildHeaders';
import { actions } from './index';
import apiClient, { applyHeaders } from '../utils/apiSwaggerRequest';
import { storeIncluded } from '../utils/dictionarySagas';

/** Worker Sagas */

/** Show Saga
 *  @description: connects to the showStaticPage operation
 *  @param {number} action.payload the content id
 */
export function* show(action) {
  const { id, token } = action.payload;
  const lang = yield select(state => state.i18nState.lang);
  let headers = buildHeaders();
  headers['Accept-Language'] = lang
  headers['case-token'] = token;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Cases.showCase,
      { id: id },
      { requestInterceptor: applyHeaders(headers) },
    );
    if (payload.obj?.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }
    yield put({ type: actions.showSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

/** Update Saga --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the updateCase operation. If faqcategory contains a picture also connects to the updateCase operation
 *  @param {Object} action.payload the case to update
 */
export function* update(action) {
  const { id, token, userInput } = action.payload;
  const lang = yield select(state => state.i18nState.lang);

  // If cancel and case is not new case, just cancel in UI
  if (userInput.cancel && userInput.current_status !== "new_case") {
    yield put({ type: actions.resetCase, payload: { id: id } });
  } else {
    let headers = buildHeaders();
    headers['Accept-Language'] = lang
    headers['case-token'] = token;
    try {
      const SwaggerClient = yield call(apiClient);

      const payload = yield call(
        SwaggerClient.apis.Cases.updateCase,
        { id: id },
        {
          requestInterceptor: applyHeaders(headers),
          requestBody: { ...userInput }
        }
      );
      if (payload.obj?.included) {
        yield* storeIncluded({ payload: payload.obj.included });
      }
      yield put({ type: actions.updateSuccess, payload: payload.obj });
    } catch (e) {
      yield put({ type: actions.updateFail, payload: e });
    }
    if (userInput.cancel) yield put({ type: actions.resetCase, payload: { id: id } });
  }
}

/** Create Saga --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the updateCase operation. If faqcategory contains a picture also connects to the updateCase operation
 *  @param {Object} action.payload the case to update
 */
export function* create(action) {
  const { token, userInput } = action.payload;
  const lang = yield select(state => state.i18nState.lang);
  let headers = buildHeaders();
  headers['Accept-Language'] = lang
  headers['case-token'] = token;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Cases.createCase,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: { ...userInput }
      }
    );
    if (payload.obj?.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }
    yield put({ type: actions.createSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* casesSaga() {
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
}
export default casesSaga;
