/**
 *  Sagas for the cmsblock resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { isServer } from "../store";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
// import apiRequest from '../../utils/apiRequest';
// import { push } from 'connected-react-router';

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getCmsBlocks operation
 */
export function* list(action) {
  const { slugs } = action.payload;
  /** if we are offline we use persisted data */

  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedCmsBlocks = yield select((state) => state.cmsblocks.stored);
    Object.keys(storedCmsBlocks).forEach((storedCmsBlockIndex) => {
      storedList.push(Object.assign({}, storedCmsBlocks[storedCmsBlockIndex]));
    });
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const SwaggerClient = yield call(apiClient);
      const payload = yield call(
        SwaggerClient.apis.CmsBlocks.getCmsBlocks,
        { slugs: slugs, lang: lang },
        { requestInterceptor: applyHeaders(headers) }
      );
      yield put(actions.listSuccess(payload.obj));
    } catch (e) {
      yield put(actions.listFail(e));
    }
  }
}

/** Show Saga
 *  @description: connects to the showCmsBlock operation
 *  @param {number} action.payload the cmsblock id
 */
export function* show(action) {
  const cmsblockId = action.payload;
  const storedCmsBlocks = yield select((state) => state.cmsblocks.stored);
  const lang = yield select((state) => state.i18nState.lang);
  /** if we are offline we use persisted data */
  if (
    !isServer &&
    navigator &&
    !navigator.onLine &&
    storedCmsBlocks[cmsblockId]
  ) {
    yield put({
      type: actions.showSuccess,
      payload: storedCmsBlocks[cmsblockId],
    });
  } else {
    // else we are online -> we fetch
    let headers = buildHeaders();
    try {
      const SwaggerClient = yield call(apiClient);
      const payload = yield call(
        SwaggerClient.apis.CmsBlocks.showCmsBlock,
        { id: cmsblockId, lang: lang },
        { requestInterceptor: applyHeaders(headers) }
      );
      yield put({ type: actions.showSuccess, payload: payload.obj });
    } catch (e) {
      yield put({ type: actions.showFail, payload: e });
    }
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an cmsblock is unstored
 */

export function* unstoreCmsBlock() {
  const image = yield select((state) => state.cmsblocks.show.image);
  caches
    .open("images-cache")
    .then((imagesCache) => {
      // console.log(imagesCache)
      return imagesCache.delete(image);
    })
    .then((response) => {
      // console.log(response)
    })
    .catch((err) => {
      console.log("Could not clear cache", err);
    });
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* cmsblocksSaga() {
  yield takeEvery(actions.listCmsBlock, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.unstoreCmsBlock, unstoreCmsBlock);
}
export default cmsblocksSaga;
