import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class DefaultLinkComponent extends Component {
  render(){
    const {propMap, node} = this.props.data;
    const nodeTitle = node[propMap.displayName];
    return (
      <Link
        onClick={(e) => {
          this.props.onNodeClick(e, this.props.data);
        }}
        className={ `rpm-node-link rpm-inline-block ${node[propMap.linkClasses] || ''}` }
        to={node[propMap.url] || "#"}>
        {nodeTitle}
      </Link>
    );
  }
}