import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  home: null,
  homeWithSearch: null,
  homeCMSBlocksSlugs: [],
  homeWithSearchCMSBlocksSlugs: [],
  loadingHomeWithSearch: false,
  loadingHome: false,
  loadedHomeWithSearch: false,
  loadedHome: false,
  homeErrorWithSearch: null,
  homeError: null,
  textEditor: null,
  textEditorCMSBlocksSlugs: [],
  blankCutEditor: null,
  blankCutEditorCMSBlocksSlugs: [],
  loadingTextEditor: false,
  loadedTextEditor: false,
  textEditorError: null,
  loadingBlankCutEditor: false,
  loadedBlankCutEditor: false,
  blankCutEditorError: null,
};

const xapiSlice = createSlice({
  name: "xapi",
  initialState,
  reducers: {
    loadHome: (state) => {
      state.loadingHome = true;
      state.loadedHome = false;
      state.homeError = null;
    },
    loadHomeSuccess: (state, action) => {
      state.loadingHome = false;
      state.loadedHome = true;
      state.home = action.payload;
      state.homeCMSBlocksSlugs = Object.keys(action.payload.cms_blocks);
    },
    loadHomeFail: (state, action) => {
      state.loadingHome = false;
      state.loadedHome = false;
      state.homeError = action.payload.mess;
    },
    loadHomeWithSearch: (state) => {
      state.loadingHomeWithSearch = true;
      state.loadedHomeWithSearch = false;
      state.homeErrorWithSearch = null;
    },
    loadHomeWithSearchSuccess: (state, action) => {
      state.loadingHomeWithSearch = false;
      state.loadedHomeWithSearch = true;
      state.homeWithSearch = action.payload;
      state.homeWithSearchCMSBlocksSlugs = Object.keys(
        action.payload.cms_blocks
      );
    },
    loadHomeWithSearchFail: (state, action) => {
      state.loadingHomeWithSearch = false;
      state.loadedHomeWithSearch = false;
      state.homeErrorWithSearch = action.payload.mess;
    },
    loadTextEditor: (state) => {
      state.loadingTextEditor = true;
      state.loadedTextEditor = false;
      state.textEditorError = null;
    },
    loadTextEditorSuccess: (state, action) => {
      state.loadingTextEditor = false;
      state.loadedTextEditor = true;
      state.textEditor = action.payload;
      state.textEditorCMSBlocksSlugs = Object.keys(
        action.payload.cms_blocks || {}
      );
    },
    loadTextEditorFail: (state, action) => {
      state.loadingTextEditor = false;
      state.loadedTextEditor = false;
      state.textEditorError = action.payload.mess;
    },
    loadBlankCutEditor: (state) => {
      state.loadingBlankCutEditor = true;
      state.loadedBlankCutEditor = false;
      state.blankCutEditorError = null;
    },
    loadBlankCutEditorSuccess: (state, action) => {
      state.loadingBlankCutEditor = false;
      state.loadedBlankCutEditor = true;
      state.blankCutEditor = action.payload;
      state.blankCutEditorCMSBlocksSlugs = Object.keys(
        action.payload.cms_blocks || {}
      );
    },
    loadBlankCutEditorFail: (state, action) => {
      state.loadingBlankCutEditor = false;
      state.loadedBlankCutEditor = false;
      state.blankCutEditorError = action.payload.mess;
    },
  },
});

export const {
  loadHome,
  loadHomeSuccess,
  loadHomeFail,
  loadHomeWithSearch,
  loadHomeWithSearchSuccess,
  loadHomeWithSearchFail,
  loadTextEditor,
  loadTextEditorSuccess,
  loadTextEditorFail,
  loadBlankCutEditor,
  loadBlankCutEditorSuccess,
  loadBlankCutEditorFail,
} = xapiSlice.actions;

export const actions = xapiSlice.actions;

export default xapiSlice.reducer;
