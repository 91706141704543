import { createSlice } from '@reduxjs/toolkit';

export const countrySlice = createSlice({
  name: 'shopCountries',
  initialState: {
    list: [],
    listLoading: false,
    listLoaded: false,
    listError: null,
    show: null,
    showStates: [],
    showLoading: false,
    showLoaded: false,
    showError: null,
    countryStatesDictionary: {}
  },
  reducers: {
    listCountries: (state) => {
      state.listLoading = true;
      state.listLoaded = false;
      state.listError = null;
    },
    listCountriesSuccess: (state, action) => {
      state.listLoading = false;
      state.listLoaded = true;
      state.list = action.payload;
    },
    listCountriesFail: (state, action) => {
      state.listLoading = false;
      state.listLoaded = false;
      state.listError = action.payload.message || true;
    },
    showCountry: (state, action) => {
      state.showLoading = true;
      state.showLoaded = false;
      state.showError = null;
      state.showStates = state.countryStatesDictionary[action.payload] ? state.countryStatesDictionary[action.payload].included : []
    },
    showCountrySuccess: (state, action) => {
      state.showLoading = false;
      state.showLoaded = true;
      state.show = action.payload;
      state.showStates = action.payload.included;
      state.countryStatesDictionary[action.payload.data.attributes.iso] = action.payload;
    },
    showCountryFail: (state, action) => {
      state.showLoading = false;
      state.showLoaded = false;
      state.showError = action.payload.message || true;
    },
  },
});

export const { 
  listCountries, 
  showCountry, 
} = countrySlice.actions;
export const actions = countrySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCountries = state => state.shopCountries.list;
export const selectedCountryStates = (state, iso) => {
  if (state.shopCountries.countryStatesDictionary[iso]) return state.shopCountries.countryStatesDictionary[iso].included.map(countryState => countryState.attributes)
  return [];
}
export const selectedCountryValidatesStates = (state, iso) => {
  if (state.shopCountries.countryStatesDictionary[iso]) return state.shopCountries.countryStatesDictionary[iso].data.attributes.states_required
  return false;
}
// export const selectedCountryValidatesStates = state => state.shopCountries.show && state.shopCountries.show.data ? state.shopCountries.show.data.attributes.states_required : false;
export const sesslectedCountryStates = state => state.shopCountries.showStates.map(countryState => countryState.attributes);


export default countrySlice.reducer;
