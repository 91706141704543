/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import Swagger from "swagger-client";
import getCroppedImg from "../../../utils/cropImage";
/** Worker Sagas */
import DataLayerHelper from "../../../utils/dataLayer";

export function* addToCart(action) {
  const requestStart = new Date();
  const { jsonData, successCallback } = action.payload;
  if (jsonData.fileCrop) {
    const file = yield select((state) => state.editorFile);
    try {
      const croppedImage = yield call(
        getCroppedImg,
        file.preview,
        jsonData.fileCrop.image,
        jsonData.fileCrop.rotation
      );
      jsonData.cropPreview = croppedImage;
    } catch (e) {
      console.log("AN ERROR OCCURRED WHILE CREATING PREVIEW", e);
      console.error(e);
    }
  }
  let body = JSON.stringify(jsonData);
  try {
    const payload = yield call(
      apiRequest,
      `https://media.myfolie.com/api/process_editor-v2.php?async=true`,
      { body, method: "POST" }
    );
    yield put(actions.addToCartSuccess(payload));
    if (payload.url) {
      DataLayerHelper.addSuccessEvent(
        "createProduct",
        "duration",
        new Date() - requestStart
      );
      if (successCallback) {
        successCallback(payload);
      }
    }
  } catch (e) {
    DataLayerHelper.addErrorEvent("createProduct", e.message);
    yield put(actions.addToCartFail(e));
  }
}

export function* addToShopCart(action) {
  const requestStart = new Date();
  const productId = action.payload.id;
  const qty = action.payload.amount;
  try {
    const payloadShop = yield call(
      apiRequest,
      `https://media.myfolie.com/de/cartpro/checkout_cart/add/product/${productId}/?skip_fk=true&fck=2&qty=${qty}&stg=0`,
      { method: "GET", credentials: "include", retrySilent: true }
    );
    DataLayerHelper.addSuccessEvent(
      "addToCart",
      "duration",
      new Date() - requestStart
    );

    if (action.payload) {
      DataLayerHelper.addToCartEvent(action.payload);
    }
    yield put(actions.addToShopCartSuccess(payloadShop));
  } catch (e) {
    DataLayerHelper.addErrorEvent("addToCart", e.message);
    yield put(actions.addToShopCartFail(e));
  }
}

export function* getCart(action) {
  try {
    const payload = yield call(
      apiRequest,
      `https://media.myfolie.com/de/cartpro/checkout_cart/add/product/?skip_fk=true&fck=1&stg=0`,
      { method: "GET", credentials: "include", retrySilent: true }
    );
    DataLayerHelper.addSuccessEvent("getCart", "loaded");

    yield put(actions.getCartSuccess(payload));
  } catch (e) {
    DataLayerHelper.addErrorEvent("getCart", e.message);
    yield put(actions.getCartFail(e));
  }
}

export function* deleteFromCart(action) {
  const productId = action.payload;
  try {
    const payload = yield call(
      apiRequest,
      `https://media.myfolie.com/de/cartpro/checkout_cart/add/product/${productId}/?skip_fk=true&fck=3&stg=0`,
      { method: "GET", credentials: "include" }
    );
    DataLayerHelper.addSuccessEvent("deleteFromCart", productId);
    yield put(actions.deleteFromCartSuccess(payload));
  } catch (e) {
    DataLayerHelper.addErrorEvent("deleteFromCart", e.message);
    yield put(actions.deleteFromCartFail(e));
  }
}

export function* updateItemInCart(action) {
  const productId = action.payload.id;
  const qty = action.payload.amount;
  try {
    const payload = yield call(
      apiRequest,
      `https://media.myfolie.com/de/cartpro/checkout_cart/add/product/${productId}/?skip_fk=true&fck=4&qty=${qty}&stg=0`,
      { method: "GET", credentials: "include" }
    );
    DataLayerHelper.addSuccessEvent("updateItemInCart", productId);

    yield put(actions.updateItemInCartSuccess(payload));
  } catch (e) {
    DataLayerHelper.addErrorEvent("updateItemInCart", e.message);

    yield put(actions.updateItemInCartFail(e));
  }
}
/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopSaga() {
  yield takeEvery(actions.addToShopCart, addToShopCart);
  yield takeEvery(actions.deleteFromCart, deleteFromCart);
  yield takeEvery(actions.updateItemInCart, updateItemInCart);
  yield takeEvery(actions.addToCart, addToCart);
  yield takeEvery(actions.getCart, getCart);
}

export default shopSaga;

const apiRequest = (_url, args) => {
  const request = {
    url: _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  };

  if (args.credentials) request.credentials = args.credentials;

  return Swagger.http(request)
    .then((res) => {
      return res.body;
    })
    .catch((err) => {
      if (err.message && args.retrySilent) {
        // console.log("enable cookies");
        return Swagger.http(request)
          .then((res) => {
            return res.body;
          })
          .catch((err) => {
            console.log("ERROR IN APIREQUEST");
            throw err;
          });
      } else {
        console.log("ERROR IN APIREQUEST");
        throw err;
      }
    });
};
