/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import { actions as checkoutActions } from "../checkout";
import { setCookieError, resetCookieError } from "../errors";
import { isServer } from "../../store";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import DataLayerHelper from "../../../utils/dataLayer";

const CART_INCLUDES =
  "line_items,line_items.variants.images,variants,variants.images,shipping_address,user,payments,payments.source,shipments,billing_address,variants.products,variants.products.images";
/** Worker Sagas */

export function* add(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  yield put(resetCookieError({}));

  try {
    const body = JSON.stringify(action.payload);
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/cart/add_item.json?include=${CART_INCLUDES}`,
      { body, method: "POST", headers: headers, tokenRequired: true }
    );
    yield put(actions.addItemToCartSuccess(payload));
    yield put(checkoutActions.resetPaymentMethods({}));
    yield put(checkoutActions.resetShippingMethods({}));
    console.log(payload?.included);
    const lineItem = payload?.included?.find(
      (li) =>
        li.type === "line_item" &&
        parseInt(li.relationships?.variant?.data?.id) ===
          parseInt(action?.payload?.variant_id)
    );
    if (lineItem) {
      DataLayerHelper.addToCartEvent(lineItem.attributes);
    }
  } catch (e) {
    console.error("ERROR", e);
    if (
      e.code &&
      e.code === 412 &&
      e.name &&
      (e.name === "CookiesDisabledError" ||
        e.name === "CookiesCouldNotBeSetError")
    ) {
      yield put(setCookieError(e));
      DataLayerHelper.addErrorEvent("addToCart", e.name);
    } else {
      DataLayerHelper.addErrorEvent(
        "addToCart",
        e?.error || e?.message || "Shop API Failure"
      );
    }
    yield put(actions.addItemToCartFail(e));
  }
}

export function* removeLineItemFromCart(action) {
  const { lineItemId } = action.payload;
  const cart = yield select((state) => state.shopCart.cart);
  const lineItem = cart.included.find((li) => {
    return li.type === "line_item" && parseInt(li.id) === parseInt(lineItemId);
  });
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/cart/remove_line_item/${lineItemId}.json?include=${CART_INCLUDES}`,
      { method: "DELETE", headers: headers, tokenRequired: true }
    );
    yield put(actions.removeLineItemFromCartSuccess(payload));
    yield put(checkoutActions.resetPaymentMethods({}));
    yield put(checkoutActions.resetShippingMethods({}));
    if (lineItem) {
      DataLayerHelper.removeFromCartEvent(lineItem.attributes);
    }
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.removeLineItemFromCartFail(e));
    DataLayerHelper.addErrorEvent(
      "removeLineItem",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* updateLineItem(action) {
  /** if we are offline we use persisted data */
  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedProducts = yield select((state) => state.products.stored);
    Object.keys(storedProducts).forEach((storedProductIndex) => {
      storedList.push(Object.assign({}, storedProducts[storedProductIndex]));
    });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;

    try {
      const body = JSON.stringify(action.payload); // FIXME, maybe the reducer should normalize this?
      const payload = yield call(
        apiShopRequest,
        `/api/v2/storefront/cart/set_quantity.json?include=${CART_INCLUDES}`,
        { body, method: "PATCH", headers: headers, tokenRequired: true }
      );
      yield put(actions.updateLineItemInCartSuccess(payload));
      yield put(checkoutActions.resetPaymentMethods({}));
      yield put(checkoutActions.resetShippingMethods({}));
    } catch (e) {
      console.error("ERROR", e);
      yield put(actions.updateLineItemInCartFail(e));
      DataLayerHelper.addErrorEvent(
        "updateLineItem",
        e?.error || e?.message || "Shop API Failure"
      );
    }
  }
}

export function* show(action) {
  /**  else we are online -> we fetch */
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/cart.json?include=${CART_INCLUDES}`,
      {
        method: "GET",
        headers: headers,
        tokenRequired: true,
        precheckToken: true,
      }
    );
    if (action.payload && action.payload.callback)
      action.payload.callback(payload);
    yield put(actions.showCartSuccess(payload));
    if (payload.newToken) {
      yield put(checkoutActions.resetPaymentMethods({}));
      yield put(checkoutActions.resetShippingMethods({}));
    }
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.showCartFail(e));
    DataLayerHelper.addErrorEvent(
      "showCart",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* create(action) {
  // retrieve mandatory id if it does not exist!
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/cart.json?include=${CART_INCLUDES}`,
      { method: "POST", headers: headers }
    );
    // we trigger same as on show since every thing should work the same
    yield put(actions.showCartSuccess(payload));
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.showCartFail(e));
    DataLayerHelper.addErrorEvent(
      "createCart",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* applyCouponCode(action) {
  /**  else we are online -> we fetch */
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const body = JSON.stringify({ coupon_code: action.payload });
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/cart/apply_coupon_code.json?include=${CART_INCLUDES}`,
      { body, method: "PATCH", headers: headers, tokenRequired: true }
    );
    yield put(actions.showCartSuccess(payload));
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.applyCouponCodeFail(e));
    DataLayerHelper.addErrorEvent(
      "applyCouponCode",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopCartSaga() {
  yield takeEvery(actions.addItemToCart, add);
  yield takeEvery(actions.removeLineItemFromCart, removeLineItemFromCart);
  yield takeEvery(actions.updateLineItemInCart, updateLineItem);
  yield takeEvery(actions.showCart, show);
  yield takeEvery(actions.createCart, create);
  yield takeEvery(actions.applyCouponCode, applyCouponCode);
}

export default shopCartSaga;
