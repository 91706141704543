/**
 * The CheckoutModal component
 */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Spinner,
  ModalBody,
  ModalHeader,
} from "reactstrap";
// import AmazonPay from 'amazon-pay-react';
import DataLayerHelper from "../../../utils/dataLayer";
import AmazonPayButton from "./AmazonPayButton";
/**
 * Stateless react component to render the CheckoutModal component
 * @param {Object} props the props passed to the page.
 */
const CheckoutModal = (props, context) => {
  const { language, onClose, navigateTo } = props;
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amazonAgreementId, setAmazonAgreementId] = useState(false);
  const amazonCheckoutForm = useRef(null);

  const sendToCheckout = () => {
    setRedirectToCheckout(true);
    DataLayerHelper.addRedirectEvent("checkout shop", "checkoutModal");
    if (navigateTo) {
      navigateTo(`/${language}/checkout/process/`);
      if (onClose) onClose(true);
    } else {
      window.location = `/${language}/checkout/process/`;
    }
  };

  const sendToAmazonCheckout = (billingAgreementId) => {
    setRedirectToCheckout(true);
    DataLayerHelper.addRedirectEvent("checkout amazon", "checkoutModal");
    amazonCheckoutForm.current.submit();
  };

  return (
    <Modal fade={false} isOpen={true} centered size="md">
      <ModalHeader toggle={onClose}>
        {context.t("Please select method of payment")}
      </ModalHeader>

      <ModalBody>
        <Container>
          <Row>
            <Col md={5} className="border-1 shadow-sm py-2">
              <p onClick={sendToCheckout}>
                <img
                  style={{ maxHeight: "24px" }}
                  className="mr-2"
                  src="https://cdn.myfolie.de/media/icons/kreditkarte.png"
                  title={context.t(
                    "Credit Card (Visa, MasterCard or American Express)"
                  )}
                  alt={context.t(
                    "Credit Card (Visa, MasterCard or American Express)"
                  )}
                />
                <img
                  style={{ maxHeight: "24px" }}
                  className="mr-2"
                  src="https://cdn.myfolie.de/media/icons/sofort.png"
                  title={context.t("SOFORT Payment")}
                  alt={context.t("SOFORT Payment")}
                />
                <span className="h6 text-secondary">
                  {context.t("Prepayment")}
                </span>
              </p>
              <Button onClick={sendToCheckout} color="success">
                {context.t("proceed to checkout")}
              </Button>
            </Col>
            <Col md={2}>
              <p className="text-remark text-center py-4">{context.t("or")}</p>
            </Col>
            <Col md={5} className="amazon-pay border-1 shadow-sm py-2">
              <p className="semi-small text-info">
                {context.t("Use your amazon account for payment")}
              </p>
              Amazon Pay:
              <AmazonPayButton />
              {/* { !amazonAgreementId &&
                    <>
                    <AmazonPay
                      clientId='amzn1.application-oa2-client.e002601bcd1247d4bb9e3abb9ab876aa'
                      sellerId='AYJ8YL7ZZ2PVT'
                      agreementType=''
                      scope='profile payments:widget'
                      btnType='PwA'
                      btnColor='Gold'
                      region='de'
                      language={language || 'de'}
                      currency='EUR'
                      btnSize='small'
                      onAmazonLoginReady={(ready) => {  }}
                      handleButtonError={(ready) => {  }}
                      onConsentChange={(ready) => {  }}
                      handleConsentError={(ready) => {  }}
                      handleBillingAgreementId={(billingAgreementId) => { setAmazonAgreementId(billingAgreementId); sendToAmazonCheckout(billingAgreementId); }}
                      billingAgreementId={amazonAgreementId ? amazonAgreementId : 'new'}
                      useAmazonAddressBook={true}
                    />
                    </>
                  } */}
              <form
                method="post"
                ref={amazonCheckoutForm}
                style={{ display: "none" }}
                action={`https://www.myfolie.com/${language}/amazonpayments/checkout/`}
              >
                {/* <input value={amazonAgreementId} name="orderReferenceId" /> */}
              </form>
            </Col>
            {redirectToCheckout && (
              <Col xs={12} className="py-2 text-remark">
                <Spinner color="success" size="sm" className="mr-1" />
                {context.t("one moment please")}:{" "}
                {context.t("We are redirecting you to our checkout process.")}
              </Col>
            )}
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

/** define proptypes for the form */
CheckoutModal.propTypes = {
  language: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

/**  define proptype for the 'translation' function  */
CheckoutModal.contextTypes = {
  t: PropTypes.func,
};
export default CheckoutModal;
