/**
 * The Footer component
 */
import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Row } from "reactstrap";
import { FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

/**
 * Stateless react component to render the About page
 * @param {Object} props the props passed to the page.
 */
const Footer = (props, context) => {
  const { lang, isMinimalFooter, setEnforcePrivacyConsentModal } = props;
  let myfolieBaseURL = `/${lang}`;

  const CanLink = (props) => {
    if (props.languages.includes(lang)) {
      return props.children;
    } else return null;
  };
  return (
    <footer className="footer">
      <div className="footer__top"></div>
      {!isMinimalFooter && (
        <Container className="footer__container">
          <Row>
            <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">
                  {context.t("Customer Service")}
                </span>
              </div>
              <div className="footer__block__separator"></div>
              <ul>
                <li>
                  <Link to={`/${lang}/faq/was-ist-klebefolie/`}>
                    {context.t("What is adhesive foil?")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/montage/`}>
                    {context.t("Installation instruction for all products")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/faq/montage-klebeschrift/`}>
                    {context.t(
                      "Installation instruction for adhesvive lettering"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/versandarten/`}>
                    {context.t("Shipping & Delivery")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/zahlungsarten/`}>
                    {context.t("Payment Methods")}
                  </Link>
                </li>
                <li>
                  <a href={`${myfolieBaseURL}/kontakt/`}>
                    {context.t("Contact")}
                  </a>
                </li>
                <CanLink languages={["xx"]}>
                  <li>
                    <a href={`${myfolieBaseURL}/vor-ort-montage/`}>
                      {context.t("on-site assembly")}
                    </a>
                  </li>
                </CanLink>
                <CanLink languages={["xx"]}>
                  <li>
                    <a
                      href={`${myfolieBaseURL}/gewerblicher-wiederverkaeufer/`}
                    >
                      {context.t("Commercial resellers")}
                    </a>
                  </li>
                </CanLink>
                <li>
                  <Link to={`/${lang}/blog/`}>{context.t("Blog")}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/faqs/`}>
                    {context.t("FAQs / frequently asked questions")}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">
                  {context.t("Applications")}
                </span>
              </div>
              <div className="footer__block__separator"></div>

              <ul>
                <li>
                  <Link to={`/${lang}/p/autobeschriftung/`}>
                    {context.t("Car labelling")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/autoaufkleber/`}>
                    {context.t("Car sticker")}
                  </Link>
                </li>
                {/*<CanLink languages={['de']}>
                  <li>
                   <a href={`${myfolieBaseURL}/autosticker/`}>{ context.t("Car stickers") }</a>
                  </li>
                </CanLink>*/}
                <CanLink languages={["de"]}>
                  <li>
                    <Link to={`/${lang}/p/fliesenfolie/`}>
                      {context.t("tile foil")}
                    </Link>
                  </li>
                </CanLink>
                <li>
                  <Link to={`/${lang}/p/fototapete/`}>
                    {context.t("photo wallpaper")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/fotoleinwand/`}>
                    {context.t("photo canvas")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/lochfolie/`}>
                    {context.t("perforated foil")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/magnetfolie/`}>
                    {context.t("magnetic foil")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/milchglasfolie/`}>
                    {context.t("frosted glass foil")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/moebelfolie/`}>
                    {context.t("furniture foil")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/klebebuchstaben/`}>
                    {context.t("adhesive letters")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/schaufensterbeschriftung/`}>
                    {context.t("Shop window labeling")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/heckscheibenaufkleber/`}>
                    {context.t("rear window sticker")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/klebeschriften/`}>
                    {context.t("Vehicle Tagging")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/messebeschriftung/`}>
                    {context.t("Fair labelling")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/bootsbeschriftung/`}>
                    {context.t("Boat labelling")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/schilderbeschriftung/`}>
                    {context.t("Sign labelling")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/fahrzeugbeschriftung/`}>
                    {context.t("Vehicle labelling")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/p/statischefolie/`}>
                    {context.t("Static foil")}
                  </Link>
                </li>
              </ul>
            </Col>
            {/* <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">{ context.t("User Account") }</span>
              </div>
              <div className="footer__block__separator"></div>
              
              <ul>
                <li>
                  <a href={`${myfolieBaseURL}/customer/account/`}>{ context.t("My Accounts") }</a>
                </li>
                <li>
                  <a href={`${myfolieBaseURL}/sales/order/history/`}>{ context.t("My Orders") }</a>
                </li>
                <li>
                  <a href={`${myfolieBaseURL}/customer/address/`}>{ context.t("My Addresses") }</a>
                </li>
              </ul>
            </Col>*/}
            <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">
                  {context.t("Company")}
                </span>
              </div>
              <div className="footer__block__separator"></div>
              <div className="footer__contact">
                {/* <p className="footer__contact__phone d-flex align-items-center">
                  <i className="fa fa-mobile fa-lg fa-fw"></i>
                  <span className="ml-3">
                    089 / 92 131 57 - 0<br></br>
                    <em>Mo - Fr von 09:00 - 13:00 Uhr</em>
                  </span>
                </p> */}
                <p className="footer__contact__mail">
                  <Link to={`/${lang}/kontakt/`} className="text-white">
                    <FaEnvelope className=" fa-lg fa-fw" />
                    <span className="ml-3">{context.t("contact form")}</span>
                  </Link>
                </p>

                <ul>
                  <li>
                    <Link to={`/${lang}/p/impressum/`}>
                      {context.t("Imprint")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/p/datenschutz/`}>
                      {context.t("Data privacy")}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="#privacy"
                      onClick={() => {
                        setEnforcePrivacyConsentModal(true);
                      }}
                    >
                      {context.t("Dataprivacy Settings")}
                    </a>
                  </li>
                  {/*<li>
                    <a href={`${myfolieBaseURL}/jobs/`}>{ context.t("Jobs") }</a>
                  </li> */}
                  <li>
                    <Link to={`/${lang}/p/agb/`}>
                      {context.t("Terms and conditions")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <div className="footer__copyright bg-dark py-4">
        <span className="small">
          &copy; {context.t("Copyright 2011-2022 myfolie GmbH")}
        </span>
        {isMinimalFooter && (
          <span className="small ml-3">
            <Link to={`/${lang}/p/impressum/`}>{context.t("Imprint")}</Link> |{" "}
            <Link to={`/${lang}/p/datenschutz/`}>
              {context.t("Data privacy")}
            </Link>{" "}
            |{" "}
            <a
              href="#privacy"
              onClick={() => {
                setEnforcePrivacyConsentModal(true);
              }}
            >
              {context.t("Dataprivacy Settings")}
            </a>{" "}
            |{" "}
            <Link to={`/${lang}/p/agb/`}>
              {context.t("Terms and conditions")}
            </Link>
          </span>
        )}
      </div>
    </footer>
  );
};
Footer.contextTypes = {
  t: PropTypes.func,
  isMinimalFooter: PropTypes.bool,
};
export default Footer;
