/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice } from "@reduxjs/toolkit";

export const tmpFilesSlice = createSlice({
  name: "tmpFiles",
  initialState: {
    show: null,
    list: [],
    loadingShow: false,
    loadedShow: false,
    showError: null,
    creating: false,
    created: false,
    createError: null,
    updating: false,
    updated: false,
    updateError: null,
    removing: false,
    removed: false,
    removeError: null,
    idsList: [],
    dictionary: {},
    creatingFiles: 0,
  },
  reducers: {
    create: (state) => {
      state.creating = true;
      state.created = false;
      state.createError = null;
      state.creatingFiles = (state.creatingFiles || 0) + 1;
      state.errorsObject = null;
    },
    createSuccess: (state, action) => {
      state.creating = false;
      state.created = true;
      state.dictionary[action.payload.id] = action.payload;
      state.createError = null;
      state.errorsObject = null;
      state.creatingFiles = state.creatingFiles - 1;
    },
    createFail: (state, action) => {
      state.creating = false;
      state.created = false;
      state.creatingFiles = state.creatingFiles - 1;
      console.log("tmpFile-createFailReducer", action.payload);
      if (action.payload?.response?.body?.data) {
        state.errorsObject = action.payload.response.body.data;
      } else if (
        action.payload.status === 413 ||
        action.payload.statusCode === 413
      ) {
        state.errorsObject = "File is too big";
      } else {
        state.createError = action.payload.message;
      }
    },
    createErrorReset: (state) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
  },
});

export const { create: createTmpFile } = tmpFilesSlice.actions;
export const actions = tmpFilesSlice.actions;

export default tmpFilesSlice.reducer;
