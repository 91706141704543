import { createSlice } from "@reduxjs/toolkit";

const DESIGNER_DEFAULT_WIDTH = 600;
const DESIGNER_DEFAULT_HEIGHT = 600;

/** The items reducer initial state */
const defaultState = {
  width: 100,
  height: 24,
  matrix: "width",
  previewWidth: 460,
  previewHeight: 87,
  ratio: 0.24,
  scaleFactor: 1,
  productionScaleFactor: 1,
};

const sizeSlice = createSlice({
  name: "size",
  initialState: defaultState,
  reducers: {
    changeWidth: (state, action) => {
      const width = parseFloat(action.payload);
      const changedHeight = width * state.ratio;
      const newScaleFactorPreviewWidth = (1 / width) * state.previewWidth;
      state.width = width;
      state.height = changedHeight;
      state.productionScaleFactor = newScaleFactorPreviewWidth;
    },
    changeHeight: (state, action) => {
      const height = parseFloat(action.payload);
      const changedWidth = height / state.ratio;
      const newScaleFactorPreviewHeight = (1 / height) * state.previewHeight;
      state.width = changedWidth;
      state.height = height;
      state.productionScaleFactor = newScaleFactorPreviewHeight;
    },
    changeDesignerHeight: (state, action) => {
      const newScaleFactorHeight =
        (1 / action.payload.height) * DESIGNER_DEFAULT_HEIGHT;
      state.previewHeight = action.payload.height;
      state.height = action.payload.height;
      state.scaleFactor = newScaleFactorHeight;
    },
    changeDesignerWidth: (state, action) => {
      const newScaleFactorWidth =
        (1 / action.payload.width) * DESIGNER_DEFAULT_WIDTH;
      state.width = action.payload.width;
      state.previewWidth = action.payload.width;
      state.scaleFactor = newScaleFactorWidth;
    },
    updatePreviewSize: (state, action) => {
      state.previewWidth = action.payload.width;
      state.previewHeight = action.payload.height;
    },
    updateMatrix: (state, action) => {
      state.matrix = action.payload;
    },
    updateScaleFactor: (state, action) => {
      state.scaleFactor = action.payload;
    },
    updateRatio: (state, action) => {
      const ratio = action.payload.height / action.payload.width;
      if (state.matrix === "width") {
        state.height = state.width * ratio;
      } else {
        state.width = state.height / ratio;
      }
      state.ratio = ratio;
    },
    resetSize: () => defaultState,
  },
});

// Export the actions
export const {
  changeWidth,
  changeHeight,
  changeDesignerHeight,
  changeDesignerWidth,
  updateScaleFactor,
  updatePreviewSize,
  updateRatio,
  updateMatrix,
  resetSize,
} = sizeSlice.actions;

export const actions = sizeSlice.actions;
// Export the reducer
export default sizeSlice.reducer;
