/**
 *  Sagas for the faqcategory resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import actions from "./actions";
import { isServer } from "../store";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import apiRequest from "../../utils/apiRequest";
import { push } from "connected-react-router";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getFaqCategories operation
 */
export function* list() {
  /** if we are offline we use persisted data */
  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedFaqCategories = yield select(
      (state) => state.faqcategories.stored
    );
    Object.keys(storedFaqCategories).forEach((storedFaqCategoryIndex) => {
      storedList.push(
        Object.assign({}, storedFaqCategories[storedFaqCategoryIndex])
      );
    });
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const SwaggerClient = yield call(apiClient);
      const payload = yield call(
        SwaggerClient.apis.FaqCategories.getFaqCategories,
        { lang: lang },
        { requestInterceptor: applyHeaders(headers) }
      );
      yield put({ type: actions.listSuccess, payload: payload.obj });
    } catch (e) {
      yield put({ type: actions.listFail, payload: e });
    }
  }
}

/** Show Saga
 *  @description: connects to the showFaqCategory operation
 *  @param {number} action.payload the faqcategory id
 */
export function* show(action) {
  const faqcategorySlug = action.payload;
  const storedFaqCategories = yield select(
    (state) => state.faqcategories.stored
  );
  /** if we are offline we use persisted data */
  if (
    !isServer &&
    navigator &&
    !navigator.onLine &&
    storedFaqCategories[faqcategorySlug]
  ) {
    yield put({
      type: actions.showSuccess,
      payload: storedFaqCategories[faqcategorySlug],
    });
  } else {
    // else we are online -> we fetch
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const SwaggerClient = yield call(apiClient);
      const payload = yield call(
        SwaggerClient.apis.FaqCategories.showFaqCategory,
        { slug: faqcategorySlug, lang: lang },
        { requestInterceptor: applyHeaders(headers) }
      );
      yield put({ type: actions.showSuccess, payload: payload.obj });
    } catch (e) {
      yield put({ type: actions.showFail, payload: e });
    }
  }
}

/** Create Saga  --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the createFaqCategory operation. If faqcategory contains a picture also connects to the uploadFaqCategory operation
 *  @param {Object} action.payload the faqcategory to create
 */
export function* create(action) {
  let headers = buildHeaders();
  let values = action.payload;
  let body = JSON.stringify(values);
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "picture") {
        // skip picture since its a file
        body.append(`faqcategory[${key}]`, values[key]);
      }
    });
    body.append(
      "faqcategory[picture]",
      values.picture[0],
      values.picture[0].name
    ); // add the file
    delete headers["Content-Type"]; // remove header
  }
  try {
    const payload = yield call(apiRequest, `/faqcategories`, {
      body,
      method: "POST",
      headers,
    });
    yield put({ type: actions.createSuccess, payload: payload });
    yield put(push("/faqcategories/" + payload.id));
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/** Update Saga --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the updateFaqCategory operation. If faqcategory contains a picture also connects to the uploadFaqCategory operation
 *  @param {Object} action.payload the faqcategory to update
 */
export function* update(action) {
  let headers = buildHeaders();
  let values = action.payload;
  delete values.user_id;
  let body = JSON.stringify(values);
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "picture") {
        // skip picture since its a file
        body.append(`faqcategory[${key}]`, values[key]);
      }
    });
    body.append(
      "faqcategory[picture]",
      values.picture[0],
      values.picture[0].name
    ); // add the file
    delete headers["Content-Type"]; // remove header
  }
  try {
    const payload = yield call(
      apiRequest,
      `/faqcategories/${action.payload.id}`,
      { body, method: "PUT", headers }
    );
    yield put({ type: actions.updateSuccess, payload: payload });
    yield put(push("/faqcategories/" + payload.id));
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/** Remove Saga
 *  @description: connects to the deleteFaqCategory operation.
 *  @param {Object} action.payload the id of the faqcategory to delete
 */
export function* remove(action) {
  let headers = buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.FaqCategories.deleteFaqCategory,
      { id: action.payload.id },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.removeSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.removeFail, payload: e });
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an faqcategory is unstored
 */
export function* unstoreFaqCategory() {
  const image = yield select((state) => state.faqcategories.show.image);
  caches
    .open("images-cache")
    .then((imagesCache) => {
      // console.log(imagesCache)
      return imagesCache.delete(image);
    })
    .then((response) => {
      // console.log(response)
    })
    .catch((err) => {
      console.log("Could not clear cache", err);
    });
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* faqcategoriesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreFaqCategory, unstoreFaqCategory);
}
export default faqcategoriesSaga;
