import { createSlice, createSelector } from '@reduxjs/toolkit';

export const formStepsSlice = createSlice({
  name: 'formSteps',
  initialState: {
    show: null,
    list: [],
    loadingShow: false,
    loadedShow: false,
    showError: null,
    creating: false,
    created: false,
    createError: null,
    updating: false,
    updated: false,
    updateError: null,
    removing: false,
    removed: false,
    removeError: null,
    idsList: [],
    dictionary: {},
    slugIndex: {},
    showId: null,
    filtersToIdsDictionary: {},
  },
  reducers: {
    list: (state, action) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
      state.list = [];
      //   let cachedList = [];
      //   var ids = state.filtersToIdsDictionary[JSON.stringify(newFilters)];
      //   if (ids && ids.length) {
      //     state.idsList = ids;
      //     ids.forEach((id) => {
      //       const entry = state.dictionary[id];
      //       if (entry) cachedList.push(entry);
      //     });
      //   }
      //   state.list = cachedList;

      // }
    },
    listSuccess: (state, action) => {
      const responseList = action.payload.data || [];
      const responseIds = action.payload.data.map(a => a.id);

      state.loadingList = false;
      state.loadedList = true;

      state.list = responseList;
      state.idsList = responseIds;
      responseList.forEach((entry) => (state.dictionary[entry.id] = entry));

    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    show: (state, action) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
      state.show = state.dictionary[action.payload] || {};
      state.showId = action.payload || null;
    },
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload.data;
      state.showId = action.payload.data.id;
      state.dictionary[action.payload.data.id] = action.payload.data;
    },
    showFail: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = false;
      state.listError = action.payload.mess;
    },
    updateDictionary: (state, action) => {
      action.payload.forEach((static_page) => {
        state.dictionary[static_page.id] = static_page;
      });
    },
    addToDictionary: (state, action) => {
      state.dictionary[action.payload.id] = {
        ...state.dictionary[action.payload.id],
        ...action.payload,
      };
    },
  },
});

export const {
  list: listFormSteps,
  show: showFormStep,
  updateDictionary: updateFormStepsDictionary,
  addToDictionary: addToFormStepsDictionary
} = formStepsSlice.actions;

export const actions = formStepsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const formStepsList = (state) =>
  state.formSteps.idsList.map((id) => state.formSteps.dictionary[id]);

export const formStepShowId = (state) => state.formSteps.showId;

export const getFormStep = createSelector(
  (state) => state.formSteps.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  },
);

export const getFormStepAttributes = createSelector(
  (state) => state.formSteps.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  },
);

export const getFormStepRelationships = createSelector(
  (state) => state.formSteps.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  },
);

export default formStepsSlice.reducer;
