/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { put, takeEvery, all } from 'redux-saga/effects';

import { actions as formStepActions } from '../form_steps';

import { actions as formStepOptionActions } from '../form_step_options';

import {actions as caseActions } from '../cases';


/** Worker Sagas */
export function* storeIncluded(action) {
  yield all(
    action?.payload?.map((entry) => {
      if (entry.type === 'form_step') {
        return put({ type: formStepActions.addToDictionary, payload: entry });
      }
      if (entry.type === 'form_step_option') {
        return put({ type: formStepOptionActions.addToDictionary, payload: entry });
      }
      if (entry.type === 'cases') {
        return put({ type: caseActions.addToDictionary, payload: entry });
      } else return null;
    }),
  );
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* dictionarySagas() {
  yield takeEvery('dictionary/storeIncluded', storeIncluded);
}
export default dictionarySagas;
