/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import DataLayerHelper from "../../../utils/dataLayer";

export function* loadAmazonPayConfig(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/amazonpay/create.json`,
      {
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    yield put(actions.showAmazonPayConfigSuccess(payload));
  } catch (e) {
    yield put(actions.showAmazonPayConfigFail(e));
    DataLayerHelper.addErrorEvent(
      "showAamazonPayConfig",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* resetAmazonPay(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;

  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/amazonpay/reset.json`,
      {
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    if (action?.payload?.callback) action.payload.callback();
    yield put(actions.resetAmazonPaySuccess(payload));
  } catch (e) {
    yield put(actions.resetAmazonPayFail(e));
    DataLayerHelper.addErrorEvent(
      "resetAmazonPayConfig",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

export function* finalizeAmazonPay(action) {
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  const { amazonCheckoutSessionId, cart } = action.payload;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/amazonpay/finalize.json?amazonCheckoutSessionId=${amazonCheckoutSessionId}`,
      {
        method: "POST",
        headers: headers,
        tokenRequired: true,
        skipRenewToken: true,
      }
    );
    if (cart) DataLayerHelper.addPurchaseEvent(cart); // FIXME this is not perfect, we might track failed transactions this way!
    setTimeout(() => {
      window.location = payload.redirect_to;
    }, 250);
    yield put(actions.finalizeAmazonPaySuccess(payload));
  } catch (e) {
    alert(e.error || e.message);
    yield put(actions.finalizeAmazonPayFail(e));
    DataLayerHelper.addErrorEvent(
      "resetAmazonPayConfig",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopApCheckoutSaga() {
  yield takeEvery(actions.showAmazonPayConfig, loadAmazonPayConfig);
  yield takeEvery(actions.resetAmazonPay, resetAmazonPay);
  yield takeEvery(actions.finalizeAmazonPay, finalizeAmazonPay);
}

export default shopApCheckoutSaga;
