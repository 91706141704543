/**
 * Actions and ActionTypes definitions for the Faq resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from "redux-actions";
import notificationsActions from "../notifications";

/** LIST faqs action-types */
const LIST = "faqs/LIST";
const LIST_SUCCESS = "faqs/LIST_SUCCESS";
const LIST_FAIL = "faqs/LIST_FAIL";

/** LIST faqs action-types */
const SEARCH = "faqs/SEARCH";
const SEARCH_SUCCESS = "faqs/SEARCH_SUCCESS";
const SEARCH_FAIL = "faqs/SEARCH_FAIL";

/** SHOW faq action-types */
const SHOW = "faqs/SHOW";
const SHOW_SUCCESS = "faqs/SHOW_SUCCESS";
const SHOW_FAIL = "faqs/SHOW_FAIL";

/** CREATE faq action-types */
const CREATE = "faqs/CREATE";
const CREATE_SUCCESS = "faqs/CREATE_SUCCESS";
const CREATE_FAIL = "faqs/CREATE_FAIL";

/** UPDATE faq action-types */
const UPDATE = "faqs/UPDATE";
const UPDATE_SUCCESS = "faqs/UPDATE_SUCCESS";
const UPDATE_FAIL = "faqs/UPDATE_FAIL";

/** REMOVE faq action-types */
const REMOVE = "faqs/REMOVE";
const REMOVE_SUCCESS = "faqs/REMOVE_SUCCESS";
const REMOVE_FAIL = "faqs/REMOVE_FAIL";

/** STORE faq action-type */
const STORE = "faqs/STORE";
/** UNSTORE faq action-type */
const UNSTORE = "faqs/UNSTORE";

/**
 * list faqs
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * list faqs
 */
const searchSuccess = createAction(SEARCH_SUCCESS);
const searchFail = createAction(SEARCH_FAIL);
const search = createAction(SEARCH);

/**
 * show faq
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create faq
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update faq
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);

/**
 * remove faq
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store faq
 */
const storeFaq = createAction(STORE, () => async (dispatch) => {
  if (navigator && "storage" in navigator && "estimate" in navigator.storage) {
    navigator.storage.estimate().then(function (estimate) {
      const { usage, quota } = estimate;
      const percentUsed = Math.round((usage / quota) * 100);
      const usageInMib = Math.round(usage / (1024 * 1024));
      const quotaInMib = Math.round(quota / (1024 * 1024));
      const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
      const color = percentUsed > 80 ? "danger" : "success";
      let alertOptions = { message, color, origin: "storage", timeout: 5 };
      dispatch(notificationsActions.showAlert(alertOptions));
    });
  }
});
/**
 * unstore faq
 */
const unstoreFaq = createAction(UNSTORE);

/** exports all actions as default */
const faqActions = {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  search: search,
  searchSuccess: searchSuccess,
  searchFail: searchFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeFaq: storeFaq,
  unstoreFaq: unstoreFaq,
};
export default faqActions;
