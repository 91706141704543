// taxonsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  listLoading: false,
  listError: false,
  show: null,
  errorRedirect: null,
  lastSlug: false,
  showLoading: false,
  showError: false,
  taxonQueriesDictionary: {},
  taxonsDictionary: {},
  taxonsImages: {},
  requestedTaxonIds: [],
  showFourOFour: false,
};

export const taxonsSlice = createSlice({
  name: "shopTaxons",
  initialState,
  reducers: {
    listTaxons: (state, action) => {
      let taxonomyIds = [];
      if (action.payload.taxonomyIds && action.payload.taxonomyIds.split) {
        taxonomyIds = action.payload.taxonomyIds
          .split(",")
          .map((val) => parseInt(val));
      }
      state.listLoading = true;
      state.showFourOFour = false;
      state.listError = null;
      state.requestedTaxonIds = taxonomyIds;
    },
    listTaxonsSuccess: (state, action) => {
      const { language, data } = action.payload;
      let newTaxonsDict = { ...state.taxonsDictionary };
      if (!newTaxonsDict[language]) newTaxonsDict[language] = {};
      data.forEach((prod) => (newTaxonsDict[language][prod.id] = prod));

      let dictImages = { ...state.taxonsImages };

      action.payload.included?.map((includedTaxon) => {
        if (includedTaxon?.type === "taxon_image") {
          dictImages[includedTaxon.id] = includedTaxon;
        }
        return dictImages;
      });
      state.listLoading = false;
      state.showFourOFour = false;
      state.list = action.payload;
      state.taxonsDictionary = newTaxonsDict;
      state.taxonsImages = dictImages;
    },
    listTaxonsFail: (state, action) => {
      state.listLoading = false;
      state.showFourOFour = false;
      state.list = action.payload;
      state.listError = action.payload ? action.payload.message || true : true;
    },
    showTaxon: (state, action) => {
      let cachedTaxon =
        state.taxonQueriesDictionary[
          action.payload.language + "/" + action.payload.slug
        ] || state.show;
      state.showLoading = true;
      state.lastSlug = action.payload.slug;
      state.showError = null;
      state.errorRedirect = null;
      state.show = cachedTaxon;
      state.showFourOFour = false;
    },
    showTaxonSuccess: (state, action) => {
      let dict = { ...state.taxonQueriesDictionary };
      let dictId = { ...state.taxonsDictionary };
      let dictImages = { ...state.taxonsImages };
      dict[
        action.payload.language + "/" + action.payload.data.attributes.permalink
      ] = action.payload;

      action.payload.included?.map((includedTaxon) => {
        if (includedTaxon?.type === "taxon") {
          if (!dictId[action.payload.language])
            dictId[action.payload.language] = {};
          dictId[action.payload.language][includedTaxon.id] = includedTaxon;
        } else if (includedTaxon?.type === "taxon_image") {
          dictImages[includedTaxon.id] = includedTaxon;
        }
        return dictImages;
      });

      state.showLoading = false;
      state.showFourOFour = false;
      state.show = action.payload;
      state.taxonQueriesDictionary = dict;
      state.taxonsDictionary = dictId;
      state.taxonsImages = dictImages;
    },
    showTaxonFail: (state, action) => {
      let showFourOFour = false;
      if (action?.payload?.responseStatus === 404) showFourOFour = true;
      state.showLoading = false;
      if (action?.payload?.errorRedirect)
        state.errorRedirect = action?.payload?.errorRedirect;
      state.show = showFourOFour ? null : action.payload;
      state.showError = action.payload ? action.payload.message || true : true;
      state.showFourOFour = showFourOFour;
    },
    showTaxonAndProducts: (state, action) => {
      let cachedTaxon =
        state.taxonQueriesDictionary[
          action.payload.language + "/" + action.payload.slug
        ] || state.show;
      state.showLoading = true;
      state.lastSlug = action.payload.slug;
      state.showError = null;
      state.errorRedirect = null;
      state.show = cachedTaxon;
      state.showFourOFour = false;
    },
  },
});

export const {
  listTaxons,
  listTaxonsSuccess,
  listTaxonsFail,
  showTaxon,
  showTaxonSuccess,
  showTaxonFail,
  showTaxonAndProducts,
} = taxonsSlice.actions;

export const actions = taxonsSlice.actions;

export default taxonsSlice.reducer;
