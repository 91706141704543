/**
 * The ScrollToTop component
 */
import { Component } from 'react';
import { withRouter } from 'react-router';

/**
 * React component to scroll to the top when navigating to a new route
 */
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.isServer){
        const scrollOptions = {
          left: 0,
          top: 0,
          behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
