import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
  Spinner,
  Form,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { searchProducts } from "../../redux/shop/products";
import { push } from "connected-react-router";
import ShopProductCard from "../ShopProductCard";
import ShopProductListItem from "../ShopProductListItem";
import { Link, useHistory } from "react-router-dom";

const ProductSearch = (props, context) => {
  const {
    onSearchResultClickOverwrite,
    closeSearch,
    attachedResults,
    showSearchPreview,
    setShowSearchPreview,
    fullPageSearch,
    searchType,
    defaultValue,
  } = props;

  const { language } = useSelector((state) => ({
    language: state.i18nState.lang,
  }));
  const [performingSearch, setPerformingSearch] = useState(false);
  const [searchTimer, setSearchTimer] = useState(null);
  const [selected, setSelected] = useState(null);
  const [inputCharCount, setInputCharCount] = useState(0);
  const searchInput = useRef(null);

  const searchResults = useSelector(
    (state) => state.shopProducts.searchResults
  );
  const searchResultsLoading = useSelector(
    (state) => state.shopProducts.searchLoading
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const handleInputChangeSearch = (event) => {
    if (searchTimer) clearTimeout(searchTimer);
    setInputCharCount(searchInput.current.value.length);
    event.preventDefault();
    if (searchInput.current.value.length <= 3) return;
    const filters = [{ key: "name", value: searchInput.current.value }];
    setPerformingSearch(true);
    const timer = setTimeout(() => {
      if (fullPageSearch) {
        history.push(`?q=${searchInput.current.value}`);
      } else {
        dispatch(
          searchProducts({
            filters: filters,
            page: 1,
            perPage: 12,
            searchType: searchType,
          })
        );
      }
      if (setShowSearchPreview) setShowSearchPreview(true);
    }, 750);
    setSearchTimer(timer);
  };

  return (
    <div className="product-search col-12">
      <Col
        className={`${attachedResults ? "mt-5" : "mb-5"}`}
        style={{ zIndex: 1010 }}
        sm="12"
        md={{ size: attachedResults ? 6 : 10, offset: attachedResults ? 3 : 1 }}
      >
        <Form onSubmit={handleInputChangeSearch}>
          <InputGroup>
            <Input
              innerRef={searchInput}
              defaultValue={defaultValue}
              placeholder={context.t("What are you looking for?")}
              style={{ fontSize: "16px", minHeight: "3rem" }}
              onChange={handleInputChangeSearch}
            />
            <InputGroupAddon addonType="append">
              <Button color="primary" disabled={inputCharCount <= 3}>
                <FaSearch /> {context.t("search")}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
        {inputCharCount <= 3 && inputCharCount !== 0 && (
          <div className="small text-info">
            {context.t("Please enter at least 3 characters")}
          </div>
        )}
      </Col>
      {((performingSearch && searchResultsLoading) ||
        (fullPageSearch && searchResultsLoading) ||
        searchResults?.data) &&
        ((attachedResults && showSearchPreview) || !attachedResults) && (
          <div
            className={`shopcategory__products ${
              attachedResults
                ? "col-sm-12 col-md-6 offset-md-3 shopcategory__products-attached mt-0"
                : fullPageSearch
                ? "col-12 shopcategory__products-fullpage"
                : "col-12"
            }`}
          >
            {((performingSearch && searchResultsLoading) ||
              (fullPageSearch && searchResultsLoading)) && (
              <div className="text-center my-3">
                <Spinner size="sm" color="primary" />{" "}
                {context.t("searching...")}
              </div>
            )}
            {/^(MYF|P|R)\d{1,10}$/.test(searchInput?.current?.value) && (
              <div className="text-center py-2">
                <p>
                  <strong>
                    {context.t(
                      "If you have questions regarding your order or invoice please use our support form."
                    )}
                  </strong>
                </p>
                <Link to={`/${language}/kontakt/`} className="btn btn-success">
                  {context.t("Contact")}
                </Link>
              </div>
            )}
            <Row>
              {searchResults?.data &&
                searchResults?.data.map((product, index) => {
                  let productData = { ...product };
                  if (
                    product?.relationships &&
                    product?.relationships?.images?.data?.length > 0
                  ) {
                    if (searchResults.included) {
                      productData = {
                        ...product,
                        thumbnail: {
                          img_url: searchResults.included.filter((incl) => {
                            return (
                              incl.type === "image" &&
                              incl.id ===
                                product.relationships.images.data[0].id
                            );
                          })[0].attributes.styles[2].url,
                        },
                      };
                    }
                  }
                  return (
                    <>
                      {!attachedResults && (
                        <Col
                          key={`product-${index}`}
                          lg={fullPageSearch ? 2 : 3}
                          md={fullPageSearch ? 3 : 4}
                          xs={fullPageSearch ? 6 : 6}
                          className="mb-3"
                        >
                          <ShopProductCard
                            product={productData}
                            language={language}
                            titleTag="h2"
                            onClickOverwrite={onSearchResultClickOverwrite}
                            setSelected={setSelected}
                            selected={selected}
                            navigateTo={(url) => push(url)}
                            closeSearch={closeSearch}
                          />
                        </Col>
                      )}
                      {attachedResults && (
                        <Col key={`product-a-${index}`} className="mb-3 col-12">
                          <ShopProductListItem
                            product={product}
                            language={language}
                            titleTag="p"
                            onClickOverwrite={onSearchResultClickOverwrite}
                            setSelected={setSelected}
                            selected={selected}
                            navigateTo={(url) => push(url)}
                            closeSearch={closeSearch}
                          />
                        </Col>
                      )}
                    </>
                  );
                })}
            </Row>
            {searchResults?.data && searchResults?.data.length === 0 && (
              <div className="text-center text-muted my-3">
                {context.t("No Products found")}
              </div>
            )}
          </div>
        )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
ProductSearch.contextTypes = {
  t: PropTypes.func,
};

// export default ProductSearch;

/** export the redux connected component and add a frontload call */
export default ProductSearch;
