import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookieHandler = new Cookies();

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    paymentMethods: null,
    paymentMethodsLoading: false,
    paymentMethodsError: false,
    shippingMethods: null,
    shippingMethodsLoading: false,
    shippingMethodsError: false,
    updateCheckoutLoading: false,
    updateCheckoutError: false,
  },
  reducers: {
    showPaymentMethods: (state) => {
      state.paymentMethodsLoading = true;
      state.paymentMethodsError = false;
    },
    showPaymentMethodsSuccess: (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethodsError = false;
      state.paymentMethods = action.payload;
    },
    showPaymentMethodsFail: (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethodsError = action.payload || true;
      state.paymentMethods = null;
    },
    showShippingMethods: (state) => {
      state.shippingMethodsLoading = true;
      state.shippingMethodsError = false;
    },
    showShippingMethodsSuccess: (state, action) => {
      state.shippingMethodsLoading = false;
      state.shippingMethodsError = false;
      state.shippingMethods = action.payload;
    },
    showShippingMethodsFail: (state, action) => {
      state.shippingMethodsLoading = false;
      state.shippingMethodsError = action.payload || true;
      state.shippingMethods = null;
    },
    updateCheckout: (state) => {
      state.updateCheckoutLoading = true;
      state.updateCheckoutError = false;
    },
    updateAmazonPayment: (state) => {
      state.updateCheckoutLoading = true;
      state.updateCheckoutError = false;
    },
    updateCheckoutSuccess: (state) => {
      state.updateCheckoutLoading = false;
      state.updateCheckoutError = false;
    },
    updateCheckoutFail: (state, action) => {
      state.updateCheckoutLoading = false;
      state.updateCheckoutError = action.payload || true;
    },
    nextStepCheckout: (state) => {
      state.updateCheckoutLoading = true;
      state.updateCheckoutError = false;
    },
    nextStepCheckoutSuccess: (state) => {
      state.updateCheckoutLoading = false;
      state.updateCheckoutError = false;
    },
    nextStepCheckoutFail: (state) => {
      state.updateCheckoutLoading = false;
      state.updateCheckoutError = true;
    },
    completeCheckout: (state) => {
      state.completeCheckoutLoading = true;
      state.completeCheckoutError = false;
    },
    completeCheckoutSuccess: (state, action) => {
      cookieHandler.remove("osid", {
        httpOnly: false,
        secure: false,
        sameSite: "strict",
        path: "/",
      });
      cookieHandler.remove("osidsrc", {
        httpOnly: false,
        secure: false,
        sameSite: "strict",
        path: "/",
      });
      cookieHandler.remove("osidn", {
        httpOnly: false,
        secure: false,
        sameSite: "none",
        path: "/",
      });
      state.completeCheckoutLoading = false;
      state.completeCheckoutError = false;
      state.paymentMethods = null;
      state.shippingMethods = null;
    },
    completeCheckoutFail: (state, action) => {
      state.completeCheckoutLoading = false;
      state.completeCheckoutError = action.payload || true;
    },
    completeCheckoutResetError: (state) => {
      state.completeCheckoutLoading = false;
      state.completeCheckoutError = false;
    },
    resetShippingMethods: (state) => {
      state.shippingMethods = null;
    },
    resetPaymentMethods: (state) => {
      state.paymentMethods = null;
    },
    setOrderReviewConsent: (state) => {},
  },
});

export const {
  setOrderReviewConsent,
  showPaymentMethods,
  showPaymentMethodsSuccess,
  showPaymentMethodsFail,
  showShippingMethods,
  showShippingMethodsSuccess,
  showShippingMethodsFail,
  updateCheckout,
  updateAmazonPayment,
  updateCheckoutSuccess,
  updateCheckoutFail,
  nextStepCheckout,
  nextStepCheckoutSuccess,
  nextStepCheckoutFail,
  completeCheckout,
  completeCheckoutSuccess,
  completeCheckoutFail,
  completeCheckoutResetError,
  resetShippingMethods,
  resetPaymentMethods,
} = checkoutSlice.actions;

export const actions = checkoutSlice.actions;
export default checkoutSlice.reducer;
