import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
  name: 'shopErrors',
  initialState: {
    cookieError: null,
    cookieErrorMessage: null
  },
  reducers: {
    setCookieError: (state, action) => {
      state.cookieError = action.payload;
      state.cookieErrorMessage = action.payload.message;
    },
    resetCookieError: (state) => {
      state.cookieError = null;
      state.cookieErrorMessage = null;
    }
  },
});

export const { 
  setCookieError, 
  resetCookieError, 
} = errorSlice.actions;
export const actions = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const cookieErrorMessage = state => state.shopErrors.cookieErrorMessage;


export default errorSlice.reducer;
