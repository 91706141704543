/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import { list as loadProducts } from "../products/sagas";
import { list as loadCmsBlocks } from "../../cmsblocks/sagas";
import DataLayerHelper from "../../../utils/dataLayer";
import { push } from "connected-react-router";
/** Worker Sagas */
export function* list(action) {
  let taxonomyIds = (action.payload && action.payload.taxonomyIds) || 9;
  let filter =
    (action.payload && action.payload.taxonomyFilter) || "taxonomy_id";
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/taxons.json?filter[${filter}]=${taxonomyIds}&include=parent,taxonomy,image&per_page=150&lang=${lang}`,
      { method: "GET", headers: headers, useSdn: true }
    );
    payload.language = lang;
    yield put(actions.listTaxonsSuccess(payload));

    return payload;
  } catch (e) {
    console.error("ERROR", e);
    yield put(actions.listTaxonsFail(e));
    DataLayerHelper.addErrorEvent(
      "listTaxons",
      e?.error || e?.message || "Shop API Failure"
    );
  }
}
export function* show(action) {
  const { slug, callbackSuccess } = action.payload;
  /**  else we are online -> we fetch */
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    // FIXME for some reason this request is not allowed with .json
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/taxons/${slug}?include=parent,taxonomy,children,children.image,image&lang=${lang}`,
      { method: "GET", headers: headers, useSdn: true }
    );

    // FIXME unfortunately the products are not the same as on the products filter request.
    // const productList = payload.included.filter((obj) => { return (obj.type === "product")});
    payload.language = lang;
    yield put(actions.showTaxonSuccess(payload));
    if (callbackSuccess) callbackSuccess(payload);
  } catch (e) {
    console.error("ERROR", e);
    DataLayerHelper.addErrorEvent(
      "showTaxon",
      e?.error || e?.message || "Shop API Failure"
    );

    yield put(actions.showTaxonFail({ message: e.message, ...e }));

    if (e.responseStatus === 404) {
      yield put(push("/" + lang + "/404-taxon/?source=" + slug));
    }
  }
}

export function* showTaxonAndLoadProducts(action) {
  const { slug, callbackSuccess } = action.payload;

  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    // FIXME for some reason this request is not allowed with .json
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/taxons/${slug}?include=parent,taxonomy,children,children.image,image&lang=${lang}`,
      { method: "GET", headers: headers, useSdn: true }
    );

    // FIXME unfortunately the products are not the same as on the products filter request.
    payload.language = lang;
    yield put(actions.showTaxonSuccess(payload));

    if (payload?.data?.id) {
      const productIds = payload?.data?.relationships?.products?.data?.map(
        (prod) => prod.id
      );
      if (productIds.length > 0) {
        let filters = [
          { key: "taxons", value: payload.data.id },
          { key: "ids", value: productIds },
        ];
        yield loadProducts({
          payload: { filters, language: lang, perPage: 38, page: 1 },
        });
      }
    }
    if (payload?.data?.attributes?.taxonomy_filter_ids) {
      yield list({
        payload: {
          taxonomyIds: payload.data.attributes.taxonomy_filter_ids,
          taxonomyFilter: "taxonomy_ids",
        },
      });
    }

    if (payload?.data?.attributes?.cms_blocks) {
      yield loadCmsBlocks({
        payload: { slugs: payload.data.attributes.cms_blocks },
      });
    }
  } catch (e) {
    console.error("FAILED TAXON ERROR", e);
    if (
      e?.responseStatus === 404 ||
      e?.responseStatus === 403 ||
      e?.responseStatus === 417
    ) {
      yield put(
        actions.showTaxonFail({
          message: e.message,
          ...e,
          errorRedirect:
            "/" +
            lang +
            "/404-taxon/?source=" +
            slug +
            "&status=" +
            e?.responseStatus,
        })
      );
      yield put(
        push(
          "/" +
            lang +
            "/404-taxon/?source=" +
            slug +
            "&status=" +
            e?.responseStatus
        )
      );
    }
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopTaxonsSaga() {
  yield takeEvery(actions.listTaxons, list);
  yield takeEvery(actions.showTaxon, show);
  yield takeEvery(actions.showTaxonAndProducts, showTaxonAndLoadProducts);
}

export default shopTaxonsSaga;
